/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs/';
import ShopItems from '../../pages/Shop/shop-items';
import { useMsal } from '@azure/msal-react';
import useAxios from 'scripts/hooks/useAxios';
import { InteractionStatus } from '@azure/msal-browser';
import { ProductListWithBreadcrumbsLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';
import { useTranslation } from 'react-i18next';
import "../../../i18n"

export default function Shop(props) {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    let { categoryId } = useParams();
    const history = useHistory();
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        if (loading && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: `/api/order/salesNodes?parent=${categoryId}`
            }).then((res) => {
                // Rename JSON keys
                let products = res.data.children.map((o) => ({
                    'id': o.NodeId,
                    'title': o.Label,
                    'sku': o.NodeId,
                    'image': o.ImageUrl,
                    'to': o.NodeType === 1 ? `/shop/product/${o.ItemNumber}` : `/shop/category/${o.NodeId}`
                }));

                // Rename JSON keys
                let breadcrumbs = res.data.ancestors.map((o) => ({
                    'id': `/shop/category/${o.NodeId}`,
                    'label': o.Label,
                }));

                breadcrumbs.push({
                    id: '/order-add',
                    label: t('shop.new-order-label')
                })

                breadcrumbs.unshift({
                    id: `/shop/category/${res.data.current.NodeId}`,
                    label: res.data.current.Label
                })

                setBreadcrumbs(breadcrumbs.reverse())

                localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
                setProducts(products)
                setLoading(false)

            }).catch(error => {
                setLoading(false)
                setError(true)
                alertLoadingError(t)
                console.error(error) // eslint-disable-line no-console
            })
        }
    }, [categoryId, loading, inProgress, callApi]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return history.listen((location) => {
            setLoading(true);
        });
    }, [history]);

    return (
        <div className="main-content main-content-shop">
            {loading && !error &&
                <ProductListWithBreadcrumbsLoader />
            }
            {products && !loading &&
                <>
                    <Breadcrumbs items={breadcrumbs} />
                    <ShopItems items={products} />
                </>
            }
        </div>
    );
}