/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect, useMemo } from 'react';
import InvoiceTable from '../../components/DataTable/InvoiceTable';
import { formatDate } from '../../components/Utils/dates';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from '../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { TableLoader } from '../../components/ContentLoader';
import Invoice from './invoice';
import { alertLoadingError } from '../../components/Confirm';

const StatusFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const { t } = useTranslation();

    const values = useMemo(() => {
        let statuses = {
            1: 0,
            2: 0,
            3: 0
        }
        preFilteredRows.forEach((row) => {
            statuses[row.values[id]]++;
        });
        return statuses;
    }, [id, preFilteredRows]);

    const handleInputChange = (e) => {
        setFilter(e.target.value || undefined);
    };

    return (
        <div className="cpq-radio-controls cpq-radio-controls-horizontal-center">
            <div className="cpq-radio-control">
                <input
                    type="radio"
                    value=""
                    id="finance-all"
                    checked={!filterValue}
                    onChange={handleInputChange}
                    className="cpq-radio-field"
                />
                <label
                    htmlFor="finance-all"
                    className="cpq-radio-label"
                >
                    {t('finance.all')}
                </label>
            </div>
            <div className="cpq-radio-control">
                <input
                    type="radio"
                    value="2"
                    id="finance-overdue-plural"
                    checked={filterValue === '2'}
                    onChange={handleInputChange}
                    disabled={!values[2]}
                    className="cpq-radio-field"
                />
                <label
                    htmlFor="finance-overdue-plural"
                    className="cpq-radio-label"
                >
                    {t('finance.overdue-plural')} <span className="value">{values[2]}</span>
                </label>
            </div>
            <div className="cpq-radio-control">
                <input
                    type="radio"
                    value="1"
                    id="finance-unpaid-plural"
                    checked={filterValue === '1'}
                    onChange={handleInputChange}
                    disabled={!values[1]}
                    className="cpq-radio-field"
                />
                <label
                    htmlFor="finance-unpaid-plural"
                    className="cpq-radio-label"
                >
                    {t('finance.unpaid-plural')}  <span className="value">{values[1]}</span>
                </label>
            </div>
            <div className="cpq-radio-control">
                <input
                    type="radio"
                    value="3"
                    id="finance-paid-plural"
                    checked={filterValue === '3'}
                    onChange={handleInputChange}
                    className="cpq-radio-field"
                />
                <label
                    htmlFor="finance-paid-plural"
                    className="cpq-radio-label"
                >
                    {t('finance.paid-plural')}
                </label>
            </div>
        </div>
    );
};

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

function Invoices() {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    const [invoices, setInvoices] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const [invoiceError, setInvoiceError] = useState(false)
    const [invoice, setInvoice] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState(false);

    useEffect(() => {
        if (!invoices && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/invoice/invoices?amount=999',
            }).then((res) => {
                if (res.status === 200) {
                    setInvoices(res.data.Invoices);
                    setLoading(false);
                }
            }).catch(error => {
                setError(true)
                setLoading(false)
                alertLoadingError(t)
                console.error(error) // eslint-disable-line no-console
            })
        }
    }, [invoices, loading, inProgress, callApi]); // eslint-disable-line react-hooks/exhaustive-deps

    const getInvoice = (orderNumber) => {
        setInvoiceLoading(true)
        document.documentElement.classList.add('show-nb-modal')
        callApi({
            method: 'get',
            url: `/api/invoice/invoiceDetails?invoiceNumber=${orderNumber}`
        }).then((res) => {
            if (res.status === 200) {
                setInvoice(res.data.Invoice);
                setInvoiceLoading(false)
            }
        }).catch(error => {
            setInvoiceError(true)
            setInvoiceLoading(false)
            alertLoadingError(t)
            console.error(error) // eslint-disable-line no-console
        })
    }

    const columns = React.useMemo(() => {
        return [
            {
                Header: t('finance.header.invoice-number'),
                accessor: 'InvoiceNumber',
                Cell: function Tracking(cell) {
                    return (
                        <span
                            className="fake-link"
                            onClick={() => getInvoice(cell.value)}
                        >
                            {cell.value}
                        </span>
                    );
                }
            },
            {
                Header: t('finance.header.order-number'),
                accessor: 'OrderNumber',
            },
            {
                Header: t('finance.header.invoice-mark'),
                accessor: 'OrderMark',
            },
            {
                Header: t('finance.header.date'),
                accessor: 'InvoiceDate',
                Cell: ({ value }) => {
                    return formatDate(String(value));
                },
            },
            {
                Header: t('finance.header.due-date'),
                accessor: 'NetDueDate',
                Cell: ({ value }) => {
                    return formatDate(String(value));
                },
            },
            {
                Header: t('finance.header.sum'),
                accessor: 'TotalAmount',
                Cell: (cell) => {
                    return `${cell.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })} ${cell.row.original.Currency}`;
                },
            },
            {
                Header: t('finance.header.status'),
                accessor: 'PaymentStatus',
                Cell: ({ value }) => {
                    switch (value) {
                        case '1':
                            return t('finance.header.unpaid');
                        case '2':
                            return t('finance.header.overdue');
                        case '3':
                            return t('finance.header.paid');
                        default:
                            return value;
                    }
                },
                Filter: StatusFilter,
                filter: 'includes',
            },
        ];
    }, [t]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="main-content">
            <div className="primary-content">
                <h1>{t('finance.invoices')}</h1>
                {loading &&
                    <TableLoader />
                }
                {!loading && !error &&
                    <InvoiceTable
                        columns={columns}
                        data={invoices}
                        className="table-default"
                        limit="2"
                        getRowProps={(rowInfo) => {
                            if (rowInfo.values.PaymentStatus) {
                                switch (rowInfo.values.PaymentStatus) {
                                    case '1':
                                        return {
                                            className: 'status status-unpaid',
                                        };
                                    case '2':
                                        return {
                                            className: 'status status-overdue',
                                        };
                                    case '3':
                                        return {
                                            className: 'status status-paid',
                                        };
                                    default:
                                        return {};
                                }
                            }
                            return {};
                        }}
                        DefaultColumnFilter={DefaultColumnFilter}
                    />
                }
            </div>
            {!invoiceError &&
                <Invoice invoice={invoice} invoiceLoading={invoiceLoading} />
            }
        </div>
    );
}

export default Invoices;