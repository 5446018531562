import React, { useState } from 'react';

const ImageAndTextHorizontal = ({id, value: startValue, selectableValues, onChange, onContinue, isEnabled}) => {

    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.name, event.target.value);
        setValue(event.target.value);
        onContinue();
    };

    return (
        <div className="cpq-option-input cpq-radio-control-with-image-groups" id={id}>
            {selectableValues.map(
                (selectableValue, index) => (
                    <div key={index} className="cpq-radio-control">
                        <input
                            type="radio"
                            id={selectableValue.Value + id}
                            name={id}
                            value={selectableValue.Value}
                            checked={selectableValue.Value === value}
                            onChange={handleInputChange}
                            className="cpq-radio-field"
                            disabled={!selectableValue.IsEnabled}
                        />
                        <label
                            htmlFor={selectableValue.Value + id}
                            className="cpq-radio-label"
                        >
                        </label>

                        <label
                            htmlFor={selectableValue.Value + id}
                            className="cpq-image-label"
                        >
                            <span className="cpq-radio-label-text">{selectableValue.Caption}</span>
                            {selectableValue.ImageLink ? (
                                <img
                                    src={selectableValue.ImageLink}
                                    alt=""
                                />
                            ) : (
                                ''
                            )}
                        </label>
                    </div>
                )
            )}
        </div>
    );
};

export default ImageAndTextHorizontal;