/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ErrorInfo from '../../Messages';
import { useTranslation } from 'react-i18next';

const SelectedNews = () => {
    const [news, setNews] = useState([]);
    const { t } = useTranslation();
    const [error, setError] = useState(false)

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
            url: '/rest-api/mediaApi/articles',
            params: {
                market: 'sv-SE',
                sort: true,
                limit: 3,
            },
        }).then((res) => {
            setNews(res.data);
        }).catch((error) => {
            setError(true)
            console.error(error); // eslint-disable-line
        });
    }, []);

    return (
        <div className="selected-news">
            {news.length > 0 &&
                news.map((post) => {
                    return (
                        <Link
                            key={post.id}
                            className="news-card"
                            to={`/news/${post.id}`}
                        >
                            <img
                                className="card-img"
                                src={post.image}
                                alt="Contura news"
                            />
                            <div className="card-text">
                                <h3 className="card-title">{post.title}</h3>
                            </div>
                        </Link>
                    );
                })
            }
            {error &&
                <ErrorInfo text={t('error.title.news')} />
            }
        </div>
    );
}

export default SelectedNews;