import React, { useState } from 'react';

const ImageHorizontal = ({id, value: startValue, selectableValues, onChange, onContinue, isEnabled}) => {

    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.name, event.target.value);
        setValue(event.target.value);
        onContinue();
    };

    return (
        <div className="cpq-option-input" id={id}>
            {selectableValues.map(
                (selectableValue, index) => (
                    <div key={index}>
                        <input
                            type="radio"
                            id={selectableValue.Value + id}
                            name={id}
                            value={selectableValue.Value}
                            checked={selectableValue.Value === value}
                            onChange={handleInputChange}
                            disabled={!selectableValue.IsEnabled}
                        />
                        <label htmlFor={selectableValue.Value + id}>
                            {selectableValue.ImageLink ? (
                                <img
                                    src={selectableValue.ImageLink}
                                    alt=""
                                />
                            ) : (
                                ''
                            )}
                        </label>
                    </div>
                )
            )}
        </div>
    );
};

export default ImageHorizontal;