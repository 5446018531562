import React from 'react';

import Screen from '../../components/CPQ/Screen';
import { CpqScreenLoader } from '../../components/ContentLoader';

function Page({isActive, screens, onChange, onContinue, onInfo, loadingPage}) {
    return (
        <div
            className={`cpq-page ${isActive ? 'cpq-page--active' : ''}`}
        >
            {screens.map((screen, index) => {
                if (screen.IsVisible) {
                    return (
                        <Screen
                            key={index}
                            screen={screen}
                            onChange={onChange}
                            onContinue={onContinue}
                            onInfo={onInfo}
                        />
                    )
                } else {
                    return null;
                }
            })}
            {loadingPage &&
                <div className="cpq-screen-loader">
                    <CpqScreenLoader />
                </div>
            }
        </div>
    );
}

export default Page;