import axios from 'axios';
import React, { useState, useEffect } from 'react';
import NewsList from '../../components/News/';
import NewsArchiveList from '../../components/News/NewsArchiveList';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

export default function News() {
    let [news, setNews] = useState(null);
    let [newsArchiveList, setNewsArchiveList] = useState();
    let [filteredNews, setFilteredNews] = useState();

    const { t } = useTranslation();


    useEffect(() => {
        if (!news) {
            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
                url: '/rest-api/mediaApi/articles',
                params: {
                    market: 'sv-SE',
                    limit: 30,
                    sort: true
                }
            }).then((res) => {
                setNews(res.data);
                setNewsArchiveList(setMonthAndYears(res.data));
            }).catch((error) => {
                console.error(error); // eslint-disable-line
            });
        }
    }, [news]);

    let filterNews = (filter) => {
        /** Snygga till */

        let filtered = news.filter((post) => {
            let postDate = post.date
                .replace(/(\d{4})(\d{2})(\d{6})/, '$1-$2-$3')
                .split('-');

            if (filter.type === 'month') {
                return (
                    postDate[0] === filter.year && postDate[1] === filter.month
                );
            } else {
                return postDate[0] === filter.year;
            }
        });

        setFilteredNews(filtered);
    };
    /**
     * Get every year and month that contains news posts
     * @param {Array} posts Array of posts
     */
    let setMonthAndYears = (posts) => {
        let dates = [];
        posts.forEach((post) => {
            let postDate = post.date
                .replace(/(\d{4})(\d{2})(\d{6})/, '$1-$2-$3')
                .split('-'),
                year = postDate[0],
                month = postDate[1],
                index = null;
            //If year is already stored
            dates.some((e, i) => {
                if (e.year === year) {
                    index = i;
                }
                return null;
            });
            // Year does not exists in dates list
            if (index === null) {
                dates.push({ year: year, items: [{ month: month, to: '/' }] });
            } else {
                //Check if month exist in that Year
                let mIndex = null;
                dates[index].items.some((y, j) => {
                    if (y.month === month) {
                        mIndex = j;
                    }
                    return null;
                });
                // Month of Year not found. Add to year
                if (mIndex === null) {
                    dates[index].items.push({ month: month, to: '/' });
                }
            }
        });

        return dates;
    };
    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>
                    {t('my-pages.news.title')}
                </h1>
                <p>{t('my-pages.news.intro')}</p>
            </div>
            <div className="content-secondary news-block">
                {news || filteredNews ? (
                    <div className="news-content news-content-latest-posts">
                        <NewsList items={filteredNews ? filteredNews : news} />
                    </div>
                ) : null}
                <div className="news-archive">
                    {newsArchiveList && (
                        <NewsArchiveList
                            items={newsArchiveList}
                            filter={filterNews}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}