import React from 'react';
import { ReactComponent as IconToggle } from '../../../images/icons/arrow-down-orange.svg';

function CategoryList(props) {
    function itemToggle(event) {
        let self = event.target;
        self.closest('.list-category-item').classList.toggle(
            'list-category-item-has-visible-content'
        );
    }

    function listItems(items) {
        return (
            items &&
            items.map((item, index) => (
                <div key={index} className="list-category-item">
                    <div className="list-category-title" onClick={itemToggle}>
                        {item.name}
                        <span className="icon-toggle">
                            <IconToggle />
                        </span>
                    </div>
                    <div className="list-category-content">
                        {item.items.map((categoryItem, index) => (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                                href={categoryItem.url}
                                className="list-category-link">
                                {categoryItem.name}
                            </a>
                        ))}
                    </div>
                </div>
            ))
        );
    }

    return (
        <div className="list-category">
            <div className="list-category-items">{listItems(props.items)}</div>
        </div>
    );
}

export default CategoryList;