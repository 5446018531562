/* eslint-disable react/jsx-key */

import React from 'react';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

const defaultPropGetter = () => ({});

const InvoiceTable = ({
    columns,
    data,
    className,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    DefaultColumnFilter
}) => {
    const { t } = useTranslation();

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }), [DefaultColumnFilter]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            defaultColumn
        },
        useFilters,
        useSortBy,
        usePagination,
    );

    const cellsLength = columns.length;

    return (
        <>
            {headerGroups.at(0).headers.at(-1).canFilter ? (
                <div className="table-header">{headerGroups.at(0).headers.at(-1).render('Filter')}</div>
            ) : null}
            <table
                {...getTableProps()}
                className={`table-sortable ${className} table-cols-${cellsLength}`}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className="th-sortable"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                    <span
                                        className={`column-is-sortable${column.isSorted
                                            ? column.isSortedDesc
                                                ? ' column-is-sorted-desc'
                                                : ' column-is-sorted-asc'
                                            : ''}`}
                                    >
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps([
                                    {
                                        className: row.className,
                                        style: row.style,
                                    },
                                    getRowProps(row),
                                ])}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                {
                                                    className: row.className,
                                                    style: row.style,
                                                },
                                                getCellProps(cell),
                                            ])}
                                        >
                                            <p>{cell.render('Cell')}</p>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="table-footer">
                <div className="table-pagination">
                    <button
                        className="first"
                        onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                    >
                    </button>
                    <button
                        className="previous"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                    </button>
                    <span className="page-count">
                        <span>{t('table.page')}</span>
                        <b>{pageIndex + 1}</b>
                        <span>{t('table.of')}</span>
                        <b>{pageOptions.length}</b>
                    </span>
                    <button
                        className="next"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                    </button>
                    <button
                        className="last"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                    </button>
                </div>

                <div className="table-size">
                    <select
                        className="table-size-select"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t('table.show-page-size', { pageSize: pageSize })}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export default InvoiceTable;