import React, { useState } from 'react';

const RadioButtonHorizontal = ({id, value: startValue, selectableValues, onChange, onContinue, isEnabled}) => {

    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.name, event.target.value);
        setValue(event.target.value);
        onContinue();
    };

    return (
        <div className="cpq-option-input cpq-radio-control-groups" id={id}>
            {selectableValues.map(
                (selectableValue, index) => (
                    <div key={index} className="cpq-radio-control">
                        <input
                            type="radio"
                            id={`${id}-${selectableValue.Value}`}
                            name={id}
                            value={selectableValue.Value}
                            checked={selectableValue.Value === value}
                            onChange={handleInputChange}
                            className="cpq-radio-field"
                            disabled={!isEnabled}
                        />
                        <label
                            htmlFor={`${id}-${selectableValue.Value}`}
                            className="cpq-radio-label"
                        >
                            {selectableValue.Caption}
                        </label>
                    </div>
                )
            )}
        </div>
    );
};

export default RadioButtonHorizontal;