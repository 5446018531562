import React, { useContext } from 'react';
import SummaryTable from './summaryTable';
import printIcon from '../../../images/icons/print-document.svg'; //temp
import { CartContext } from '../../components/Context/cart/CartContext';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import stoveImg from '../../../images/kamin-default.jpg';
import chimneyImg from '../../../images/skorsten-default.jpg';
import stoveChimneyImg from '../../../images/kamin-skorsten-default.jpg';
// import useAxios from 'scripts/hooks/useAxios';

export default function ConfigSummary({
    config,
    addedToCart,
    history,
    ruleset,
    headerId,
}) {
    const { addProduct } = useContext(CartContext);
    const { t } = useTranslation();
    // const { callApi } = useAxios();
    // const [deliveryDate, setDeliveryDate] = useState();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         let delivery = await callApi({
    //             method: 'post',
    //             url: '/api/order/deliveryDate',
    //             data: {
    //                 orderLines: buildDeliveryOrderLines(config.ConfigItems),
    //             },
    //         });
    //         setDeliveryDate(delivery.data.promiseDate.split('T')[0])
    //     }
    //     fetchData();
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Build orderlines to match deliveryDate format from CartItems
     */
    // let buildDeliveryOrderLines = (items) => {
    //     let orderLines = [];
    //     items.forEach((item) => {
    //         if ("ItemNo" in item) {
    //             orderLines.push({
    //                 artNo: item.ItemNo,
    //                 qty: item.Quantity,
    //                 date: new Date().toISOString().split('T')[0].replace(/-/g, ''),
    //             });
    //         }
    //
    //     });
    //     return orderLines;
    // };


    const ConfigInfoString = (text) => {
        let descriptions = text.split('|');
        return (
            <div>
                {descriptions.map((desc, index) => {
                    return <p key={index}>{desc}</p>;
                })}
            </div>
        );
    };

    const getConfigTitle = () => {
        if (
            ruleset.match(/^(Stove|New_Stove)$/) &&
            config.ConfigItems.at(-1).Path
        ) {
            return t('cpq-summary.config-title.stove-and-chimney');
        } else if (ruleset.match(/^(Stove|New_Stove)$/)) {
            return t('cpq-summary.config-title.stove');
        } else {
            return t('cpq-summary.config-title.chimney');
        }
    };

    const getConfigImage = () => {
        if (
            ruleset.match(/^(Stove|New_Stove)$/) &&
            config.ConfigItems.at(-1).Path
        ) {
            return stoveChimneyImg;
        } else if (ruleset.match(/^(Stove|New_Stove)$/)) {
            return stoveImg;
        } else {
            return chimneyImg;
        }
    };

    let addToCart = () => {
        addProduct({
            image: getConfigImage(),
            title: getConfigTitle(),
            sku: config.ConfigId,
            id: config.ConfigId,
            price: config.ConfiguredPrice,
            items: config.ConfigItems,
            quantity: 1,
            addQty: 1,
            type: 'config',
            headerId: headerId,
            ruleset: ruleset,
        });
        addedToCart();
    };

    let onCancel = () => {
        history.push('/order-add');
    };

    let printSummary = () => {

        window.print();
    };

    return (
        <>
            {config.Status === 'Success' ? (
                <>
                    <div className="cpq-page-content cpq-summary">
                        <div className="cpq-page content">
                            <div className="config-items">
                                <SummaryTable items={config.ConfigItems} />
                                {/* <p className="config-delivery-date"> {deliveryDate && "Leveransdatum: " + deliveryDate}</p> */}
                                <img
                                    className="print-icon"
                                    src={printIcon}
                                    alt="Print"
                                    onClick={printSummary}
                                />
                            </div>

                            {config.ConfigItems.at(-1).Path &&
                                config.ConfigItems.at(-1).ConfigInfoStr ? (
                                <div className="config-description">
                                    <img
                                        src={config.ConfigItems.at(-1).Path}
                                        alt="Configuration description"
                                    />
                                    <div className="config-info-string">
                                        {ConfigInfoString(
                                            config.ConfigItems.at(-1)
                                                .ConfigInfoStr
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                    </div>
                    <div className="cpq-footer">

                        <div className="cpq-footer-buttons">
                            <button
                                onClick={onCancel}
                                className="button-submit button-tertiary">
                                {t('cpq.cancel')}
                            </button>

                            <button
                                onClick={addToCart}
                                className="button-submit button-primary">
                                {t('cpq.add-to-cart')}
                            </button>

                        </div>

                        <p className="cpq-price">
                            <span className="cpq-price-details">
                                <span className="cpq-price-label">{t('cpq-summary.sum')}</span>
                                <span className="cpq-price-value">
                                    {config.ConfiguredPrice.toString()}{' '}
                                    {t('cpq-summary.currency')}
                                </span>
                            </span>

                        </p>
                    </div>
                </>
            ) : (
                <div>
                    <h3>
                        {t('cpq-summary.configuration-error')}
                        {config.Status} |
                        {config.Error}
                    </h3>
                </div>
            )}
        </>
    );
}
