import React from 'react';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className='main-content'>
      <h1>{t('error.not-found')}</h1>
    </div>
  );
};

export default NotFound;