import React from 'react';
import { stripHtml } from '../../components/Utils/strings';
import ScreenOption from '../../components/CPQ/ScreenOption';

function Screen({ screen, onChange, onContinue, onInfo }) {
    return (
        <div className="cpq-screen">
            {screen.ShowCaption ? <h3>{screen.Caption}</h3> : null}
            {screen.ShowDescription && screen.Description ? <p><b>{stripHtml(screen.Description)}</b></p> : null}
            {screen.ScreenOptions?.map((option, index) => {
                if (option.IsVisible) {
                    if (option) {
                        return (
                            <ScreenOption
                                key={option.ID}
                                screenOption={option}
                                onChange={onChange}
                                onContinue={onContinue}
                                onInfo={onInfo}
                            />
                        );
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            })}
        </div>
    );
}

export default Screen;