/* eslint no-console: ["warn", { allow: ["error"] }] */

import React, { useState, useEffect } from 'react';
import { TextLoader } from '../../components/ContentLoader';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal } from '@azure/msal-react';
import useAxios from 'scripts/hooks/useAxios';
import { InteractionStatus } from '@azure/msal-browser';

export default function Campaigns() {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isSignedUp, setIsSignedUp] = useState(false);

    useEffect(() => {
        if(loading && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/reseller/isSignedUpForCampaign'
            }).then((res) => {
                setIsSignedUp(res.data.ed_signedupforthisyearscampaign);
                setLoading(false);
            }).catch((err) => console.error(err));
        }
    }, [loading, inProgress, callApi]);

    const signUp = () => {
        callApi({
            method: 'get',
            url: '/api/reseller/signUpForCampaign'
        }).then((res) => {
            setIsSignedUp(true);
        }).catch((err) => console.error(err));
    }

    return (
        <div className="main-content">
            {loading ?
                <TextLoader style={{ display: 'block', width: '100%', maxWidth: '400px', margin: '0 auto'}} />
                :
                <>
                    { isSignedUp ?
                        <div className="content-primary content-primary-is-signed-up">
                            <h1>{t('campaign.title-feedback')}</h1>
                            <p>{t('campaign.text-feedback')}</p>
                        </div>
                        :
                        <div className="content-primary content-primary-not-signed-up">
                            <h1>{t('campaign.title-signup')}</h1>
                            <p>{t('campaign.text-signup')}</p>
                            <button
                                onClick={signUp}
                                type="button"
                                className="button-submit button-secondary"
                            >
                                {t('campaign.action-signup')}
                            </button>
                        </div>
                    }
                </>
            }
        </div>
    );
}