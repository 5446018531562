import React, { useEffect, useState, useCallback } from 'react';
// import { set } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

export default function OrderConfirmation(props) {

    const { t } = useTranslation();

    let [productsTotalSum, setProductsTotalSum] = useState(0);
    let [grandTotal, setGrandTotal] = useState(0);

    let calcProductsSum = useCallback((products) => {
        let total = 0;
        products.forEach((product) => {
            if (product.price) {
                total += parseInt(product.price);
            }
        });
        setProductsTotalSum(total);
        setGrandTotal(total + props.freight);

    }, [props.freight]);

    useEffect(() => {
        calcProductsSum(props.cartItems);
    }, [props.cartItems, calcProductsSum]);

    let renderProducts = (products) => {
        products.sort((a, b) =>
            a.price < b.price ? 1 : b.price < a.price ? -1 : 0
        );
        return products.map((product) => {
            return (
                <div
                    className="inner-row inner-row--product-row"
                    key={product.id}>
                    <p className="sub-title">{product.title}</p>
                    <p>{product.quantity} {t('orders.pieces')}</p>
                    <p>{product.price * product.quantity} {t('orders.currency')}</p>
                </div>
            );
        });
    };
    return (
        <div className="order-confirmation">
            <h1>{t('orders.order-confirmation')}</h1>
            <div className="cards">
                <div className="card card--customer">
                    <div className="outer-row">
                        <h3 className="sub-title">{t('orders.overview')}</h3>
                        <div className="inner-row">
                            <p>{t('orders.header.order-number')}</p>
                            <p>
                                {
                                    props.orderResponse.data.Header
                                        .PlaceOrderNumber
                                }
                            </p>
                        </div>
                        <div className="inner-row">
                            <p>{t('orders.order-status')}</p>
                            <p>
                                {props.orderResponse.data.status} {t('orders.pending')}
                            </p>
                        </div>
                        <div className="inner-row">
                            <p>{t('orders.order-marking')}</p>
                            <p>{props.orderData.GoodsMark}</p>
                        </div>
                        <div className="inner-row">
                            <p>{t('orders.payment-method')}</p>
                            <p>{t('orders.invoice')}</p>
                        </div>
                        <div className="inner-row">
                            <p>{t('orders.shipping')}</p>
                            <p>
                                {props.orderData.ShippingInstructions === ' '
                                    ? t('orders.standard-delivery')
                                    : t('orders.co-delivery')}
                            </p>
                        </div>
                    </div>
                    <div className="outer-row">
                        <h4 className="sub-title">{t('orders.billing-address')}</h4>
                        <div className="inner-row">
                            <p>{props.reseller.Name}</p>
                        </div>
                        <div className="inner-row">
                            <p>
                                {props.reseller.Address1}
                                {props.reseller.Address2 ?? ''}
                                {props.reseller.Address3 ?? ''}
                            </p>
                        </div>
                        <div className="inner-row">
                            <p>
                                {props.reseller.PostalCode}{' '}
                                {props.reseller.City}
                            </p>
                        </div>
                        <div className="inner-row">
                            {props.reseller.Country}
                        </div>
                    </div>
                    <div className="outer-row">
                        <h4 className="sub-title">{t('orders.delivery-address')}</h4>
                        <div className="inner-row">
                            <p>{props.orderData.addresses[0].Name}</p>
                        </div>
                        <div className="inner-row">
                            <p>
                                {props.orderData.addresses[0].Address1}
                                {props.orderData.addresses[0].Address2 ?? ''}
                                {props.orderData.addresses[0].Address3 ?? ''}
                            </p>
                        </div>
                        <div className="inner-row">
                            <p>
                                {props.orderData.addresses[0].PostalCode}{' '}
                                {props.orderData.addresses[0].City}
                            </p>
                        </div>
                        <div className="inner-row">
                            {props.orderData.addresses[0].Country}
                        </div>
                    </div>
                </div>

                <div className="card card--products">
                    <div className="outer-row">
                        <h3 className="sub-title">{t('orders.your-order')}</h3>
                        {renderProducts(props.cartItems)}
                    </div>
                    <div className="outer-row">
                        <h4 className="sub-title">{t('orders.items')}</h4>
                        <div className="inner-row">
                            <p>{t('orders.sum')}</p>
                            <p>{productsTotalSum} {t('orders.currency')}</p>
                        </div>
                        <div className="inner-row">
                            <p>{t('orders.shipping')}</p>
                            <p>{props.freight ?? '?'} {t('orders.currency')}</p>
                        </div>
                    </div>
                    <div className="outer-row">
                        <div className="inner-row inner-row--bold">
                            <h4 className="sub-title">{t('orders.total-sum')}</h4>
                            <h4 className="sub-title">{grandTotal} {t('orders.currency')}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <Link to="/order-add" className="button-secondary new-order-btn">
                {t('orders.place-new-order')}
            </Link>
        </div>
    );
}