/* eslint no-console: ["error", { allow: ["warn", "error", "log", "dir"] }] */
import React, { useState } from 'react';
import { ReactComponent as IconToggle } from '../../../images/icons/arrow-down-orange.svg'
import ProductList from '../../components/ProductList/';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import useAxios from 'scripts/hooks/useAxios';

export default function CategoryProductList(props) {
    const { callApi } = useAxios();
    const [products, setProducts] = useState([]);
    const { t } = useTranslation()

    const itemToggle = (self) => {
        let item = self.closest('.list-category-item')
        item.classList.toggle('list-category-item-has-visible-content');
    }

    const toggleProducts = (self, title, index) => {
        let
            item = self.closest('.list-category-item'),
            hasProductsLoaded = item.dataset.hasProductsLoaded || false;

        if(!hasProductsLoaded) {
            item.classList.add('list-category-item-loading')
            //let timerStart = Date.now()
            callApi({
                method: 'get',
                url: `/api/product/productsForModelSerie?modelSerie=${title}`
            }).then(response => {
                console.log(response.data.value)
                // Rename JSON keys
                let theProducts = response.data.value.map((o) => ({
                    'id' : o.productid,
                    'title': o.ed_namese,
                    'img': o.ed_urlpicture
                }));

                let newState = [...products]
                newState[index] = theProducts
                setProducts(newState);
                itemToggle(self)
                item.dataset.hasProductsLoaded = true
                item.classList.remove('list-category-item-loading')
                //console.log(title, response.data.value.length)
                //console.log('Loadtime', Date.now()-timerStart)
                //console.log(response.data.value)
            })
                .catch(error => console.error(error)) // eslint-disable-line no-console
        } else {
            itemToggle(self)
        }
    }

    const NoProducts = () => {
        return (
            <>
                <p className="list-product-empty">{t('my-pages.all-products-in-showroom')}</p>
            </>
        )
    };

    const listItems = (props) => {
        return props.items?.map((item, index) =>
            <div key={index} className="list-category-item">
                <div className="list-category-title" onClick={(event) => toggleProducts(event.target, item.title, index)}>{item.title}<span className="icon-toggle"><IconToggle /></span></div>
                <div className={`list-category-content${products[index] && products[index].length === 0 ? ' list-category-content-empty' : ''}`}>
                    {products[index] && products[index].length ?
                        (
                            <ProductList items={products[index]} index={index} add={true} toggleShowAddProducts={props.toggleShowAddProducts} />
                        ) : (<NoProducts />)
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="list-category">
            <div className="list-category-items">
                {listItems(props)}
            </div>
        </div>
    );
}