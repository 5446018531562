import React, { useEffect, useContext } from 'react';
import { CartContext } from '../../components/Context/cart/CartContext';
import CartItem from '../../components/Cart/shopping-cart-item';
import { formatNumber } from '../../components/Utils/numbers';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import Moment from 'moment'

const ShoppingCart = () => {

    useEffect(() => {
        cartLifetime();
    });

    const { total, cartItems, itemCount, clearCart } = useContext(CartContext);
    const { t } = useTranslation();

    const cartLifetime = () => {
        const cartLastModTimestamp = localStorage.getItem('cartLastMod');

        if(cartItems.length > 0) {
            let dtNow = Moment();
            let dtCartLastMod = Moment(cartLastModTimestamp, 'x');
            let cartAgeInDays = dtNow.diff(dtCartLastMod, 'days')

            if(cartAgeInDays > 30) {
                clearCart();
            }
        }
    }

    const toggleShoppingCart = () => {
        document.documentElement.classList.toggle('has-visible-shopping-cart');
    }

    return (
        <div className={`shopping-cart cart-container${itemCount < 1 ? ' cart-container-has-empty-cart' : ''}`}>
            <div className="cart-content">

                <div className="cart-header">
                    <div className="cart-title" data-cart-item-count={itemCount}>{t('cart.title')}</div>
                    <div className="cart-close" onClick={toggleShoppingCart}></div>
                </div>

                <div className="cart-main">
                    <div className="cart-line-items shopping-cart-line-items">
                        {cartItems.map(product => <CartItem key={product.id} product={product}/>)}

                        {cartItems.length < 1 && <div className="empty-cart">{t('cart.empty')}</div>}
                    </div>
                </div>

                <div className="cart-footer">
                    <div className="cart-summary">
                        <span className="cart-summary-label">{t('cart.subtotal')}</span>
                        <span className="cart-summary-value" data-cart-subtotal="">{formatNumber(total)}</span>
                    </div>

                    <p className="shopping-cart-disclaimer">{t('cart.disclaimer')}</p>
                    <Link
                        to="/checkout"
                        onClick={toggleShoppingCart}
                        className="button-secondary shopping-cart-checkout-btn">
                        <span className="btn-text">{t('button.to-checkout')}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ShoppingCart;