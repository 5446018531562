/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../../i18n';
import { useMsal, useAccount } from '@azure/msal-react';
import useAxios from 'scripts/hooks/useAxios';
import { InteractionStatus } from '@azure/msal-browser';
import { TableLoader } from '../../components/ContentLoader';

export default function Users() {
    const { accounts, inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation()
    const
        accountInfo = useAccount(accounts[0] || {}),
        account = accountInfo.idTokenClaims,
        isAdmin = account.extension_Roles.toLowerCase().includes('portal admin');

    const {
        register,
        handleSubmit,
        setValue
    } = useForm()

    const [users, setUsers] = useState(null)

    useEffect(() => {
        if (!users && inProgress === InteractionStatus.None) {

            callApi({
                method: 'get',
                url: '/api/user/usersForAccount'
            }).then((res) => {
                // Rename JSON keys
                let newUsers = res.data.value.map((o) => ({
                    'id': o.contactid,
                    'name': o.fullname,
                    'accountLevel': o.userrole
                }));
                setUsers(newUsers);
            })
                .catch((err) => console.error(err));
        }
    }, [users, inProgress, callApi]);

    const accountLevels = [
        {
            value: '',
            label: t('user.account-levels.non-active'),
        },
        {
            value: 'Reseller',
            label: t('user.account-levels.reseller'),
        },
        {
            value: 'Portal Admin',
            label: t('user.account-levels.admin'),
        },
        {
            value: 'Portal Admin,Reseller',
            label: t('user.account-levels.admin-reseller'),
        }
    ]

    const accountLabel = (accountLevel) => {
        let level = accountLevels.filter(d => d.value.toString() === accountLevel.toString())[0]
        return level.label
    }

    const toggleEdit = (event) => {
        let
            fields = document.querySelectorAll('.account-level'),
            editBox = event.target.closest('.edit-box');

        for (var i = 0; i < fields.length; i++) {
            fields[i].classList.toggle('field-hidden');
        }
        editBox.classList.toggle('edit-box-disabled');
    }

    const cancel = (event) => {
        toggleEdit(event)
    }

    const onSubmit = (data, event) => {

        let submitBtn = document.querySelector('.form-account .button-submit');
        submitBtn.classList.toggle('button-loading');

        const promises = data.users.map((user, index) => {
            return callApi({
                method: 'get',
                url: `/api/user/addOrUpdateAsAdmin?contactid=${user.id}&roles=${user.accountLevel}`,
            })
        })

        Promise.all(promises).then(response => {
            submitBtn.classList.toggle('button-loading');
            toggleEdit(event)
            setUsers(data.users)
        })
    };

    const itemClassName = (accountLevel) => {
        return `grid-item-edit-user${accountLevel === '' ? ' grid-item-user-disabled' : ''}`
    }

    const Users = ({ user, index }) => {
        return (
            <>
                <div className={itemClassName(user.accountLevel)}>{user.name}</div>
                <div className={itemClassName(user.accountLevel)}>{user.email}</div>
                <div className={itemClassName(user.accountLevel)}>
                    <input
                        type="hidden"
                        {...register(`users.${index}.name`)}
                        {...setValue(`users.${index}.name`, user.name)}
                    />
                    <input
                        type="hidden"
                        {...register(`users.${index}.email`)}
                        {...setValue(`users.${index}.email`, user.email)}
                    />
                    <input
                        type="hidden"
                        {...register(`users.${index}.id`)}
                        {...setValue(`users.${index}.id`, user.id)}
                    />
                    <span className="account-level">{accountLabel(user.accountLevel)}</span>
                    <div className="field-select account-level account-level field-hidden">
                        <select
                            className="field field-select-input"
                            {...register(`users.${index}.accountLevel`)}
                            defaultValue={user.accountLevel}
                        >
                            {accountLevels.map((option, index) => <Options option={option} key={option.value} />)}
                        </select>
                    </div>
                </div>
            </>
        )
    }

    const Options = ({ option }) => {
        return (
            <>
                <option value={option.value}>{option.label}</option>
            </>
        )
    }

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('user.admin.title')}</h1>
                <p>
                    {t('user.admin.intro')} <Link to="/contact" >{t('user.admin.contact-your-sales-representative')}</Link>.
                </p>
            </div>
            {users &&
                <div className="content-secondary">
                    <div className="edit-box edit-box-disabled">
                        <div className="edit-box-header">
                            <div className="edit-box-title"></div>
                            {isAdmin &&
                                <>
                                    <div className="edit-box-btn edit-box-btn-edit" onClick={toggleEdit}>{t('button.edit')}</div>
                                    <div className="edit-box-btn edit-box-btn-update" onClick={cancel}>{t('button.cancel')}</div>
                                </>
                            }
                        </div>

                        <div className="edit-box-content">
                            <form className="form-account" onSubmit={handleSubmit(onSubmit)}>
                                <div className="grid-edit-user" >
                                    {users?.map((user, index) => <Users user={user} index={index} key={user.id} />)}
                                </div>

                                <div className="buttongroup">
                                    <button type="button" className="button-cancel button-tertiary" onClick={cancel}>{t('button.cancel')}</button>
                                    <button type="submit" className="button-submit button-primary"><span>{t('button.submit')}</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            {!users &&
                <TableLoader />
            }

        </div>
    );
}