import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.sass';
import i18next from 'i18next';
import Router from './scripts/components/Router/';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
});

console.log(process.env.NODE_ENV); //! TEST
console.log(process.env.REACT_APP_TEST_ENV_VAR); //! TEST

ReactDOM.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance} >
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</MsalProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

document.getElementById('root').classList.add('page-container');
document.documentElement.lang = i18next.language;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
