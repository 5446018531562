/* eslint no-console: ["error", { allow: ["error", "log"] }] */
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';
import { protectedResources } from '../../authConfig';
import axios from 'axios';
import { useCallback } from 'react';

const useAxios = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    // Add a response interceptor
    axios.interceptors.response.use((response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
            instance.logoutRedirect().catch((e) => {
                console.error(e);
            });
        }
        return Promise.reject(error);
    });

    const getToken = useCallback(async() => {
        const silentRequest = {
            account,
            ...protectedResources.api,
            forceRefresh: false,
        };

        try {
            const response = await instance.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (error) {
            if (
                InteractionRequiredAuthError.isInteractionRequiredError(
                    error.errorCode
                )
            ) {
                instance.logoutRedirect().catch((e) => {
                    console.error(e);
                });
                // instance.acquireTokenRedirect(request);
            }
        }
    }, [account, instance]);

    const callApi = useCallback(async(config) => {
        const accessToken = await getToken();

        return axios({
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }, [getToken]);

    return { callApi };
};

export default useAxios;