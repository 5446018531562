import React, { useState, useEffect } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { TableLoader } from '../../components/ContentLoader';
import { Link } from 'react-router-dom';

export default function Complaints() {
    const { callApi } = useAxios();
    const { inProgress } = useMsal();
    const [complaintsData, setComplaintsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (
            complaintsData.length === 0 &&
            inProgress === InteractionStatus.None &&
            loading
        ) {
            callApi({
                method: 'get',
                url: '/api/complaint/openComplaints',
            }).then((response) => {
                setComplaintsData(response.data.value);
                setLoading(false);
            });
        }
    }, [complaintsData, loading, inProgress, callApi]);

    const complaintsColumns = React.useMemo(
        () => [
            {
                Header: t('complaints.table-header.created'),
                accessor: 'createdon',
                Cell: ({ value }) => {
                    return new Date(value).toLocaleString([], {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    });
                },
            },
            {
                Header: t('complaints.table-header.complaints-id'),
                accessor: (row) => {
                    return row.ed_casenumber ? 'ed_casenumber' : 'ticketnumber';
                },
                Cell: function Tracking(cell) {
                    return (
                        <Link to={`/complaint/${cell.row.original.incidentid}`}>
                            {cell.row.original.ed_casenumber ? cell.row.original.ed_casenumber : cell.row.original.ticketnumber}
                        </Link>
                    );
                },
            },
            {
                Header: t('complaints.table-header.mark'),
                accessor: 'edp_ordermark',
            },
            {
                Header: t('complaints.table-header.related-order'),
                accessor: 'edp_ordernumber',
            },
            {
                Header: t('complaints.table-header.created-by'),
                accessor: (row) => {
                    return row.primarycontactid?.fullname;
                },
            },
            {
                Header: t('complaints.table-header.status'),
                accessor: 'edp_portalstatus',
                Cell: ({ value }) => {
                    switch (value) {
                        case 757550000:
                            return t('complaints.status.registered');
                        case 757550001:
                            return t('complaints.status.ongoing');
                        case 757550002:
                            return t('complaints.status.completed');
                        case 757550003:
                            return t('complaints.status.waiting-for-details');
                        default:
                            return value;
                    }
                },
            },
        ],
        [t]
    );

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('complaints.open.title')}</h1>
                <p>{t('complaints.open.text')}</p>
            </div>
            {loading ? (
                <TableLoader />
            ) : (
                <div className="content-secondary">
                    <h2>{t('complaints.open.complaints')}</h2>
                    <DataTable
                        columns={complaintsColumns}
                        data={complaintsData}
                        className="table-default"
                        limit="2"
                        getRowProps={(rowInfo) => {
                            if (rowInfo.values.Status) {
                                switch (rowInfo.values.Status) {
                                    case '10':
                                        return {
                                            className: 'status status-pending',
                                        };
                                    case '20':
                                        return {
                                            className: 'status status-open',
                                        };
                                    case '30':
                                        return {
                                            className: 'status status-closed',
                                        };
                                    case '40':
                                        return {
                                            className: 'status status-waiting',
                                        };
                                    default:
                                        return {};
                                }
                            }
                            return {};
                        }}
                    />
                </div>
            )}
        </div>
    );
}