
const Storage = (cartItems) => {
    localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems: []));
}

export const sumItems = cartItems => {
    Storage(cartItems);
    let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    let total = cartItems.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    return { itemCount, total }
}

export const CartReducer = (state, action) => {
    let
        currentDate = new Date(),
        timestamp = currentDate.getTime(),
        cartItems,
        index;
    if(action.payload) {
        index = state.cartItems.findIndex(item => item.id === action.payload.id);
    }

    localStorage.setItem('cartLastMod', timestamp);

    switch (action.type) {
        case "ADD_ITEM":
            if (index < 0) {
                cartItems = [...state.cartItems]
                cartItems.push({ ...action.payload, quantity: action.payload.addQty });

            } else {
                cartItems = [
                    ...state.cartItems.slice(0, index),
                    {...state.cartItems[index], quantity: state.cartItems[index].quantity + action.payload.addQty},
                    ...state.cartItems.slice(index + 1)
                ];
            }
            return {
                ...state,
                ...sumItems(cartItems),
                cartItems: cartItems
            }

        case "UPDATE":
            state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].quantity = action.payload.quantity
            if(state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].quantity === 0) {
                return {
                    ...state,
                    ...sumItems(state.cartItems.filter(item => item.id !== action.payload.id)),
                    cartItems: [...state.cartItems.filter(item => item.id !== action.payload.id)]
                }
            }
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }

        case "UPDATE_PRICE":
            state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].price = action.payload.price
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }

        case "REMOVE_ITEM":
            cartItems = [...state.cartItems];
            cartItems.splice(index, 1)
            return {
                ...state,
                ...sumItems(cartItems),
                cartItems: cartItems
            }

        case "INCREASE":
            cartItems = [
                ...state.cartItems.slice(0, index),
                {...state.cartItems[index], quantity: state.cartItems[index].quantity + 1},
                ...state.cartItems.slice(index + 1)
            ];
            return {
                ...state,
                ...sumItems(cartItems),
                cartItems: cartItems
            }

        case "DECREASE":
            if(state.cartItems[index] && state.cartItems[index].quantity === 1) {
                // Remove
                cartItems = [...state.cartItems];
                cartItems.splice(index, 1)
            } else if(state.cartItems[index]) {
                // Decrease
                cartItems = [
                    ...state.cartItems.slice(0, index),
                    {...state.cartItems[index], quantity: state.cartItems[index].quantity - 1},
                    ...state.cartItems.slice(index + 1)
                ];
            }
            return {
                ...state,
                ...sumItems(cartItems),
                cartItems: cartItems
            }

        case "CHECKOUT":
            return {
                cartItems: [],
                checkout: true,
                ...sumItems([]),
            }

        case "CLEAR":
            return {
                cartItems: [],
                ...sumItems([]),
            }
        default:
            return state

    }
}