/* eslint no-console: ["error", { allow: ["error"] }] */

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ProductList from '../../components/ProductList/';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal } from '@azure/msal-react';
import useAxios from 'scripts/hooks/useAxios';
import { InteractionStatus } from '@azure/msal-browser';
import { ProductListLoader} from '../../components/ContentLoader';

export default function ProductsCurrent() {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [myProducts, setMyProducts] = useState([]);

    useEffect(() => {
        if(myProducts.length === 0 && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/product/myproducts',
            }).then((res) => {
                let myNewProducts = res.data.value.map((o) => ({
                    'id' : o.ed_showroomid,
                    'productNumber' : o.ed_ProductId.productnumber,
                    'title': o.ed_ProductId.ed_namese,
                    'isLiveDisplay': o.ed_combustible,
                    'img': o.ed_ProductId.ed_urlpicture
                }));
                setMyProducts(myNewProducts);
                setLoading(false);
            }).catch((err) => console.error(err));
        }
    }, [myProducts.length, inProgress, callApi]);

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('my-pages.products-in-showroom-title')}</h1>
                <p>{t('my-pages.products-in-showroom-description')}</p>
                <Link to="/my-pages/add-products" className="button-secondary">{t('my-pages.add-products')}</Link>
            </div>

            {loading ?
                <ProductListLoader />
                :
                <ProductList items={myProducts} />
            }
        </div>
    );
}