import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";
import CategoryProductList from '../../components/ProductList/category-product-list';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { TableLoader } from '../../components/ContentLoader';

export default function ProductsCurrent() {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation()
    const [products, setProducts] = useState(null);

    useEffect(() => {
        if(!products && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/product/allmodelseries'
            }).then((res) => {
                let newProducts = res.data.value.map((o) => ({
                    title: o.ed_modelserie
                }));
                setProducts(newProducts);
            }).catch((err) => console.error(err)); // eslint-disable-line no-console
        }
    }, [products, inProgress, callApi]);

    return (
        <div className="main-content">

            <div className="content-primary">
                <h1>{t('my-pages.add-products-title')}</h1>
                <p>{t('my-pages.add-products-description')}</p>
                <Link
                    to="/my-pages/products-in-showroom"
                    className="button-secondary"
                >
                    {t('button.products-in-showroom')}
                </Link>
            </div>
            {!products ? (
                <TableLoader />
            ) : (
                <CategoryProductList
                    items={products}
                    toggleShowAddProducts={false}
                />
            )}
        </div>
    );
}