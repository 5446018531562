/* eslint no-console: ["error", { allow: ["warn", "error", "log", "dir"] }] */
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { Prompt } from 'react-router';
import Header from '../../components/Header/';
import ProgressStep from '../../components/CPQ/ProgressStep';
import Page from '../../components/CPQ/Page';
import CPQInfoPanel from '../../components/CPQ/CPQInfoPanel';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from '../../components/Confirm';
import '../../../i18n';
import ConfigSummary from '../../components/CPQSummary/index';
import ShoppingCart from '../../components/Cart/shopping-cart';
import Navigation from '../../components/Navigation';
import { CpqLoader } from '../../components/ContentLoader';
import { CartContext } from '../../components/Context/cart/CartContext';

const CPQ = withRouter(({ history, location, ...props }) => {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(false);
    const [isExecutionComplete, setIsExecutionComplete] = useState(false);
    const [isConfigFinished, setIsConfigFinished] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeInfoIndex, setActiveInfoIndex] = useState(2);
    const [config, setConfig] = useState(null);
    const [finishedConfig, setFinishedConfig] = useState(null);
    const [infoImage, setInfoImage] = useState(null);
    const [inCart, setInCart] = useState(false);
    const { cartItems } = useContext(CartContext);

    const sessionId = useRef(null);
    const headerId = useRef(null);
    const configurationId = useRef(null);
    const values = useRef([]);

    const startConfig = useCallback(
        (ruleset) => {
            console.log(`Starting config using ruleset: ${ruleset}`);
            headerId.current = cartItems.find((item) => item.headerId)?.headerId;
            callApi({
                method: 'get',
                url: `/api/cpq/v2/init?ruleset=${ruleset}${headerId.current ? `&headerId=${headerId.current}` : ''}`,
            })
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res.data);
                        configurationId.current = res.data.ConfigurationId;
                        headerId.current = res.data.HeaderId;
                        sessionId.current = res.data.SessionId;
                        callApi({
                            method: 'post',
                            url: '/api/cpq/v2/config',
                            data: {
                                sessionId: sessionId.current,
                                values: [],
                            },
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log(res.data);
                                    setActiveIndex(res.data.Pages.length - 1);
                                    setConfig(res.data);
                                    setLoading(false);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        [callApi, cartItems]
    );

    useEffect(() => {
        if (location?.state?.ruleset) {
            if (
                !configurationId.current &&
                inProgress === InteractionStatus.None
            ) {
                startConfig(location.state.ruleset);
            }
        } else {
            history.push({
                pathname: '/order-add',
            });
        }
    }, [inProgress, history, location, startConfig]);

    useEffect(() => {
        let activePage = document.querySelector('.cpq-page--active');

        if (activePage) {
            let lastScreen = activePage.lastChild,
                control = lastScreen.querySelector('.cpq-input-field-number');

            setTimeout(() => {
                lastScreen.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
                if (control) {
                    control.focus();
                }
            }, 250);
        }
    }, [loadingPage]);

    useEffect(() => {
        if (inCart) {
            history.push('/checkout');
        }
    });

    const handleContinue = () => {
        console.log('Continue config');
        console.log({
            sessionId: sessionId.current,
            values: values.current,
        });
        setLoadingPage(true);
        callApi({
            method: 'post',
            url: '/api/cpq/v2/config',
            data: {
                sessionId: sessionId.current,
                values: values.current,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    let title = '',
                        msg = [],
                        message,
                        msgType;

                    if (res.data.Messages.length) {
                        res.data.Messages.forEach((message) => {
                            switch (message.Type) {
                                case 1:
                                    title = t('cpq.confirm.bad-value');
                                    break;
                                case 3:
                                    title = t('cpq.confirm.oops');
                                    break;
                                default:
                                    title = t('cpq.confirm.information');
                            }
                            msgType = message.Type;
                            msg.push(message.Value);
                        });

                        switch (msgType) {
                            case 3:
                                message = t(
                                    'cpq.confirm.unexpected-error-occurred'
                                );
                                break;
                            default:
                                message = msg.join(', ');
                        }
                        setLoadingPage(false);

                        confirmAlert({
                            title: title,
                            message: message,
                            buttons: [
                                {
                                    label: t('cpq.confirm.okay'),
                                    className: 'button-primary',
                                },
                            ],
                        });
                    }
                    setLoadingPage(false)
                }
                if (res.data.Pages) {
                    values.current = [];
                    setConfig(res.data);
                    setActiveIndex(res.data.Pages.length - 1);
                    setIsExecutionComplete(res.data.IsExecutionComplete);
                    if (res.data.IsExecutionComplete) {
                        setActiveInfoIndex(1);
                        setInfoImage(null)
                    } else {
                        // setActiveInfoIndex(2);
                        setInfoImage(null)
                    }
                }
            })
            .catch((error) => {
                confirmAlert({
                    title: t('cpq.confirm.oops'),
                    message: t('cpq.confirm.unexpected-error-occurred'),
                    buttons: [
                        {
                            label: t('cpq.confirm.okay'),
                            className: 'button-primary',
                        },
                    ],
                }); 
                setLoadingPage(false);
                console.error(error);
            });
    };

    const handleFinish = () => {
        setLoadingPage(true);
        console.log('Finish config');
        console.log({
            sessionId: sessionId.current,
            headerId: headerId.current,
            configurationId: configurationId.current,
        });

        callApi({
            method: 'post',
            url: '/api/cpq/v2/complete',
            data: {
                sessionId: sessionId.current,
                headerId: headerId.current,
                configurationId: configurationId.current,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    setFinishedConfig(res.data);
                    setIsConfigFinished(true);
                    setLoadingPage(false);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCancel = () => {
        history.push('/order-add');
    };

    const handleTabClick = (index) => {
        console.log('Tab clicked');
        console.log(index);
        setActiveIndex(index);
    };

    const handleInputChange = (id, value) => {
        console.log('Input changed');
        console.log(`${id} : ${value}`);
        console.log(values.current);
        const index = values.current.findIndex((el) => el.ID === id);
        if (index >= 0) {
            values.current[index].Value = value;
        } else {
            values.current.push({
                ID: id,
                Value: value,
            });
        }
        console.log(values.current);
        setIsExecutionComplete(false);
    };

    const handleInfoClick = (image) => {
        setInfoImage(image);
        setActiveInfoIndex(4);
    };

    const handleAddedToCart = () => {
        setInCart(true);
    };

    const getSummary = useCallback((pages) => {
        const summary = [];

        pages.forEach((page) => {
            if (page.IsVisible) {
                summary.push({
                    Caption: page.Caption,
                    Type: 0,
                    Value: null
                });

                page.Screens.forEach((screen) => {
                    if (screen.IsVisible) {
                        summary.push({
                            Caption: screen.Caption,
                            Type: 1,
                            Value: null
                        });

                        screen.ScreenOptions?.forEach((option) => {
                            if (option.IsVisible) {
                                let value = '';

                                if (option.DisplayFormat === 'Decimal') {
                                    value = option.Value;
                                } else if (option.DisplayType === 'CheckBox') {
                                    value = option.Value === "True" ? t('cpq.yes') : t('cpq.no');
                                } else {
                                    option.SelectableValues.every((select) => {
                                        if (select.Value === option.Value) {
                                            value = select.Caption;
                                            return false
                                        }
                                        return true;
                                    });
                                }

                                summary.push({
                                    Caption: option.Caption,
                                    Type: 2,
                                    Value: value
                                });
                            }
                        });
                    }
                });
            }
        });

        return summary;
    }, [t]);

    const buildUI = (ui) => {
        console.log('Build UI');
        return (
            <div className="page-content">
                <div className="main-container">
                    <Header />
                    <main className="cpq-main-content">
                        {!loading &&
                            <>
                                {!isConfigFinished ? (
                                    <>
                                        <div className={`cpq-page-content${ui.ImageUrl ? ' cpq-page-content-has-image' : ''}`}>
                                            <div className="cpq-pages">
                                                <div className="cpq-progress-steps">
                                                    {ui.Pages.map((page, index) => (
                                                        <ProgressStep
                                                            caption={page.Caption}
                                                            isActive={activeIndex === index}
                                                            onClick={handleTabClick}
                                                            index={index}
                                                            key={index}
                                                        />
                                                    ))}
                                                </div>
                                                {buildPages(ui.Pages)}
                                                {loadingPage &&
                                                    <div className="page-is-loading">
                                                        <svg className="loading-spinner" viewBox="0 0 50 50">
                                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <CPQInfoPanel
                                                details={ui.Details}
                                                summary={getSummary(ui.Pages)}
                                                image={ui.ImageUrl}
                                                infoImage={infoImage}
                                                activeIndex={activeInfoIndex}
                                                setActiveIndex={setActiveInfoIndex}
                                            />
                                        </div>
                                        <div className="cpq-footer">
                                            <div className="cpq-footer-buttons">
                                                <button
                                                    onClick={handleCancel}
                                                    className="button-submit button-tertiary"
                                                >
                                                    {t('cpq.cancel')}
                                                </button>
                                                <button
                                                    onClick={
                                                        isExecutionComplete
                                                            ? handleFinish
                                                            : handleContinue
                                                    }
                                                    disabled={loadingPage}
                                                    className={`button-submit button-${isExecutionComplete ? 'primary' : 'secondary'}`}
                                                >
                                                    {isExecutionComplete ? t('cpq.finish') : t('cpq.continue')}
                                                </button>
                                            </div>
                                            <p className="cpq-price">
                                                {ui.ConfiguredPrice !== 0 &&
                                                    <>
                                                        <span className="cpq-price-details">
                                                            <span className="cpq-price-label">{t('cpq.price')}</span>
                                                            <span className="cpq-price-value">{ui.ConfiguredPrice.toLocaleString()} {t('cpq.currency')}</span>
                                                        </span>
                                                        <span className="cpq-incl-vat">{t('cpq.excl-vat')}</span>
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <ConfigSummary
                                        config={finishedConfig}
                                        addedToCart={handleAddedToCart}
                                        inCart={inCart}
                                        history={history}
                                        ruleset={location.state.ruleset}
                                        headerId={headerId.current}
                                    />
                                )}
                            </>
                        }
                        {loading &&
                            <CpqLoader />
                        }
                    </main>
                </div>
                <Navigation />
                <ShoppingCart />
            </div>
        );
    };

    const buildPages = (pages) => {
        console.log('Build pages');
        return pages.map((page, index) => {
            if (page.IsVisible) {
                return (
                    <Page
                        index={index}
                        isActive={activeIndex === index}
                        key={index}
                        screens={page.Screens}
                        onChange={handleInputChange}
                        onContinue={handleContinue}
                        onInfo={handleInfoClick}
                        loadingPage={loadingPage}
                    />
                );
            } else {
                return null;
            }
        });
    };

    console.log('RENDER!');
    return (
        <>
            <Prompt
                when={location?.state?.ruleset && !isConfigFinished}
                message={t('cpq.configuration-not-finished')}
            />
            <Prompt
                when={isConfigFinished && !inCart}
                message={t('cpq.configuration-not-added-to-cart')}
            />
            {buildUI(config)}
        </>
    );
});

export default CPQ;