import React from 'react';

function campaignItemXD(item) {
    let originalDiscountText = `${item.OriginalBasePrice}-${item.NormalDiscount}%=${item.OriginalNetPrice}`,
        campaignDiscountText = `${item.OriginalNetPrice}-${item.CampaignDiscount}%=${item.CampaignNetPrice}`,
        discount = (
            ((item.OriginalBasePrice - item.CampaignNetPrice) /
                item.OriginalBasePrice) *
            100
        ).toFixed(2),
        total = item.CampaignNetPrice * item.Quantity;

    return (
        <tr className="item item--campaign-xd" key={item.ItemNo}>
            <td className="item-number">{item.ItemNo}</td>
            <td className="item-description">
                {item.Description}
                <div className="campaign-text">{originalDiscountText}</div>
                <div className="campaign-text">{campaignDiscountText}</div>
            </td>
            <td></td>
            <td className="item-quantity">{item.Quantity}</td>
            <td className="item-unit-price">{item.OriginalBasePrice}</td>
            <td className="item-discount">{discount}%</td>
            <td className="item-total">{total}</td>
        </tr>
    );
}

function campaignItemRP(item) {
    let originalDiscountText = `${item.OriginalBasePrice}-${item.NormalDiscount}&=${item.OriginalNetPrice}`,
        campaignDiscountText = `${item.OriginalBasePrice}-${item.CampaignDiscount}&=${item.CampaignNetPrice}`,
        discount = (
            ((item.OriginalBasePrice - item.CampaignNetPrice) /
                item.OriginalBasePrice) *
            100
        ).toFixed(2),
        total = item.CampaignNetPrice * item.Quantity;
    return (
        <tr className="item item--campaign-xd" key={item.ItemNo}>
            <td className="item-number">{item.ItemNo}</td>
            <td className="item-description">
                {item.Description}
                <div className="campaign-text">{originalDiscountText}</div>
                <div className="campaign-text">{campaignDiscountText}</div>
            </td>
            <td className="item-quantity">{item.Quantity}</td>
            <td className="item-unit-price">{item.OriginalBasePrice}</td>
            <td className="item-discount">{discount}%</td>
            <td className="item-total">{total}</td>
        </tr>
    );
}

function originalPrice(item) {
    let total = (item.OriginalNetPrice * item.Quantity).toFixed(2);

    return (
        <tr className="item item--org-price" key={item.ItemNo}>
            <td className="item-number">{item.ItemNo}</td>
            <td className="item-description">{item.Description} </td>
            <td className="item-quantity">{item.Quantity}</td>
            <td className="item-unit-price">{item.OriginalBasePrice}</td>
            <td className="item-discount">{item.NormalDiscount}%</td>
            <td className="item-total">{total}</td>
        </tr>
    );
}

function attachedItem(item) {
    return (
        <tr className="item item--attached" key={item.ItemNo}>
            <td className="item-number">{item.ItemNo}</td>
            <td className="item-description">{item.Description} </td>
            <td className="item-quantity">{item.Quantity}</td>
            <td className="item-unit-price"></td>
            <td className="item-discount"></td>
            <td className="item-total"></td>
        </tr>
    );
}
let parseOrNull = (val) => {
    if (val === null) {
        return null;
    }
    return parseInt(val).toFixed(2);
};
let parseItem = (item) => {
    return {
        ItemNo: item.ItemNo,
        Description: item.Description,
        Quantity: parseInt(item.Quantity),
        Price: parseOrNull(item.Price),
        Uom: item.Uom,
        OriginalBasePrice: parseOrNull(item.OriginalBasePrice),
        OriginalNetPrice: parseOrNull(item.OriginalNetPrice),
        OriginalDiscount: parseOrNull(item.OriginalDiscount),
        NormalDiscount: parseOrNull(item.NormalDiscount),
        Factor: parseOrNull(item.Factor),
        CampaignBasePrice: parseOrNull(item.CampaignBasePrice),
        CampaignNetPrice: parseOrNull(item.CampaignNetPrice),
        CampaignFactor: parseOrNull(item.CampaignFactor),
        CampaignDiscount: parseOrNull(item.CampaignDiscount),
        CampaignDiscountType: item.CampaignDiscountType,
        CampaignCampaignID: item.CampaignCampaignID,
    };
};

export {
    originalPrice,
    attachedItem,
    campaignItemXD,
    campaignItemRP,
    parseItem,
};