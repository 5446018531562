/* eslint no-console: ["error", { allow: ["error", "log"] }] */
import React  from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoader } from '../../components/ContentLoader';
import '../../../i18n';
import { formatNumber } from '../../components/Utils/numbers';
import { formatDate } from '../../components/Utils/dates';

const Invoice = ({invoiceLoading, invoice}) => {
    const { t } = useTranslation();
    let lines = [];

    if(invoice) {
        lines = invoice.DetLines.filter(function(el) {
            return el.LineSequence !== 0
        });
    }

    const InvoiceItem = ({item}) => {
        return (
            <tr>
                <td>{item.ItemNumber}</td>
                <td>
                    <p>{item.Description}</p>
                </td>
                <td>{item.OrderedQty}</td>
                <td>{formatNumber(item.Price)}</td>
                <td>{item.Discount}</td>
                <td>{formatNumber(item.NetSalesAmt)}</td>
            </tr>
        )
    }

    const closeModal = () => {
        document.documentElement.classList.remove('show-nb-modal')
    }

    return (
        <div className="nb-modal single-invoice">
            <div className="nb-modal-container">
                <div
                    className="nb-modal-close"
                    onClick={() => closeModal()}
                ></div>
                <div className="nb-modal-content">
                    {invoiceLoading &&
                        <TableLoader />
                    }

                    {!invoiceLoading &&
                        <>
                            {lines.length > 0 &&
                                <>
                                    <h1>{t('finance.invoice')}</h1>
                                    <p>{t('finance.header.invoice-number')}: <b>{invoice.InvoiceNumber}</b></p>
                                    <p>{t('finance.header.order-number')}: {invoice.OrderNumber}</p>
                                    {invoice.OrderMark && <p>{t('finance.header.invoice-mark')}: {invoice.OrderMark}</p>}
                                    <p>{t('finance.header.invoice-date')}: {formatDate(String(invoice.InvoiceDate))}</p>
                                    <p>{t('finance.header.due-date')}: {formatDate(String(invoice.NetDueDate))}</p>
                                    <table className="table-fixed">
                                        <thead>
                                            <tr>
                                                <th>{t('order.table-header.article-nr')}</th>
                                                <th>{t('order.table-header.article')}</th>
                                                <th>{t('order.table-header.quantity')}</th>
                                                <th>{t('order.table-header.price')}</th>
                                                <th>{t('order.table-header.discount')} %</th>
                                                <th>{t('order.table-header.sum-excl-vat')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lines.map((item, i) => <InvoiceItem key={i} item={item}/>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="4"></td>
                                                <td>
                                                    {t('finance.header.sum')}<br />
                                                    {t('finance.header.freight')}<br />
                                                    {t('finance.header.vat')} 25%<br />
                                                </td>
                                                <td>
                                                    {formatNumber(invoice.InvoiceNetTot)}<br />
                                                    {formatNumber(invoice.Freight)}<br />
                                                    {formatNumber(invoice.VAT)}<br />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"></td>
                                                <td>
                                                    {t('finance.header.totalsum')}
                                                </td>
                                                <td>
                                                    <b>{formatNumber(invoice.InvoiceTotal)}</b>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <div className="nb-modal-overlay" onClick={() => closeModal()}></div>
        </div>
    )
}

export default Invoice;