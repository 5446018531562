import React from 'react';

function ProgressStep({index, isActive, caption, onClick}) {
    const handleClick = () => onClick(index);
    return (
        <span
            className={`cpq-progress-step${isActive ? ' cpq-progress-step-current' : ''}`}
            onClick={handleClick}
        >
            {caption}
        </span>
    );
}

export default ProgressStep;