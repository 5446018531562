import React from 'react';
import SalesFigures from '../../components/SalesFigures/';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

const Finance = () => {
    const { t } = useTranslation();
    return (
        <div className="main-content">
            <h1>{t('finance.statistics-title')}</h1>
            <SalesFigures />
        </div>
    );
}

export default Finance;