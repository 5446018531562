import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ErrorMessage from '../../components/Forms/error-message';
import { ReactComponent as IconClear } from '../../../images/icons/close.svg'
import { ReactComponent as IconPlus } from '../../../images/icons/plus-2.svg'
import { ReactComponent as IconMinus } from '../../../images/icons/minus-2.svg'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { useMsal, useAccount } from '@azure/msal-react';
import { alertLoadingError } from '../../components/Confirm';

export default function ComplaintNew(props) {
    const { callApi } = useAxios();
    const { accounts, inProgress } = useMsal();
    let { searchId } = useParams();
    const [orderNumber, setOrderNumber] = useState('');
    const [incident, setIncident] = useState();
    const [loadingPage, setLoadingPage] = useState(false);
    const [articles, setArticles] = useState([]);
    const [user, setUser] = useState([]);
    const { t } = useTranslation();
    const [filesToSubmit, setFilesToSubmit] = useState([]);
    const
        accountInfo = useAccount(accounts[0] || {}),
        account = accountInfo.idTokenClaims;

    useEffect(() => {
        if (user.length === 0 && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: `/api/reseller/postalAddress`
            })
                .then(response => {
                    setUser({
                        company: account.extension_Company,
                        address: response.data.address1_line1,
                        zip: response.data.address1_postalcode,
                        city: response.data.address1_city,
                    })
                }).catch(error => {
                    setLoadingPage(false)
                    alertLoadingError(t)
                    console.error(error) // eslint-disable-line no-console
                })
        }
    }, [user, inProgress, callApi, account]);  // eslint-disable-line react-hooks/exhaustive-deps

    const toggleComplaintsSection = () => {
        let complaintsSection = document.querySelectorAll('.complaints-section');

        for (var i = 0; i < complaintsSection.length; i++) {
            complaintsSection[i].classList.toggle('hide');
        }
        reset({ orderNumber: '' })
    }

    const toggleHide = (self) => {
        let targetElements = document.querySelectorAll(self.dataset.target);
        for (var i = 0; i < targetElements.length; i++) {
            targetElements[i].classList.toggle('hide')
        }
    }

    const orderRequireSerial = () =>
        getValues2('products').some((el) => el.edp_quantity > 0 && el.Require_Serial);

    const verifyStoveBlock = () => {
        let verifyStoveBlock = document.querySelector('.verify-stove-block');

        if (orderRequireSerial()) {
            verifyStoveBlock.classList.remove('hide')
        } else {
            verifyStoveBlock.classList.add('hide')
        }
    }

    // form to search articles with ordernumber/serialnumber
    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
    });

    // form to send complaint
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        getValues: getValues2,
        reset: reset2,
        setError: setError2,
        formState: { errors: errors2 },
        clearErrors: clearErrors2
    } = useForm();

    /*
     * STEP ONE
     * Validates ordernumber/serialnumber &
     * Get articles
     */
    const onSubmit = (data) => {
        const { searchId } = data;

        if (isNaN(searchId)) {
            return setError('searchId', {
                type: 'manual',
                message: t('complaints.search-format-error'),
            });
        }

        const btn = document.querySelector('.btn-validate-order-number');
        btn.classList.add('btn-loading');

        callApi({
            method: 'get',
            url: `/api/complaint/order?searchId=${searchId}`
        }).then(response => {
            if (response.data.OrderNumber !== '' && response.data.OrderNumber !== 'ERR0001') {
                setArticles(response.data.Lines);
                btn.classList.remove('btn-loading');
                toggleComplaintsSection();
                setOrderNumber(response.data.OrderNumber);
                setValue2('orderNumber', ' ' + response.data.OrderNumber);
            } else {
                btn.classList.remove('btn-loading');
                setError('searchId', {
                    type: 'manual',
                    message: searchId.length >= 10 ? t('complaints.serialnumber-error') : t('complaints.ordernumber-error'),
                });
            }
        }).catch(error => {
            btn.classList.remove('btn-loading');
            alertLoadingError(t);
            console.error(error); // eslint-disable-line no-console
        });

        // getArticlesInOrder(data.searchId);
    };

    /*
     * STEP TWO
     * Send complaint &
     * Show feedback
     */
    const onSubmit2 = (data, event) => {
        // Remove articles without quantity
        data.products = data.products.filter(function(el) {
            return el.edp_quantity > 0
        });

        if (!data.products.length) {
            return setError2('products', {
                type: 'manual',
                message: t('complaints.no-articles-error'),
            });
        }

        setLoadingPage(true);

        const formData = new FormData();

        let complaintsSection = document.querySelectorAll('.complaints-section');



        // Remove Require_Serial to avoid server error when posting order
        for (var key in data.products) {
            data.products[key].edp_quantity = data.products[key].edp_quantity.toString(); //eslint-disable-line
            delete data.products[key].Require_Serial;
        }

        for (let i = 0; i < data.file.length; i++) {
            formData.append('file', data.file[i]);
        }

        delete data.file;

        formData.append('data', JSON.stringify(data));

        callApi({
            method: 'post',
            url: '/api/complaint',
            data: formData,
        }).then((response) => {
            setIncident(response.data);
            setLoadingPage(false);
            for (var i = 0; i < complaintsSection.length; i++) {
                complaintsSection[i].classList.add('hide');
            }
            document.querySelector('.complaints-feedback').classList.remove('hide');
            document.querySelector('.verify-stove-block').classList.add('hide');
            event.target.reset();
            reset2();
        }).catch(error => {
            setLoadingPage(false);
            alertLoadingError(t)
            console.error(error) // eslint-disable-line no-console
        });
    }

    const showCreateNewComplaint = () => {
        document.querySelector('.complaints-section-primary').classList.remove('hide');
        document.querySelector('.complaints-feedback').classList.add('hide');
    }

    const isChecked = (selector) => {
        return document.querySelector(selector).checked
    }

    const Quantity = ({index}) => {
        return (
            <div className="product-quantity">
                <div className="label">{t('complaints.article-header.quantity')}</div>
                <div className="quantity-select">
                    <div className="quantity quantity-decrease" onClick={() => decrease(index)}><IconMinus /></div>
                    <input
                        type="number"
                        className="field quantity-field"
                        {...register2(`products.${index}.edp_quantity`)}
                        defaultValue="0"
                    />
                    <div className="quantity quantity-increase" onClick={() => increase(index)}><IconPlus /></div>
                </div>
            </div>
        );
    };

    // decrease article quantity in complaint
    const decrease = (index) => {
        const ref = `products.${index}.edp_quantity`;
        const currentQuantity = parseInt(getValues2(ref));

        if (currentQuantity === 0) {
            return;
        }

        const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 0;

        setValue2(ref, newQuantity);
        verifyStoveBlock();
    };

    // increase article quantity in complaint
    const increase = (index) => {
        clearErrors2('products');
        const maxQuantity = articles[index].ShippedQty;
        const requireSerial = articles[index].Require_Serial === '1';
        const ref = `products.${index}.edp_quantity`;
        const currentQuantity = parseInt(getValues2(ref));

        if (currentQuantity === maxQuantity) {
            return;
        }

        // You can only add ONE of the article if it requires serial number
        if (requireSerial && currentQuantity === 1) {
            return;
        }

        const newQuantity = currentQuantity + 1;

        setValue2(ref, newQuantity);
        verifyStoveBlock();

    };

    const getFileInfo = (files) => { // FileList
        setFilesToSubmit(Array.from(files));
    };

    const clearFiles = () => {
        setFilesToSubmit([]);
        document.querySelector('.file-upload').value = '';
        clearErrors2('file');
    };

    return (
        <div className="main-content">
            {loadingPage &&
                <div className="page-is-loading">
                    <svg className="loading-spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                    </svg>
                </div>
            }
            <div className="content-primary complaints-feedback hide">
                <h1>{t('complaints.add-product.feedback.title')}</h1>
                <p>
                    {t('complaints.add-product.feedback.text')}
                    <Link to={`/complaint/${incident?.incidentid}`}>
                        {incident?.ed_casenumber ? incident?.ed_casenumber : incident?.ticketnumber }
                    </Link>
                </p>
                <p>{t('complaints.add-product.feedback.imageFeedback')}</p>
                <button type="button" className="button-secondary" onClick={showCreateNewComplaint}>{t('button.create-new-complaint')}</button>
            </div>

            <div className="content-primary complaints-section complaints-section-primary">
                <h1>{t('complaints.add-product.title')}</h1>
                <p>{t('complaints.add-product.text')}</p>
                <form key={1} className="form-complaints" onSubmit={handleSubmit(onSubmit)}>
                    <div className="field-item">
                        <div className={`field field-has-button ${errors.searchId ? 'field-wrap-error' : ''}`}>
                            <input
                                type="search"
                                className={`field field-search-input`}
                                defaultValue={searchId ? searchId.trim() : ''}
                                placeholder={`${t('form.field.search')} ${t('form.field.serialnumber')} / ${t('form.field.order-number')} *`}
                                {...register("searchId", {
                                    validate: {
                                        notEmpty: value => value.trim() !== '' || `${t('form.field.serialnumber')} / ${t('form.field.order-number')} ${t('form.is-required')}`,
                                        pattern: value => (value.length === 6 || value.length === 10 || value.length === 14) || t('complaints.search-format-error'),
                                        // exists: validateSearch
                                    }
                                })}
                            />
                            <button type="submit" className="button-secondary btn-validate-order-number"><span>{t('button.search')}</span></button>
                        </div>
                        <ErrorMessage errors={errors.searchId} />
                    </div>
                </form>
            </div>

            <div className="complaints-section complaints-section-secondary hide">
                <div className="content-primary">
                    <h1>{t('complaints.add-product.title')}</h1>
                    <h2>{t('form.field.order-number')} {orderNumber}</h2>
                </div>
                <div className="content-secondary">
                    <form key={2} onSubmit={handleSubmit2(onSubmit2)}>
                        <input
                            type="hidden"
                            {...register2("orderNumber")}
                        />

                        {articles.length
                            ?
                            <>
                                <p className="field-label">{t('complaints.label.order-new-articles')}</p>
                                <div className={`complaint-article-items ${errors2.products ? 'field-error' : ''}`} >
                                    <div className="complaint-article-item complaint-article-item-header">
                                        <span>{t('complaints.article-header.article-nr')}</span>
                                        <span>{t('complaints.article-header.article')}</span>
                                        <span>{t('complaints.article-header.quantity')}</span>
                                        <span>{t('complaints.article-header.order')}</span>
                                    </div>

                                    {articles.map((Line, index) => (
                                        <div key={index} className="complaint-article-item complaint-article-item-data">
                                            <input
                                                type="hidden"
                                                {...register2(`products.${index}.edp_articlenumber`)}
                                                {...setValue2(`products.${index}.edp_articlenumber`, Line.ItemNumber)}
                                            />
                                            <input
                                                type="hidden"
                                                {...register2(`products.${index}.Require_Serial`)}
                                                {...setValue2(`products.${index}.Require_Serial`, Line.Require_Serial === '1')}
                                            />
                                            <input
                                                type="hidden"
                                                {...register2(`products.${index}.edp_name`)}
                                                {...setValue2(`products.${index}.edp_name`, Line.Description)}
                                            />
                                            <span>{Line.ItemNumber}</span>
                                            <span>{Line.Description}</span>
                                            <span>{Line.ShippedQty}</span>
                                            <span>
                                                <Quantity index={index} />
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <ErrorMessage errors={errors2.products} />
                            </>
                            :
                            <p className="field-label">[ {t('complaints.no-articles-error')} ]</p>
                        }

                        <div className="verify-stove-block hide">
                            <p className="field-label">{t('complaints.label.stove-article-number')}</p>
                            <select
                                className={`field field-select-input${errors2.serialNumber ? ' field-error' : ''}`}
                                {...register2('serialNumber', {
                                    validate: {
                                        notEmpty: value => ((value.trim() !== '' && orderRequireSerial()) || !orderRequireSerial()) || `${t('form.field.serialnumber')} ${t('form.is-required')}`,
                                    }
                                })}
                            >
                                <option value="">{t('form.option.select-serial-number')}</option>
                                {articles.map((article) => {
                                    const serialNumbers = article.SerialNbrs.map((serialNumber) => {
                                        if (!serialNumber.SerialNumber) {
                                            return null;
                                        }

                                        return (
                                            <option key={serialNumber.SerialNumber} value={serialNumber.SerialNumber}>
                                                {`${serialNumber.SerialNumber} - ${serialNumber.ProductionNbr}`}
                                            </option>
                                        );
                                    });
                                    return serialNumbers;
                                })}
                            </select>
                            <ErrorMessage errors={errors2.serialNumber} />
                        </div>

                        <p className="field-label">{t('complaints.label.type-of-complaint')}</p>
                        <select
                            className={`field field-select-input${errors2.incidentType ? ' field-error' : ''}`}
                            {...register2("incidentType", {
                                validate: value => value !== '' || `${t('form.field.select-type')} ${t('form.is-required')}`
                            })}
                        >
                            <option value="">{t('form.option.select-type-of-error')}</option>
                            <option value={ process.env.REACT_APP_COMPLAINT_TRANSPORT_DAMAGE_ID }>{t('form.option.transport-damage')}</option>
                            <option value={ process.env.REACT_APP_COMPLAINT_DELIVERY_FAULT_ID }>{t('form.option.delivery-fault')}</option>
                            <option value={ process.env.REACT_APP_COMPLAINT_OTHER_FAULTS_ID }>{t('form.option.other-faults')}</option>
                        </select>
                        <ErrorMessage errors={errors2.incidentType} />

                        <textarea
                            className={`field field-textarea ${errors2.description ? 'field-error' : ''}`}
                            placeholder={`${t('form.field.error-description')} *`}
                            {...register2("description", { required: `${t('form.field.error-description')} ${t('form.is-required')}` })}
                            maxLength="480"
                        ></textarea>
                        <ErrorMessage errors={errors2.description} />
                        <p className="field-description">{t('complaints.label.stove-articles-complaint-description')}</p>

                        <div className="delivery-address">
                            <p className="field-label">{t('form.field.delivery-address')}</p>
                            <input type="text" className="field field-text" placeholder={t('form.field.name')} defaultValue={user.company} readOnly />
                            <input type="text" className="field field-text" placeholder={t('form.field.address')} defaultValue={user.address} readOnly />
                            <input type="text" className="field field-text" placeholder={t('form.field.zip')} defaultValue={user.zip} readOnly />
                            <input type="text" className="field field-text" placeholder={t('form.field.city')} defaultValue={user.city} readOnly />
                        </div>

                        <div className="field-item field-item-checkbox">
                            <input
                                type="checkbox"
                                id="alternative-delivery-address"
                                {...register2('alternateAddress', {
                                    onChange: (e) => {
                                        toggleHide(e.target);
                                    }
                                })}
                                data-target=".delivery-address"
                            />
                            <label htmlFor="alternative-delivery-address">{t('complaints.label.different-delivery-address')}</label>
                        </div>

                        <div className="delivery-address hide">
                            <p className="field-label">{t('complaints.label.contact-details')}</p>
                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.firstName ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.first-name')} *`}
                                {...register2("customAddress.firstName", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.first-name')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.firstName} />

                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.lastName ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.last-name')} *`}
                                {...register2("customAddress.lastName", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.last-name')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.lastName} />

                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.address ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.address')} *`}
                                {...register2("customAddress.address", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.address')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.address} />

                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.postalCode ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.zip')} *`}
                                {...register2("customAddress.postalCode", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.zip')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.postalCode} />

                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.city ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.city')} *`}
                                {...register2("customAddress.city", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.city')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.city} />

                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.country ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.country')} *`}
                                {...register2("customAddress.country", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.country')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.country} />

                            <input
                                type="email"
                                className={`field field-text ${errors2?.customAddress?.emailAddress ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.email')} *`}
                                {...register2("customAddress.emailAddress", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.phone')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.emailAddress} />


                            <input
                                type="text"
                                className={`field field-text ${errors2?.customAddress?.mobilePhone ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.phone')} *`}
                                {...register2("customAddress.mobilePhone", {
                                    validate: value => ((value.trim() !== '' && isChecked('#alternative-delivery-address')) || !isChecked('#alternative-delivery-address')) || `${t('form.field.phone')} ${t('form.is-required')}`
                                })}
                            />
                            <ErrorMessage errors={errors2?.customAddress?.mobilePhone} />

                        </div>

                        <div>
                            <p className="field-label">{t('complaints.label.order-mark')}</p>
                            <input
                                type="text"
                                className={`field field-text ${errors2?.orderMark ? 'field-error' : ''}`}
                                placeholder={`${t('form.field.mark')} *`}
                                {...register2('orderMark', { required: `${t('form.field.mark')} ${t('form.is-required')}` })}
                            />
                            <ErrorMessage errors={errors2?.orderMark} />
                        </div>

                        <div>
                            <p className="field-label">{t('complaints.label.complementary-images-and-files')}</p>
                            <div className={`field-file${errors2?.file ? ' field-error' : ''}`}>
                                <span className="field-file-placeholder">
                                    {filesToSubmit?.length ?
                                        <ol>
                                            {filesToSubmit.map((file, index) => (
                                                <li key={index}>
                                                    {file.name}
                                                </li>
                                            ))}
                                        </ol>
                                        : null}
                                </span>
                                <div className="field-file-buttons">
                                    { filesToSubmit?.length ?
                                        <span className="field-file-icon field-file-icon-clear"><IconClear onClick={clearFiles} /></span>
                                        : null}
                                    <div className={`field-file-button ${filesToSubmit?.length ? ' field-file-button-hidden' : ''}`}>
                                        <input
                                            className={`file-upload field ${errors2.file ? 'field-error' : ''}`}
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            {...register2('file', {
                                                onChange: (e) => getFileInfo(e.target.files),
                                                validate: {
                                                    lessThan4MB: files => {
                                                        let allowed = true;
                                                        for (let i = 0; i < files.length; i++) {
                                                            if (files[i].size > 4000000) {
                                                                allowed = false;
                                                            }
                                                        }
                                                        return allowed || t('form.file-error.file-size');
                                                    },
                                                    lessThan5Files: files => {
                                                        return files.length < 5 || t('form.file-error.file-amount', { maxFiles: 4 });
                                                    },
                                                    allowedTypes: files => {
                                                        let allowed = true;
                                                        for (let i = 0; i < files.length; i++) {
                                                            if (!files[i].type.match('image.*')) {
                                                                allowed = false;
                                                            }
                                                        }
                                                        return allowed || t('form.file-error.file-type');
                                                    },
                                                }
                                            })}
                                            name="file"
                                        />
                                    </div>
                                </div>
                            </div>
                            <ErrorMessage errors={errors2.file} />
                        </div>

                        <div className="buttongroup">
                            <button type="button" className="button-cancel button-tertiary" onClick={toggleComplaintsSection}>{t('button.cancel')}</button>
                            <button type="submit" className="button-submit button-secondary">{t('button.submit')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}