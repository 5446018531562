import React from 'react';
import TextBox from './ScreenOptions/TextBox';
import NumericTextBox from './ScreenOptions/NumericTextBox';
import TextArea from './ScreenOptions/TextArea';
import CheckBox from './ScreenOptions/CheckBox';
import DropDown from './ScreenOptions/DropDown';
import ImageAndTextHorizontal from './ScreenOptions/ImageAndTextHorizontal';
import RadioButtonHorizontal from './ScreenOptions/RadioButtonHorizontal';
import ImageHorizontal from './ScreenOptions/ImageHorizontal';
import { stripHtml } from '../../components/Utils/strings';
import { ReactComponent as InfoIcon } from '../../../images/icons/info.svg'

function ScreenOption({screenOption, onChange, onContinue, onInfo}) {
    const buildOption = (screenOption) => {
        switch (screenOption.DisplayType) {
            case 'TextBox':
                return (
                    <TextBox
                        id={screenOption.ID}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'DropDown':
                return (
                    <DropDown
                        id={screenOption.ID}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        options={screenOption.SelectableValues}
                        isRequired={screenOption.IsRequired}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'TypeableDropDown':
                return (
                    <DropDown
                        id={screenOption.ID}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        options={screenOption.SelectableValues}
                        isRequired={screenOption.IsRequired}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'TextArea':
                return (
                    <TextArea
                        id={screenOption.ID}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'CheckBox':
                return (
                    <CheckBox
                        id={screenOption.ID}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'NumericTextBox':
                return (
                    <NumericTextBox
                        id={screenOption.ID}
                        value={typeof screenOption.Value === 'string' ? screenOption.Value?.replace(/\s/g, '') : screenOption.Value }
                        max={screenOption.Ranges?.[0] && screenOption.Ranges[0].Max}
                        min={screenOption.Ranges?.[0] && screenOption.Ranges[0].Min}
                        step={screenOption.Ranges?.[0] && screenOption.Ranges[0].Increment}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'NumericControl':
                try {
                    return (
                        <NumericTextBox
                            id={screenOption.ID}
                            value={typeof screenOption.Value === 'string' ? screenOption.Value?.replace(/\s/g, '') : screenOption.Value }
                            max={screenOption.Ranges?.[0] && screenOption.Ranges[0].Max}
                            min={screenOption.Ranges?.[0] && screenOption.Ranges[0].Min}
                            step={screenOption.Ranges?.[0] && screenOption.Ranges[0].Increment}
                            onChange={onChange}
                            onContinue={onContinue}
                            isEnabled={screenOption.IsEnabled}
                        />
                    );
                } catch (error) {
                    console.error('Error in NumericControl', error);
                    console.log('screenOption', screenOption);
                }
                break;
            case 'ImageAndTextHorizontal':
                return (
                    <ImageAndTextHorizontal
                        id={screenOption.ID}
                        selectableValues={screenOption.SelectableValues}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'ImageAndTextWrap':
                return (
                    <ImageAndTextHorizontal
                        id={screenOption.ID}
                        selectableValues={screenOption.SelectableValues}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'ImageHorizontal':
                return (
                    <ImageHorizontal
                        id={screenOption.ID}
                        selectableValues={screenOption.SelectableValues}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            case 'RadioButtonHorizontal':
                return (
                    <RadioButtonHorizontal
                        id={screenOption.ID}
                        selectableValues={screenOption.SelectableValues}
                        value={screenOption.Value}
                        onChange={onChange}
                        onContinue={onContinue}
                        isEnabled={screenOption.IsEnabled}
                    />
                );
            default:
                break;
        }
    };

    const handleInfoClick = (e) => {
        onInfo(screenOption.InformationLink);
    };

    if (screenOption.IsVisible) {
        return (
            <div className="cpq-option-row">
                <div className="cpq-option-caption">
                    <span className={`cpq-option-caption-text${screenOption.IsRequired ? ' cpq-option-caption-text-required' : ''}`}>{stripHtml(screenOption.Caption)}</span>
                    {screenOption.InformationLink ? <button className="cpq-option-info" onClick={handleInfoClick}><InfoIcon /></button>: null}
                </div>
                {buildOption(screenOption)}
            </div>
        );
    } else {
        return null;
    }
}

export default ScreenOption;
