import React from 'react';
import { ReactComponent as ConturaLogo } from '../../../images/contura-logo.svg';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    return (
        <div className="page-content page-content-login">
            <div className="main-container">
                <main>
                    <div className="main-content">
                        <div className="login-logo">
                            <ConturaLogo />
                        </div>
                        <h1>{t("login.welcome-title")}</h1>
                        <p>
                            {t("login.intro")}
                        </p>

                        <button
                            onClick={() => instance.loginRedirect(loginRequest)}
                            className="button button-primary"
                        >
                            {t('button.log-in')}
                        </button>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Login;