/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React from 'react';
import { ReactComponent as IconLiveDisplay } from '../../../images/icons/flame.svg'
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import useAxios from 'scripts/hooks/useAxios';

export default function ProductList(props) {
    const { callApi } = useAxios();
    const { t } = useTranslation()

    const toggleIsLiveDisplay = (self, id) => {
        let
            listProductItem = self.closest('.list-product-item'),
            isLiveDisplay = self.value;

        listProductItem.classList.toggle('list-product-item-is-live-display')

        callApi({
            method: 'get',
            url: `/api/product/updatecombustible?showroomId=${id}&combustible=${isLiveDisplay}`
        }).then((res) => {
            //console.dir(res);
        }).catch((err) => console.error(err));
    }

    const toggleConfirm = (self) => {
        self.closest('.buttonset-confirm').classList.toggle('buttonset-confirm-show-confirm')
    }

    const addItem = (self, id, index) => {
        let
            isLiveDisplay = document.querySelector(`input[name='is-live-display-${index}']:checked`).value,
            item = self.closest('.list-product-item');

        item.classList.add('list-product-item-loading');
        callApi({
            method: 'get',
            url: `/api/product/addProductToStore?productId=${id}&combustible=${isLiveDisplay}`
        }).then((res) => {
            item.classList.add('list-product-item-in-showroom');
            item.dataset.title = t('my-pages.showroom.product-in-showroom');
            item.classList.remove('list-product-item-loading-delete');
        }).catch((err) => console.error(err));
    }

    const deleteItem = (self, id) => {
        let item = self.closest('.list-product-item');

        item.classList.add('list-product-item-loading');
        callApi({
            method: 'get',
            url: `/api/product/deleteProductFromStore?showroomId=${id}`
        }).then(res => {
            item.parentNode.removeChild(item);
            item.classList.remove('list-product-item-loading-delete');
        }).catch(err => console.error(err));
    }

    const ListItems = (props) => {
        let add = props.add || false;

        return props.items.map((item, index) =>
            <div
                key={index}
                className={`list-product-item ${item.isLiveDisplay ? 'list-product-item-is-live-display' : ''}${add ? '' : ' list-product-item-in-showroom'} `}
            >
                <div className="list-product-item-image">
                    {
                        item.img ? (
                            <img src={item.img} alt="" />
                        ) : (
                            <img src="/images/no-image-available.jpg" alt="" />
                        )
                    }
                </div>
                <div className="list-product-item-content">
                    <h3 className="list-product-item-title">{item.title ? item.title : 'no-title'}</h3>
                    <p className="list-product-item-subtitle">{t('my-pages.showroom.product-is-live-display')}</p>
                    <div className="list-product-item-actions">
                        <div className="list-product-item-select-live-display">
                            <input
                                className="field-checkbox field-is-live-display-yes"
                                type="radio"
                                id={`is-live-display-yes-${index}`}
                                name={`is-live-display-${index}`}
                                defaultChecked={item.isLiveDisplay}
                                onChange={(event) => toggleIsLiveDisplay(event.target, item.id)}
                                value="true"
                            />
                            <label htmlFor={`is-live-display-yes-${index}`}>{t('button.yes')}</label>

                            <input
                                className="field-checkbox field-is-live-display-no"
                                type="radio"
                                id={`is-live-display-no-${index}`}
                                name={`is-live-display-${index}`}
                                defaultChecked={!item.isLiveDisplay}
                                onChange={(event) => toggleIsLiveDisplay(event.target, item.id)}
                                value="false"
                            />
                            <label htmlFor={`is-live-display-no-${index}`}>{t('button.no')}</label>
                        </div>

                        <button
                            type="button"
                            className="button-sm button-secondary button-add"
                            onClick={(event) => addItem(event.target, item.id, index)}
                        >
                            <span>{t('button.add')}</span>
                        </button>

                        <div className="buttonset-confirm">
                            <button
                                type="button"
                                className="button-sm button-secondary"
                                onClick={(event) => toggleConfirm(event.target)}
                            >
                                {t('button.delete')}
                            </button>
                            <div className="buttonset">
                                <button
                                    type="button"
                                    className="button-sm button-tertiary"
                                    onClick={(event) => toggleConfirm(event.target)}
                                >
                                    {t('button.cancel')}
                                </button>
                                <button
                                    type="button"
                                    className="button-sm button-delete"
                                    onClick={(event) => deleteItem(event.target, item.id)}
                                >
                                    <span>{t('button.delete')}</span>
                                </button>
                            </div>
                        </div>

                        <div className="icon-live-display"><IconLiveDisplay /></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="list-product">
            <div className={`list-product-items${props.add ? ' list-product-items-add' : ''}`}>
                {ListItems(props)}
            </div>
        </div>
    );
}