import React from 'react';

export default function ErrorMessage(props) {
    return (
        <>
            {props.errors &&
                <span className="label-error">{props.errors.message}</span>
            }
        </>
    );
}