import React from 'react';

function Video(props) {
    let videoItem = (item, index) => {
        return (
            <div className="list-video-item" key={index}>
                <video
                    className="list-video-preview"
                    src={item.url}
                    poster={item.thumbnail}
                    controls
                    muted
                    preload="none"></video>

                <div className="list-video-content">
                    <h3 className="list-video-title">{item.name}</h3>
                    <p className="list-video-meta">Format: {item.mimeType}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="list-video">
            <div className="list-video-items">
                {props.items.map((item, index) => {
                    return videoItem(item, index);
                })}
            </div>
        </div>
    );
}

export default Video;