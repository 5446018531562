import React from 'react';
import '../../../../i18n';
import { useTranslation } from 'react-i18next';


function NewsArchive(props) {

    const { t } = useTranslation();
    function listItems(items) {
        return items.map((item, index) => (
            <div key={index} className="list-newsarchive-item">
                <h4
                    className="list-newsarchive-title"
                    onClick={() =>
                        props.filter({ type: 'year', year: item.year })
                    }>
                    {item.year}
                </h4>
                <div className="list-newsarchive-links">
                    {item.items.map((newsItem, index) => (
                        <p
                            onClick={() =>
                                props.filter({
                                    type: 'month',
                                    year: item.year,
                                    month: newsItem.month,
                                })
                            }
                            key={index}
                            to={newsItem.to}
                            className="list-newsarchive-link">
                            {numToMonth(newsItem.month)}
                        </p>
                    ))}
                </div>
            </div>
        ));
    }

    var numToMonth = (monthAsNum) => {
        let month;
        switch (monthAsNum) {
            case '01':
                month = t('news.months.Jan');
                break;
            case '02':
                month = t('news.months.Feb');
                break;
            case '03':
                month = t('news.months.Mars');
                break;
            case '04':
                month = t('news.months.Apr');
                break;
            case '05':
                month = t('news.months.May');
                break;
            case '06':
                month = t('news.months.June');
                break;
            case '07':
                month = t('news.months.July');
                break;
            case '08':
                month = t('news.months.Aug');
                break;
            case '09':
                month = t('news.months.Sept');
                break;
            case '10':
                month = t('news.months.Oct');
                break;
            case '11':
                month = t('news.months.Nov');
                break;
            case '12':
                month = t('news.months.Dec');
                break;
            default:
                month = ' ';
        }
        return month;
    };

    return (
        <div className="list-newsarchive">
            <div className="list-newsarchive-items">
                {listItems(props.items)}
            </div>
        </div>
    );
}

export default NewsArchive;