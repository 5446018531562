import React from 'react';
import { Link } from "react-router-dom";

const BreadcrumbsItem = ({item, firstChild, lastChild}) => {

    return (
        <span className={`breadcrumbs-item${ firstChild ? ' breadcrumbs-item-back' : ''}`}>
            { lastChild ? (
                <span className="breadcrumbs-item-text">{item.label}</span>
            ) : (
                <Link className="breadcrumbs-item-text breadcrumbs-item-link" to={item.id}>
                    {item.label}
                </Link>
            )}
        </span>
    )
}

export default BreadcrumbsItem;