/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect } from 'react';
import ChartistGraph from 'react-chartist'; // https://gionkunz.github.io/chartist-js/
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { TextLoader, ChartLoader } from '../../components/ContentLoader';
import ErrorInfo from '../Messages';

const SalesFigures = () => {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    const [totalRev, setRev] = useState(null);
    const [revContura, setRevContura] = useState(null);
    const [revPremodul, setRevPremodul] = useState(null);
    const [units, setUnits] = useState(null);
    const [chart, setChart] = useState(null);
    const { accounts } = useMsal();
    const [errorRevenueYTD, setErrorRevenueYTD] = useState(false);
    const [errorRevContura, setErrorRevContura] = useState(false);
    const [errorRevPremodul, setErrorRevPremodul] = useState(false);
    const
        accountInfo = useAccount(accounts[0] || {}),
        market = accountInfo?.idTokenClaims.extension_market?.toLowerCase() || 'se', // eslint-disable-line camelcase
        showPremodul = market.includes('se') || market.includes('no') || market.includes('fi');

    useEffect(() => {
        if (
            !chart &&
            !totalRev &&
            !units &&
            inProgress === InteractionStatus.None
        ) {
            callApi({
                method: 'get',
                url: '/api/financial/totalRevenueYTD',
            }).then((res) => {
                if (res.status === 200) {
                    let {
                        AmountsYTD: {
                            AmountYTD,
                            CompareYTD,
                            NumberOfUnitsYTD,
                            NumberOfUnitsCompare,
                        },
                        Currency,
                        AmountsMTD,
                    } = res.data;
                    let compareRev =
                        CompareYTD > 0 ? `+${CompareYTD}%` : `${CompareYTD}%`;
                    let compareUnits =
                        NumberOfUnitsCompare > 0
                            ? `+${NumberOfUnitsCompare}%`
                            : `${NumberOfUnitsCompare}%`;
                    setRev({
                        amount: Math.round(AmountYTD).toLocaleString(),
                        currency: Currency,
                        compare: compareRev,
                    });
                    setUnits({
                        amount: NumberOfUnitsYTD.toLocaleString(),
                        compare: compareUnits,
                    });
                    let currentYear = [];
                    let previousYear = [];
                    AmountsMTD.forEach(({ AmountMTD, PreviousMTD }) => {
                        currentYear.push(AmountMTD);
                        previousYear.push(PreviousMTD);
                    });

                    setChart({
                        data: {
                            labels: [
                                t('sales-figures.months.Jan'),
                                t('sales-figures.months.Feb'),
                                t('sales-figures.months.Mar'),
                                t('sales-figures.months.Apr'),
                                t('sales-figures.months.May'),
                                t('sales-figures.months.June'),
                                t('sales-figures.months.July'),
                                t('sales-figures.months.Aug'),
                                t('sales-figures.months.Sept'),
                                t('sales-figures.months.Oct'),
                                t('sales-figures.months.Nov'),
                                t('sales-figures.months.Dec'),
                            ],
                            series: [currentYear, previousYear],
                        },
                        options: {
                            seriesBarDistance: 15,
                        },
                        type: 'Bar',
                    });
                }
            }).catch((err) => {
                setErrorRevenueYTD(true)
                console.error(err);
            });
        }
    }, [chart, totalRev, units, inProgress, callApi, t]);

    useEffect(() => {
        if (!revContura && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/financial/totalRevenueByBrand?brand=CONTU',
            }).then((res) => {
                if (res.status === 200) {
                    let { AmountYTD, CompareYTD, Currency } = res.data;
                    let compare =
                        CompareYTD > 0 ? `+${CompareYTD}%` : `${CompareYTD}%`;
                    setRevContura({
                        amount: Math.round(AmountYTD).toLocaleString(),
                        currency: Currency,
                        compare,
                    });
                }
            }).catch((err) => {
                setErrorRevContura(true)
                console.error(err);
            });
        }
    }, [revContura, inProgress, callApi]);

    useEffect(() => {
        if (!revPremodul && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/financial/totalRevenueByBrand?brand=PREM',
            }).then((res) => {
                if (res.status === 200) {
                    let { AmountYTD, CompareYTD, Currency } = res.data;
                    let compare =
                        CompareYTD > 0 ? `+${CompareYTD}%` : `${CompareYTD}%`;
                    setRevPremodul({
                        amount: Math.round(AmountYTD).toLocaleString(),
                        currency: Currency,
                        compare,
                    });
                }
            }).catch((err) => {
                setErrorRevPremodul(true)
                console.error(err);
            });
        }
    }, [revPremodul, inProgress, callApi]);

    return (
        <div className="sales-figures">
            <div className="sales-numbers">
                <div className="sales-items">
                    <div className="sales-item sales-total">
                        <h2>{t('sales-figures.total-revenue-title')}</h2>
                        {totalRev &&
                            <>
                                <div className="sales-price">
                                    <span className="value">
                                        {totalRev.amount}
                                    </span>
                                    <span className="unit">
                                        {totalRev.currency}
                                    </span>
                                </div>
                                <p>
                                    {t(
                                        'sales-figures.compared-to-previous-year',
                                        {
                                            percent: totalRev.compare,
                                        }
                                    )}
                                </p>
                            </>
                        }
                        {!totalRev && !errorRevenueYTD &&
                            <TextLoader style={{width: '100%', maxWidth: '400px'}} />
                        }
                        {errorRevenueYTD &&
                            <ErrorInfo text={t('error.title.sales-figures')} />
                        }
                    </div>
                    <div className="sales-item sales-units">
                        <h2>{t('sales-figures.number-of-units-title')}</h2>
                        {units &&
                            <>
                                <div className="sales-price">
                                    <span className="value">
                                        {units.amount}
                                    </span>
                                    <span className="unit">{t('sales-figures.pieces')}</span>
                                </div>
                                <p>
                                    {t(
                                        'sales-figures.compared-to-previous-year',
                                        {
                                            percent: units.compare,
                                        }
                                    )}
                                </p>
                                <p className="sales-footnote">
                                    {t('sales-figures.number-of-units-footnote')}
                                </p>
                            </>
                        }
                        {!units && !errorRevenueYTD &&
                            <TextLoader style={{width: '100%', maxWidth: '400px'}} />
                        }
                        {errorRevenueYTD &&
                            <ErrorInfo text={t('error.title.sales-figures')} />
                        }
                    </div>
                    { showPremodul &&
                        <>
                            <div className="sales-item sales-contura">
                                <h2>{t('sales-figures.contura-revenue-title')}</h2>
                                {revContura &&
                                    <>
                                        <div className="sales-price">
                                            <span className="value">
                                                {revContura.amount}
                                            </span>
                                            <span className="unit">
                                                {revContura.currency}
                                            </span>
                                        </div>
                                        <p>
                                            {t(
                                                'sales-figures.compared-to-previous-year',
                                                {
                                                    percent: revContura.compare,
                                                }
                                            )}
                                        </p>
                                        <p className="sales-footnote">
                                            {t('sales-figures.contura-revenue-footnote')}
                                        </p>
                                    </>
                                }
                                {!revContura && !errorRevContura &&
                                    <TextLoader style={{width: '100%', maxWidth: '400px'}} />
                                }
                                {errorRevContura &&
                                    <ErrorInfo text={t('error.title.sales-figures')} />
                                }
                            </div>
                            <div className="sales-item sales-premodul">
                                <h2>{t('sales-figures.premodul-revenue-title')}</h2>
                                {revPremodul &&
                                    <>
                                        <div className="sales-price">
                                            <span className="value">
                                                {revPremodul.amount}
                                            </span>
                                            <span className="unit">
                                                {revPremodul.currency}
                                            </span>
                                        </div>
                                        <p>
                                            {t(
                                                'sales-figures.compared-to-previous-year',
                                                {
                                                    percent: revPremodul.compare,
                                                }
                                            )}
                                        </p>
                                    </>
                                }
                                {!revPremodul && !errorRevPremodul &&
                                    <TextLoader style={{width: '100%', maxWidth: '400px'}} />
                                }
                                {errorRevPremodul &&
                                    <ErrorInfo text={t('error.title.sales-figures')} />
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="sales-chart">
                <h2>{t('sales-figures.sales-chart-title')}</h2>
                {chart &&
                    <>
                        <ChartistGraph
                            className={'ct-minor-seventh'}
                            data={chart.data}
                            options={chart.options}
                            type={chart.type}
                        />
                        <div className="sales-chart-footer">
                            <span className="chart-year chart-year-current">
                                <span className="chart-color"></span>{' '}
                                {new Date().getFullYear()}
                            </span>
                            <span className="chart-year chart-year-previous">
                                <span className="chart-color"></span>{' '}
                                {new Date().getFullYear() - 1}
                            </span>
                        </div>
                    </>
                }
                {!chart && !errorRevenueYTD &&
                    <ChartLoader />
                }
                {errorRevenueYTD &&
                    <ErrorInfo text={t('error.title.sales-figures')} />
                }
            </div>
        </div>
    );
};

export default SalesFigures;