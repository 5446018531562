import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryList from '../CategoryList';
import { useTranslation } from 'react-i18next';
import ErrorInfo from '../Messages';

const DeliveryLists = () => {
    const [deliveryLists, setDeliveryLists] = useState([]);
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
            url: '/rest-api/mediaApi/files',
            params: {
                market: 'sv-SE',
                contentType: 'dts'
            }
        }).then((res) => {
            setDeliveryLists(res.data);
        }).catch((error) => {
            setError(true)
            console.error(error); // eslint-disable-line
        });
    }, []);

    return (
        <>
            {deliveryLists.length > 0  &&
                <div className="delivery-lists">
                    <CategoryList items={deliveryLists} />
                </div>
            }
            {error &&
                <ErrorInfo text={t('error.title.delivery-lists')} />
            }
        </>
    );
}

export default DeliveryLists;