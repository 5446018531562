import React from 'react';

class NewsList extends React.Component {
    listItems(items) {
        return items.map((item, index) => {
            return (
                <a
                    key={index}
                    href={'/news/' + item.id}
                    className="list-news-item"
                    rel="noopener noreferrer">
                    <h3 className="list-news-title">{item.title}</h3>
                    <time className="list-news-date" dateTime={item.date}></time>
                    {item.date && this.transformPublishDate(item.date)}

                    <div className="list-news-excerpt">{item.abstract}</div>
                </a>
            );
        });
    }

    transformPublishDate = (date) => {
        return date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    };

    render() {
        return (
            <div className="list-news">
                <div className="list-news-items">
                    {this.props.items.length > 0 && this.listItems(this.props.items)}
                </div>
            </div>
        );
    }
}

export default NewsList;