import React from 'react';
import { ReactComponent as ConturaLogo } from '../../../images/contura-logo.svg'
import ToggleMobileMenu from '../../components/Header/toggle-mobile-menu';
import ToggleShoppingCart from '../../components/Header/toggle-shopping-cart';
import User from '../../components/User/';

export default class Header extends React.Component {
    render() {
        return (
            <header className="site-header">
                <div className="site-header-left">
                    <div className="site-header-mobile">
                        <ToggleMobileMenu />
                        <a href="/" className="header-logo">
                            <ConturaLogo />
                        </a>
                    </div>
                </div>
                <div className="site-header-right">
                    <User />
                    <ToggleShoppingCart cart={this.state}  />
                </div>
            </header>
        );
    }
}