import React from "react"
import ContentLoader from "react-content-loader"

const bgColor = "#eee"
const fgColor = "#fff"

export const ProductLoader = (props) => (
    <ContentLoader
        backgroundColor={fgColor}
        foregroundColor={bgColor}
        speed={3}
        style={{ width: '100%', maxWidth: '720px'}}
        viewBox="0 0 720 470"
    >
        <rect x="0" y="0" rx="3" ry="3" width="720" height="30" />
        <rect x="0" y="50" rx="3" ry="3" width="720" height="420" />

    </ContentLoader>
)

export const ProductListLoader = (props) => (
    <ContentLoader
        backgroundColor={fgColor}
        foregroundColor={bgColor}
        speed={2}
        style={{ width: '100%', maxWidth: '1400px'}}
        viewBox="0 0 780 510"
    >
        <rect x="0" y="0" rx="3" ry="3" width="180" height="220" />
        <rect x="200" y="0" rx="3" ry="3" width="180" height="220" />
        <rect x="400" y="0" rx="3" ry="3" width="180" height="220" />
        <rect x="600" y="0" rx="3" ry="3" width="180" height="220" />

        <rect x="0" y="240" rx="3" ry="3" width="180" height="220" />
        <rect x="200" y="240" rx="3" ry="3" width="180" height="220" />
        <rect x="400" y="240" rx="3" ry="3" width="180" height="220" />
        <rect x="600" y="240" rx="3" ry="3" width="180" height="220" />
    </ContentLoader>
)

export const ProductListWithBreadcrumbsLoader = (props) => (
    <ContentLoader
        backgroundColor={fgColor}
        foregroundColor={bgColor}
        speed={2}
        style={{ width: '100%', maxWidth: '1400px'}}
        viewBox="0 0 780 510"
    >
        <rect x="0" y="0" rx="3" ry="3" width="780" height="20" />

        <rect x="0" y="40" rx="3" ry="3" width="180" height="220" />
        <rect x="200" y="40" rx="3" ry="3" width="180" height="220" />
        <rect x="400" y="40" rx="3" ry="3" width="180" height="220" />
        <rect x="600" y="40" rx="3" ry="3" width="180" height="220" />

        <rect x="0" y="280" rx="3" ry="3" width="180" height="220" />
        <rect x="200" y="280" rx="3" ry="3" width="180" height="220" />
        <rect x="400" y="280" rx="3" ry="3" width="180" height="220" />
        <rect x="600" y="280" rx="3" ry="3" width="180" height="220" />
    </ContentLoader>
)

export const CpqLoader = (props) => (
    <ContentLoader
        backgroundColor="#e4e4e4"
        foregroundColor="#f6f6f6"
        speed={2}
        style={{ width: '100%' }}
        viewBox="0 0 800 400"
    >
        <rect x="30" y="30" rx="3" ry="3" width="500" height="15" />
        <rect x="30" y="60" rx="3" ry="3" width="500" height="15" />
        <rect x="30" y="90" rx="3" ry="3" width="500" height="15" />

        <rect x="30" y="140" rx="3" ry="3" width="500" height="15" />
        <rect x="30" y="170" rx="3" ry="3" width="500" height="15" />
        <rect x="30" y="200" rx="3" ry="3" width="500" height="15" />

        <rect x="560" y="30" rx="3" ry="3" width="210" height="400" />
    </ContentLoader>
)

export const CpqScreenLoader = (props) => (
    <ContentLoader
        backgroundColor="#e4e4e4"
        foregroundColor="#f6f6f6"
        speed={2}
        style={{ width: '100%' }}
        viewBox="0 0 800 100"
    >
        <rect x="0" y="30" rx="3" ry="3" width="420" height="15" />
        <rect x="0" y="60" rx="3" ry="3" width="483" height="15" />
    </ContentLoader>
)

export const TableLoader = (props) => (
    <ContentLoader
        backgroundColor={fgColor}
        foregroundColor={bgColor}
        speed={1}
        style={{ width: '100%' }}
        viewBox="0 0 1460 375"
    >
        <rect x="0" y="0" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="75" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="150" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="225" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="300" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="2375" rx="3" ry="3" width="1460" height="70" />
    </ContentLoader>
)

export const TableWithSearchLoader = (props) => (
    <ContentLoader
        backgroundColor={fgColor}
        foregroundColor={bgColor}
        speed={1}
        style={{ width: '100%' }}
        viewBox="0 0 1460 475"
    >
        <rect x="430" y="20" rx="30" ry="30" width="600" height="60" />

        <rect x="0" y="100" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="175" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="250" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="325" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="400" rx="3" ry="3" width="1460" height="70" />
        <rect x="0" y="2375" rx="3" ry="3" width="1460" height="70" />
    </ContentLoader>
)

export const TextLoader = (props) => (
    <ContentLoader
        backgroundColor={bgColor}
        foregroundColor={fgColor}
        speed={2}
        viewBox="0 0 400 100"
        {...props}
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="20" />
        <rect x="0" y="40" rx="3" ry="3" width="400" height="20" />
        <rect x="0" y="80" rx="3" ry="3" width="400" height="20" />
    </ContentLoader>
)

export const ChartLoader = (props) => (
    <ContentLoader
        backgroundColor= {bgColor}
        foregroundColor={fgColor}
        style={{ width: '100%' }}
        viewBox="0 0 690 400"
        {...props}
    >
        <rect x="60" y="300" rx="0" ry="0" width="15" height="100" />
        <rect x="100" y="200" rx="0" ry="0" width="15" height="200" />
        <rect x="140" y="100" rx="0" ry="0" width="15" height="300" />
        <rect x="180" y="0" rx="0" ry="0" width="15" height="400" />
        <rect x="220" y="25" rx="0" ry="0" width="15" height="375" />
        <rect x="260" y="50" rx="0" ry="0" width="15" height="350" />
        <rect x="300" y="200" rx="0" ry="0" width="15" height="200" />
        <rect x="340" y="120" rx="0" ry="0" width="15" height="280" />
        <rect x="380" y="220" rx="0" ry="0" width="15" height="180" />
        <rect x="420" y="50" rx="0" ry="0" width="15" height="350" />
        <rect x="460" y="75" rx="0" ry="0" width="15" height="325" />
        <rect x="500" y="150" rx="0" ry="0" width="15" height="250" />
    </ContentLoader>
)
export const LargeFormLoader = (props) => (
    <ContentLoader
        backgroundColor={bgColor}
        foregroundColor={fgColor}
        style={{ width: '100%' }}
        speed={2}
        viewBox="0 0 690 600"
        {...props}>
        <rect x="100" y="50" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="105" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="160" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="205" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="270" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="310" rx="3" ry="3" width="20" height="20" />

        <rect x="100" y="355" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="410" rx="3" ry="3" width="500" height="30" />
        <rect x="100" y="450" rx="3" ry="3" width="20" height="20" />
    </ContentLoader>
);