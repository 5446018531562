
import { LogLevel } from '@azure/msal-browser';

const tenant = process.env.REACT_APP_B2C_TENANT;
const tenantSubdomain = tenant.split('.')[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInPolicy = process.env.REACT_APP_B2C_SIGN_IN_POLICY;
const clientId = process.env.REACT_APP_B2C_CLIENT_ID;


export const b2cPolicies = {
    names: {
        signIn: signInPolicy,
    },
    authorities: {
        signIn: {
            authority: `${instance}${tenant}/${signInPolicy}`,
        },
    },
    authorityDomain: `${tenantSubdomain}.b2clogin.com`,
};

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:	
                        console.log(message);
                        return;	
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ['https://nibeb2c.onmicrosoft.com/ce60fab0-f530-4bcc-893a-317bdc775e38/Portal.Access']
};

export const protectedResources = {
    api: {
        scopes: ['https://nibeb2c.onmicrosoft.com/ce60fab0-f530-4bcc-893a-317bdc775e38/Portal.Access']
    }
};