import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CategoryList from 'scripts/components/CategoryList';
import { TableLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';
import { useTranslation } from 'react-i18next';

export default function MediaManuals() {
    const [manuals, setManuals] = useState(null);
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        if (!manuals) {
            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_CONTURA_BASE_URL,
                url: '/rest-api/mediaApi/files',
                params: {
                    market: 'sv-SE',
                    contentType: 'iav',
                }
            }).then((res) => {
                setManuals(res.data[0]);
            }).catch((error) => {
                setError(true)
                alertLoadingError(t)
                console.error(error); // eslint-disable-line
            })
        }
    }, [manuals, t]);

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('media.manuals.title')}</h1>
                <p></p>
            </div>
            {manuals && manuals.items.length > 0 &&
                <div className="content-secondary">
                    <CategoryList items={manuals.items} />
                </div>
            }
            {!manuals && !error &&
                <TableLoader />
            }
        </div>
    );
}