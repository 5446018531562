import React from 'react';
import '../../../../i18n';
import { useTranslation } from 'react-i18next';


export default function AdressFields(props) {
    const { t } = useTranslation();

    return (
        <div className="">
            <label className="label" htmlFor="resellerName">
            {t('checkout.forms.name')}
            </label>
            <input
                className="field field-text"
                name="resellerName"
                id="resellerName"
                value={props.reseller.Name}
                readOnly
            />

            <label className="label" htmlFor="resellerAddress1">
            {t('checkout.forms.address')}
            </label>
            <input
                className="field field-text"
                name="resellerAddress1"
                id="resellerAddress1"
                value={props.reseller.Address1}
                readOnly
            />

            <div className="row">
                <div className="column">
                    <label className="label" htmlFor="resellerCity">
                    {t('checkout.forms.city')}
                    </label>
                    <input
                        className="field field-text"
                        name="resellerCity"
                        id="resellerCity"
                        value={props.reseller.City}
                        readOnly
                    />
                </div>
                <div className="column">
                    <label className="label" htmlFor="resellerPostalCode">
                    {t('checkout.forms.postal')}
                    </label>
                    <input
                        className="field field-text"
                        name="resellerPostalCode"
                        id="resellerPostalCode"
                        value={props.reseller.PostalCode}
                        readOnly
                    />
                </div>
            </div>

            <label className="label" htmlFor="resellerCountry">
            {t('checkout.forms.country')}
            </label>
            <input
                className="field field-text"
                name="resellerCountry"
                id="resellerCountry"
                value={props.reseller.Country ?? ''}
                readOnly
            />
        </div>
    );
}