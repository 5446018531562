/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect } from 'react';
import SalesFigures from '../../components/SalesFigures/';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import SelectedNews from 'scripts/components/News/SelectedNews';
import DeliveryLists from 'scripts/components/DeliveryLists';
import BasicDataTable from 'scripts/components/DataTable/BasicDataTable';
import { formatDate } from '../../components/Utils/dates';
import { useMsal, useAccount } from '@azure/msal-react';
import {
    InteractionStatus,
} from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { TableLoader } from '../../components/ContentLoader';
import ErrorInfo from '../../components/Messages';

function Home() {
    const { accounts, inProgress } = useMsal();
    const { callApi } = useAxios();

    const { t } = useTranslation();

    const account = useAccount(accounts[0] || {});
    let name = account?.idTokenClaims.given_name, // eslint-disable-line camelcase
        isAdmin = account?.idTokenClaims.extension_Roles?.toLowerCase().includes('portal admin'); // eslint-disable-line camelcase


    const [orders, setOrders] = useState(null);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!orders && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/order/historyOrders?amount=3',
            }).then(
                (res) => {
                    if (res.status === 200) {
                        setOrders(res.data.Orders);
                        setOrdersLoading(false);
                    }
                },
                [orders, ordersLoading, inProgress, callApi]
            ).catch((error) => {
                setError(true)
                console.error(error); // eslint-disable-line
            });
        }
    });

    const columns = React.useMemo(
        () => [
            {
                Header: t('orders.header.order-number'),
                accessor: 'XAOrderNbr',
            },
            {
                Header: t('orders.header.date'),
                accessor: 'OrderDate',
                Cell: ({ value }) => {
                    return formatDate(String(value));
                },
            },
            {
                Header: t('orders.header.tracking-id'),
                accessor: 'TrackingID',
                Cell: function Tracking(cell) {
                    return (
                        <a
                            href={cell.row.original.TrackingURL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {cell.value}
                        </a>
                    );
                },
            },
            {
                Header: t('orders.header.order-mark'),
                accessor: 'OrderMark',
            },
            {
                Header: t('orders.header.reference'),
                accessor: 'OrderRef',
            },
            {
                Header: t('orders.header.dispatch-date'),
                accessor: 'PromiseWeek',
            },
            {
                Header: t('orders.header.sum'),
                accessor: 'OrderValue',
                Cell: (cell) => {
                    return `${cell.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })} ${cell.row.original.Currency}`;
                },
            },
        ],
        [t]
    );

    return (
        <div className="main-content">
            <h1>{t('home.welcome-title', { name: name })}</h1>
            <SelectedNews />
            {isAdmin && <SalesFigures />}

            <div className="orders">
                <h2>{t('home.recent-orders-title')}</h2>
                {ordersLoading && !error &&
                    <TableLoader />
                }
                {orders &&
                    <BasicDataTable
                        columns={columns}
                        data={orders}
                        className="table-boxed"
                        limit="2"
                    />
                }
                {error &&
                    <ErrorInfo text={t('error.title.orders')} />
                }
            </div>

            <DeliveryLists />
        </div>
    );
}

export default Home;