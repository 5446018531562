import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../../../../i18n';
import { useTranslation } from 'react-i18next';

export default function NewsArticle(props) {
    const [news, setNews] = useState({
        Title: null,
        Abstract: null,
        Content: null,
        Youtube: null,
    });

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
            url: '/rest-api/mediaApi/article',
            params: {
                market: 'sv-SE',
                node: id,
            }
        }).then((res) => {
            setNews(res.data);
            addClassTables();
        }).catch((err) => {
            console.error(err); //eslint-disable-line
        });
    }, [id]);

    let addClassTables = () => {
        let tables = document.querySelectorAll('table');
        tables.forEach((table) => {
            let columns = table.rows[0].cells.length;
            table.classList.add('table-default');
            table.classList.add('table-cols-' + columns);
        });
    };

    return (
        <div className="news-article">
            {news.Title && (
                <div>
                    <div
                        className="article-title article-component"
                        dangerouslySetInnerHTML={{ __html: news.Title }}></div>

                    <div className="article-image article-component">
                        <img src={news.ImageUrl} alt="News article"></img>
                    </div>

                    <div
                        className="article-abstract article-component"
                        dangerouslySetInnerHTML={{
                            __html: news.Abstract,
                        }}></div>

                    <div
                        className="article-content article-component"
                        dangerouslySetInnerHTML={{
                            __html: news.Content,
                        }}></div>
                    {news.Youtube && (
                        <div className="article-youtube article-component">
                            <iframe
                                title={news.Title}
                                src={news.Youtube.href}
                                alt="Youtube"></iframe>
                        </div>
                    )}

                    <button
                        className="button-submit button-secondary"
                        onClick={() => {
                            history.goBack();
                        }}>
                        {t('news.news-article.back')}
                    </button>
                </div>
            )}
        </div>
    );
}