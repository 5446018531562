import React, { useState } from 'react';

const TextArea = ({id, value: startValue, onChange, onContinue, isEnabled}) => {

    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.name, event.target.value);
        setValue(event.target.value);
    };

    const handleInputBlur = () => {
        onContinue();
    };

    return (
        <div className="cpq-option-input">
            <textarea
                id={id}
                name={id}
                value={value}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                disabled={!isEnabled}
            />
        </div>
    );
}

export default TextArea;