import React, { useState, useEffect } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { TableLoader } from '../../components/ContentLoader';
import { Link } from 'react-router-dom';

export default function Complaints() {
    const { callApi } = useAxios();
    const { inProgress } = useMsal();
    const [complaintsData, setComplaintsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (
            complaintsData.length === 0 &&
            inProgress === InteractionStatus.None &&
            loading
        ) {
            callApi({
                method: 'get',
                url: '/api/complaint/historyComplaints',
            }).then((response) => {
                setComplaintsData(response.data.value);
                setLoading(false);
            });
        }
    }, [complaintsData, loading, inProgress, callApi]);

    const complaintsColumns = React.useMemo(
        () => [
            {
                Header: t('complaints.table-header.created'),
                accessor: 'createdon',
                Cell: ({ value }) => {
                    return new Date(value).toLocaleString();
                },
            },
            {
                Header: t('complaints.table-header.complaints-id'),
                accessor: (row) => {
                    return row.ed_casenumber ? 'ed_casenumber' : 'ticketnumber';
                },
                Cell: function Tracking(cell) {
                    return (
                        <Link to={`/complaint/${cell.row.original.incidentid}`}>
                            {cell.row.original.ed_casenumber ? cell.row.original.ed_casenumber : cell.row.original.ticketnumber}
                        </Link>
                    );
                },
            },
            {
                Header: t('complaints.table-header.mark'),
                accessor: 'edp_ordermark',
            },
            {
                Header: t('complaints.table-header.related-order'),
                accessor: 'edp_ordernumber',
            },
            {
                Header: t('complaints.table-header.created-by'),
                accessor: (row) => {
                    return row.primarycontactid?.fullname;
                },
            },
        ],
        [t]
    );

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('complaints.historic.title')}</h1>
            </div>

            {loading ? (
                <TableLoader />
            ) : (
                <div className="content-secondary">
                    <h2>{t('complaints.historic.complaints')}</h2>
                    <DataTable
                        columns={complaintsColumns}
                        data={complaintsData}
                        className="table-default"
                        limit="2"
                    />
                </div>
            )}
        </div>
    );
}