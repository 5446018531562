import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal, useAccount } from '@azure/msal-react';

export default function NavigationList() {
    window.addEventListener('load', () => {
        // After DOM loaded
        currentPage();
    });

    useEffect(() => {
        // After DOM update
        currentPage();
    });

    const { t } = useTranslation();
    const { accounts } = useMsal();

    const
        accountInfo = useAccount(accounts[0] || {}),
        account = accountInfo?.idTokenClaims,
        isAdmin = account?.extension_Roles?.toLowerCase().includes('portal admin'); // eslint-disable-line camelcase

    const nav = [
        {
            title: t('nav.home'),
            to: '/home',
            icon: 'Home',
        },
        {
            title: t('nav.add-order'),
            to: '/order-add',
            icon: 'AddOrder',
        },
        {
            title: t('nav.orders'),
            icon: 'Orders',
            children: [
                {
                    title: t('nav.orders-open'),
                    to: '/orders',
                },
                {
                    title: t('nav.orders-closed'),
                    to: '/order-history',
                },
            ],
        },
        {
            title: t('nav.my-pages'),
            icon: 'MyPages',
            children: [
                {
                    title: t('nav.campaigns'),
                    to: '/campaigns',
                },
                {
                    title: t('nav.products-in-showroom'),
                    to: '/my-pages/products-in-showroom',
                },
                {
                    title: t('nav.add-products-in-showroom'),
                    to: '/my-pages/add-products',
                },
            ],
        },
        {
            title: t('nav.finance'),
            icon: 'Economy',
            children: [
                {
                    title: t('nav.finance-dashboard'),
                    to: '/finance',
                },
                {
                    title: t('nav.invoices'),
                    to: '/invoices',
                },
            ],
        },
        {
            title: t('nav.complaints'),
            icon: 'Complaints',
            children: [
                {
                    title: t('nav.complaint-new'),
                    to: '/complaint-new',
                },
                {
                    title: t('nav.complaints-open'),
                    to: '/complaints',
                },
                {
                    title: t('nav.complaints-closed'),
                    to: '/complaints-history',
                },
            ],
        },
        {
            title: t('nav.download'),
            icon: 'Download',
            children: [
                {
                    title: t('nav.download-documents'),
                    to: '/media',
                },
                {
                    title: t('nav.download-signs'),
                    to: '/media-signs',
                },
                {
                    title: t('nav.download-dop'),
                    to: '/media-dop',
                },
                {
                    title: t('nav.download-manuals'),
                    to: '/media-manuals',
                },
                {
                    title: t('nav.download-images'),
                    to: '/media-high-resolution',
                },
                {
                    title: t('nav.download-video'),
                    to: '/media-video',
                },
                {
                    title: t('nav.download-campaign'),
                    to: '/media-campaign',
                },
            ],
        },
        {
            title: t('nav.news'),
            to: '/news',
            icon: 'Bell',
        },
        {
            title: t('nav.contact'),
            to: '/contact',
            icon: 'Mail',
        },
    ];

    if (!isAdmin) {
        // Hide Invoices in menu for NON Admin users
        const economyIndex = nav.findIndex(e => e.icon === 'Economy');
        delete nav[economyIndex];
    }

    function currentPage() {
        let pathname = window.location.pathname,
            elem = document.querySelector('.menu a[href="' + pathname + '"]');

        if (elem) {
            currentItem(elem);
        }
    }

    function currentItem(o) {
        let self = o.target || o,
            openItems = self.closest('.menu').querySelectorAll('.menu-item-current'),
            menuItem = self.parentElement,
            menuItemHasChildren = menuItem.classList.contains('menu-item--has-children'),
            menuItemLevelOne = menuItem.classList.contains('menu-item--level-1'),
            menuItemLevelTwo = menuItem.classList.contains('menu-item--level-2'),
            selfHasOpenItems = menuItem.classList.contains('menu-item-current');

        if (!selfHasOpenItems || menuItemLevelTwo) {
            for (var i = 0; i < openItems.length; i++) {
                openItems[i].classList.remove('menu-item-current');
            }
        }

        if (menuItemHasChildren) {
            menuItem.classList.toggle('menu-item-current');
        } else if (menuItemLevelOne) {
            menuItem.classList.add('menu-item-current');
        } else if (menuItemLevelTwo) {
            let parentItem = self.closest('.menu-item--level-1');
            parentItem.classList.add('menu-item-current');
            menuItem.classList.add('menu-item-current');
        } else {
            menuItem.classList.add('menu-item-current');
        }
    }

    function listItems(items) {
        return items.map((item, index) =>
            // If has children
            item.children ? (
                <div
                    key={index}
                    className={`menu-item menu-item--level-1 menu-item--has-children`}
                    data-id={index + 1}>
                    <span onClick={currentItem} className="menu-item-link menu-item-link--level-1">
                        <Icon name={item.icon} />
                        {item.title}
                    </span>

                    <span className="menu-toggle-child" onClick={currentItem}></span>
                    <div className="menu menu--level-2">
                        {item.children.map((child, childIndex) => (
                            <div
                                key={childIndex}
                                className={`menu-item menu-item--level-2`}
                                data-id={index + 1 + '.' + (childIndex + 1)}>
                                <Link
                                    to={child.to}
                                    onClick={currentItem}
                                    className="menu-item-link menu-item-link--level-2">
                                    {child.title}
                                    { }
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div
                    key={item.title}
                    className={`menu-item menu-item--level-1`}
                    data-id={index + 1}>
                    <Link
                        to={item.to}
                        onClick={currentItem}
                        className="menu-item-link menu-item-link--level-1">
                        <Icon name={item.icon} />
                        {item.title}
                    </Link>
                </div>
            )
        );
    }

    return <div className="menu menu--level-1">{listItems(nav)}</div>;
}