/*
 * react-confirm-alert 2.7.0
 * https://github.com/GA-MO/react-confirm-alert
 * Custom version 2021-04 (GE)
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { render, unmountComponentAtNode } from 'react-dom'
import { ReactComponent as IconClose } from '../../../images/icons/close-simple.svg'

export default class ReactConfirmAlert extends Component {
    static propTypes = {
        title: PropTypes.string,
        message: PropTypes.string,
        buttons: PropTypes.array.isRequired,
        childrenElement: PropTypes.func,
        customUI: PropTypes.func,
        closeOnClickOutside: PropTypes.bool,
        closeOnEscape: PropTypes.bool,
        willUnmount: PropTypes.func,
        afterClose: PropTypes.func,
        onClickOutside: PropTypes.func,
        onKeypressEscape: PropTypes.func,
        overlayClassName: PropTypes.string
    }

    static defaultProps = {
        buttons: [{
            label: 'Cancel',
            onClick: () => null,
            className: null
        },
        {
            label: 'Confirm',
            onClick: () => null,
            className: null
        }],
        childrenElement: () => null,
        closeOnClickOutside: true,
        closeOnEscape: true,
        willUnmount: () => null,
        afterClose: () => null,
        onClickOutside: () => null,
        onKeypressEscape: () => null
    }

    handleClickButton = button => {
        if (button.onClick) {
            button.onClick()
        }
        this.close()
    }

    handleClickOverlay = e => {
        const { closeOnClickOutside, onClickOutside } = this.props
        const isClickOutside = e.target === this.overlay

        if (closeOnClickOutside && isClickOutside) {
            onClickOutside()
            this.close()
        }
    }

    close = () => {
        removeBodyClass()
        removeElementReconfirm()
    }

    keyboardClose = event => {
        const { closeOnEscape, onKeypressEscape } = this.props
        const isKeyCodeEscape = event.keyCode === 27

        if (closeOnEscape && isKeyCodeEscape) {
            onKeypressEscape(event)
            this.close()
        }
    }

    componentDidMount = () => {
        document.addEventListener('keydown', this.keyboardClose, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.keyboardClose, false)
        this.props.willUnmount()
    }

    renderCustomUI = () => {
        const { title, message, buttons, customUI } = this.props
        const dataCustomUI = {
            title,
            message,
            buttons,
            onClose: this.close
        }

        return customUI(dataCustomUI)
    }

    render() {
        const { title, message, buttons, childrenElement, customUI, overlayClassName } = this.props

        return (
            <div
                className={`react-confirm-alert-overlay ${overlayClassName}`}
                ref={dom => (this.overlay = dom)}
                onClick={this.handleClickOverlay}
            >
                <div className='react-confirm-alert'>
                    {customUI ? (
                        this.renderCustomUI()
                    ) : (
                        <div className='react-confirm-alert-body'>
                            {title && <h3>{title}</h3>}
                            {message && <p>{message}</p>}
                            {childrenElement()}
                            <div className='react-confirm-alert-buttons'>
                                {buttons.map((button, i) => (
                                    <button key={i} onClick={() => this.handleClickButton(button)} className={`react-confirm-alert-button ${button.className}`}>
                                        {button.label}
                                    </button>
                                ))}
                            </div>
                            <div onClick={this.close} className="react-confirm-alert-close"><IconClose /></div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

function createElementReconfirm(properties) {
    let divTarget = document.getElementById('react-confirm-alert')
    if (divTarget) {
        // Rerender - the mounted ReactConfirmAlert
        render(<ReactConfirmAlert {...properties} />, divTarget)
    } else {
        // Mount the ReactConfirmAlert component
        document.body.children[0].classList.add('react-confirm-alert-blur')
        divTarget = document.createElement('div')
        divTarget.id = 'react-confirm-alert'
        document.body.appendChild(divTarget)
        render(<ReactConfirmAlert {...properties} />, divTarget)
    }
}

function removeElementReconfirm() {
    const target = document.getElementById('react-confirm-alert')
    if (target) {
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
    }
}

function addBodyClass() {
    document.body.classList.add('react-confirm-alert-body-element')
}

function removeBodyClass() {
    document.body.classList.remove('react-confirm-alert-body-element')
}

export function confirmAlert(properties) {
    addBodyClass()
    createElementReconfirm(properties)
}

export function alertLoadingError(t) {
    return (
        confirmAlert({
            title: t('error.title.oops'),
            message: t('error.text.generic'),
            buttons: [
                {
                    label: t('button.close'),
                    className: 'button-primary',
                },
            ],
        })
    )
}