import React, { useState, useEffect } from 'react';
import ErrorMessage from '../../components/Forms/error-message';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { TableLoader } from '../../components/ContentLoader';

export default function Account(props) {
    const { callApi } = useAxios();
    const { inProgress } = useMsal();
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors }
    } = useForm()

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        clearErrors: clearErrors2,
        formState: { errors: errors2 }
    } = useForm()

    // USER DETAILS
    const [user, setUser] = useState(null)
    useEffect(() => {
        if (!user && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: `/api/reseller/myContact`
            })
                .then(response => {
                    let o = response.data;
                    setUser({
                        user: {
                            email: o.emailaddress1,
                            firstName: o.firstname,
                            lastName: o.lastname,
                            phoneOffice: o.telephone1,
                            phoneMobile: o.mobilephone,
                        }
                    })
                })
                .catch(error => console.error(error)) // eslint-disable-line no-console
        }
    }, [inProgress, setUser]); // eslint-disable-line react-hooks/exhaustive-deps

    // COMPANY POSTAL ADDRESS
    const [postalAddress, setPostalAddress] = useState(null)
    useEffect(() => {
        if (!postalAddress && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: `/api/reseller/postalAddress`
            })
                .then(response => {
                    let o = response.data;
                    setPostalAddress({
                        address: o.address1_line1,
                        zip: o.address1_postalcode,
                        city: o.address1_city
                    })
                })
                .catch(error => console.error(error)) // eslint-disable-line no-console
        }
    }, [inProgress, setPostalAddress]); // eslint-disable-line react-hooks/exhaustive-deps

    // COMPANY VISITING ADDRESS
    const [visitingAddress, setVisitingAddress] = useState(null)
    useEffect(() => {
        if (!visitingAddress && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: `/api/reseller/visitingAddress`
            })
                .then(response => {
                    let o = response.data;
                    setVisitingAddress({
                        address: o.address2_line1,
                        zip: o.address2_postalcode,
                        city: o.address2_city
                    })
                })
                .catch(error => console.error(error)) // eslint-disable-line no-console
        }
    }, [inProgress, setVisitingAddress]); // eslint-disable-line react-hooks/exhaustive-deps

    // COMPANY CONTACT DETAILS
    const [contactDetails, setContactDetails] = useState(null)
    useEffect(() => {
        if (!contactDetails && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: `/api/reseller/contactInfo`
            })
                .then(response => {
                    let o = response.data;
                    setContactDetails({
                        company: {
                            name: o.ed_commonname,
                            phone: o.telephone1,
                            email: o.emailaddress1,
                            url: o.websiteurl
                        }
                    })
                })
                .catch(error => console.error(error)) // eslint-disable-line no-console
        }
    }, [inProgress, setContactDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    // Set form with default details
    useEffect(() => {
        reset(user)
        reset2(contactDetails)
    }, [user, contactDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleEdit = (event) => {
        let
            fields = document.querySelectorAll('.field'),
            editBox = event.target.closest('.edit-box');

        for (var i = 0; i < fields.length; i++) {
            fields[i].readOnly = fields[i].readOnly ? false : true;
        }
        editBox.classList.toggle('edit-box-disabled');
    }

    const cancel = (event) => {
        clearErrors()
        reset(user)
        toggleEdit(event)
    }

    const cancel2 = (event) => {
        clearErrors2()
        reset(contactDetails)
        toggleEdit(event)
    }

    // Update User contact details
    const onSubmit = (data, event) => {
        toggleEdit(event)
    };

    // Update Company contact details
    const onSubmit2 = (data, event) => {
        callApi({
            method: 'put',
            url: '/api/reseller/contactInfo',
            data: {
                'commonName': data.company.name,
                'email': data.company.email,
                'website': data.company.url,
                'phone': data.company.phone
            }
        })
            .then(response => {
                toggleEdit(event)
            })
            .catch(error => console.error(error)) // eslint-disable-line no-console
    };

    return (
        <div className="main-content">
            <h1>{t('user.account.title')}</h1>

            {(user && postalAddress && visitingAddress && contactDetails)
                ?
                <>
                    {/* USER DETAILS */}
                    <div className="edit-box edit-box-disabled">
                        <div className="edit-box-header">
                            <div className="edit-box-title">{t('user.account.fieldset.user-details')}</div>
                            {/*
                                <div className="edit-box-btn edit-box-btn-edit" onClick={toggleEdit}>{t('button.edit')}</div>
                                <div className="edit-box-btn edit-box-btn-update" onClick={cancel}>{t('button.cancel')}</div>
                                */}
                        </div>

                        <div className="edit-box-content">
                            <form key={1} className="form-account" onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="email">{t('form.field.email')}</label>
                                <input
                                    id="email"
                                    type="email"
                                    className={`field field-text ${(errors.user && errors.user.email) ? 'field-error' : ''}`}
                                    {...register('user.email', {
                                        required: `${t('form.field.email')}  ${t('form.is-required')}`,
                                        pattern: {
                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
                                            message: `${t('form.field.email')}  ${t('form.has-invalid-format')}`,
                                        }
                                    })}

                                    readOnly
                                />
                                <ErrorMessage errors={(errors.user && errors.user.email)} />

                                <label htmlFor="firstname">{t('form.field.first-name')}</label>
                                <input
                                    id="firstname"
                                    type="text"
                                    className={`field field-text ${(errors.user && errors.user.firstName) ? 'field-error' : ''}`}
                                    {...register('user.firstName', {
                                        required: `${t('form.field.first-name')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors.user && errors.user.firstName)} />

                                <label htmlFor="lastname">{t('form.field.last-name')}</label>
                                <input
                                    id="lastname"
                                    type="text"
                                    className={`field field-text ${(errors.user && errors.user.lastName) ? 'field-error' : ''}`}
                                    {...register('user.lastName', {
                                        required: `${t('form.field.last-name')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors.user && errors.user.lastName)} />

                                <label htmlFor="phone-office">{t('form.field.phone-office')}</label>
                                <input
                                    id="phone-office"
                                    type="tel"
                                    className={`field field-text ${(errors.user && errors.user.phoneOffice) ? 'field-error' : ''}`}
                                    {...register('user.phoneOffice', {
                                        required: `${t('form.field.phone-office')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors.user && errors.user.phoneOffice)} />

                                <label htmlFor="phone-mobile">{t('form.field.phone-mobile')}</label>
                                <input
                                    id="phone-mobile"
                                    type="tel"
                                    className={`field field-text ${(errors.user && errors.user.phoneMobile) ? 'field-error' : ''}`}
                                    {...register('user.phoneMobile', {
                                        required: `${t('form.field.phone-mobile')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors.user && errors.user.phoneMobile)} />

                                <div className="buttongroup">
                                    <button type="button" className="button-cancel button-tertiary" onClick={cancel}>{t('button.cancel')}</button>
                                    <button type="submit" className="button-submit button-primary">{t('button.submit')}</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* COMPANY POSTAL ADDRESS */}
                    <div className="edit-box edit-box-disabled">
                        <div className="edit-box-header">
                            <div className="edit-box-title">{t('user.account.fieldset.postal-address')}</div>
                        </div>
                        <div className="edit-box-content">
                            <form>
                                <label>{t('form.field.address')}</label>
                                <input type="text" className="field field-text" value={(postalAddress && postalAddress.address) ? postalAddress.address : ''} readOnly />

                                <label>{t('form.field.zip')}</label>
                                <input type="text" className="field field-text" value={(postalAddress && postalAddress.zip) ? postalAddress.zip : ''} readOnly />

                                <label>{t('form.field.city')}</label>
                                <input type="text" className="field field-text" value={(postalAddress && postalAddress.city) ? postalAddress.city : ''} readOnly />
                            </form>
                        </div>
                    </div>

                    {/* COMPANY VISITING ADDRESS */}
                    <div className="edit-box edit-box-disabled">
                        <div className="edit-box-header">
                            <div className="edit-box-title">{t('user.account.fieldset.physical-address')}</div>
                        </div>
                        <div className="edit-box-content">
                            <form>
                                <label>{t('form.field.address')}</label>
                                <input type="text" className="field field-text" value={(visitingAddress && visitingAddress.address) ? visitingAddress.address : ''} readOnly />

                                <label>{t('form.field.zip')}</label>
                                <input type="text" className="field field-text" value={(visitingAddress && visitingAddress.zip) ? visitingAddress.zip : ''} readOnly />

                                <label>{t('form.field.city')}</label>
                                <input type="text" className="field field-text" value={(visitingAddress && visitingAddress.city) ? visitingAddress.city : ''} readOnly />
                            </form>
                        </div>
                    </div>

                    {/* COMPANY CONTACT DETAILS */}
                    <div className="edit-box edit-box-disabled">
                        <div className="edit-box-header">
                            <div className="edit-box-title">{t('user.account.fieldset.contact-details')}</div>
                            <div className="edit-box-btn edit-box-btn-edit" onClick={toggleEdit}>{t('button.edit')}</div>
                            <div className="edit-box-btn edit-box-btn-update" onClick={cancel2}>{t('button.cancel')}</div>
                        </div>
                        <div className="edit-box-content">
                            <form key={2} onSubmit={handleSubmit2(onSubmit2)}>

                                <label htmlFor="company-name">{t('form.field.store-name')}</label>
                                <input
                                    id="company-name"
                                    type="text"
                                    className={`field field-text ${(errors2.company && errors2.company.name) ? 'field-error' : ''}`}
                                    {...register2('company.name', {
                                        required: `${t('form.field.store-name')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors2.company && errors2.company.name)} />

                                <label htmlFor="phone">{t('form.field.phone')}</label>
                                <input
                                    id="phone"
                                    type="tel"
                                    className={`field field-text ${(errors2.company && errors2.company.phone) ? 'field-error' : ''}`}
                                    {...register2('company.phone', {
                                        required: `${t('form.field.phone')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors2.company && errors2.company.phone)} />

                                <label htmlFor="email">{t('form.field.email')}</label>
                                <input
                                    id="email"
                                    type="email"
                                    className={`field field-text ${(errors2.company && errors2.company.email) ? 'field-error' : ''}`}
                                    {...register2('company.email', {
                                        required: `${t('form.field.email')}  ${t('form.is-required')}`,
                                        pattern: {
                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
                                            message: `${t('form.field.email')}  ${t('form.has-invalid-format')}`,
                                        }
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors2.company && errors2.company.email)} />

                                <label htmlFor="url">{t('form.field.website')}</label>
                                <input
                                    id="url"
                                    type="text"
                                    className={`field field-text ${(errors2.company && errors2.company.url) ? 'field-error' : ''}`}
                                    {...register2('company.url', {
                                        required: `${t('form.field.website')}  ${t('form.is-required')}`,
                                    })}
                                    readOnly
                                />
                                <ErrorMessage errors={(errors2.company && errors2.company.url)} />

                                <div className="buttongroup">
                                    <button type="button" className="button-cancel button-tertiary" onClick={cancel2}>{t('button.cancel')}</button>
                                    <button type="submit" className="button-submit button-primary">{t('button.submit')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
                :
                <TableLoader />
            }
        </div>
    );
}