/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useAxios from 'scripts/hooks/useAxios';
import { TextLoader } from '../../components/ContentLoader';
import { useTranslation } from 'react-i18next';
import ErrorInfo from '../../components/Messages';

export default function Contact() {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const [salesRep, setSalesRep] = useState(null);
    const [departments, setDepartments] = useState(null);
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        if (!salesRep && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/reseller/salesRepInfo',
            }).then((res) => {
                setSalesRep({
                    firstName: res.data.firstname,
                    lastName: res.data.lastname,
                    email: res.data.internalemailaddress,
                    phoneOffice: res.data.address1_telephone1,
                    phoneMobile: res.data.mobilephone,
                });
            }).catch((error) => {
                setError(true)

            });
        }
    }, [salesRep, inProgress, callApi]);

    useEffect(() => {
        if (!departments) {
            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
                url: '/rest-api/mediaApi/contact',
                params: {
                    renderType: 'textData'
                }
            }).then((res) => {
                setDepartments([
                    {
                        name: t('contact.customer-service'),
                        email: res.data.CustomerServiceEmail,
                        phone: res.data.CustomerServicePhone,
                    },
                    {
                        name: t('contact.service'),
                        email: res.data.ServiceEmail,
                        phone: res.data.ServicePhone,
                    },
                    {
                        name: t('contact.marketing-communication'),
                        email: res.data.MarketingCommunicationEmail,
                        phone: res.data.MarketingCommunicationPhone,
                    },
                ]);
            }).catch((error) => {
                setDepartments([]);
                console.error(error); //eslint-disable-line
            });
        }
    }, [departments]);

    const Departments = ({departments}) => {
        return (
            <>
                {departments &&
                    departments.map((department, index) => (
                        <div className="department" key={index}>
                            <h4 className="department-title">
                                {t('contact.contact-details-to')} {department.name}
                            </h4>
                            <div className="department-body">
                                <div className="column">
                                    <a
                                        className="data-pair"
                                        href={`mailto:${department.email}`}>
                                        <label>{t('contact.email')}</label>
                                        <p>{department.email}</p>
                                    </a>
                                </div>
                                <div className="column">
                                    <a
                                        className="data-pair"
                                        href={`tel:${department.phone}`}>
                                        <label>{t('contact.phone')}</label>
                                        <p>{department.phone}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {departments?.length < 1 &&
                    <ErrorInfo text={t('error.title.contact-details')} />
                }
            </>
        )
    };

    const SalesRep = ({salesRep}) => {
        return (
            <div className="sales-rep-data">
                <div className="department department--customer">
                    <h4 className="department-title">
                        {t('contact.contact-details-to')} {t('contact.sales-representative')}
                    </h4>
                    {!salesRep && !error &&
                        <TextLoader  style={{width: '100%', maxWidth: '400px', margin: '20px'}} />
                    }
                    {error &&
                        <ErrorInfo text={t('error.title.contact-details')} />
                    }
                    {salesRep &&
                        <>
                            {salesRep.email && (
                                <div className="column">
                                    <a
                                        className="data-pair"
                                        href={`mailto:${salesRep.email}`}>
                                        <label>{t('contact.email')}</label>
                                        <p>{salesRep.email}</p>
                                    </a>
                                </div>
                            )}
                            {salesRep.firstName && (
                                <div className="column">
                                    <div className="data-pair">
                                        <label>{('contact.first-name')}</label>
                                        <p>{salesRep.firstName}</p>
                                    </div>
                                </div>
                            )}
                            {salesRep.lastName && (
                                <div className="column">
                                    <div className="data-pair">
                                        <label>{t('contact.last-name')}</label>
                                        <p>{salesRep.lastName}</p>
                                    </div>
                                </div>
                            )}
                            {salesRep.phoneOffice && (
                                <div className="column">
                                    <a
                                        className="data-pair"
                                        href={`tel:${salesRep.phoneOffice}`}>
                                        <label>{t('contact.phone-office')}</label>
                                        <p>{salesRep.phoneOffice}</p>
                                    </a>
                                </div>
                            )}
                            {salesRep.phoneMobile && (
                                <div className="column">
                                    <a
                                        className="data-pair"
                                        href={`tel:${salesRep.phoneMobile}`}>
                                        <label>{t('contact.phone-mobile')}</label>
                                        <p> {salesRep.phoneMobile}</p>
                                    </a>
                                </div>
                            )}
                        </>
                    }
                </div>
            </div>
        );
    };
    return (
        <div className="contact main-content">
            <h1>{t('contact.contact-us')}</h1>
            <SalesRep salesRep={salesRep} />
            <Departments departments={departments} />
        </div>
    );
}