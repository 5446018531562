/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import { formatDate } from '../../components/Utils/dates';
import { confirmAlert } from '../../components/Confirm';
import { TableLoader } from '../../components/ContentLoader';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import Order from './order';
import { alertLoadingError } from '../../components/Confirm';

const Orders = () => {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    const [orders, setOrders] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const [order, setOrder] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderError, setOrderError] = useState(false);
    const [cancelOrderLoading, setCancelOrderLoading] = useState(false);

    useEffect(() => {
        if (!orders && inProgress === InteractionStatus.None) {
            getOpenOrders();
        }
    }, [orders, loading, inProgress, callApi]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const getOpenOrders = () => {
        callApi({
            method: 'get',
            url: '/api/order/openOrders?amount=999',
        }).then((res) => {
            if (res.status === 200) {
                setOrders(res.data.Orders);
                setLoading(false);
            }
        }).catch(error => {
            setError(true);
            setLoading(false);
            alertLoadingError(t);
            console.error(error);
        })
    };

    const getOrder = (orderNumber) => {
        setOrderLoading(true)
        document.documentElement.classList.add('show-nb-modal')
        callApi({
            method: 'get',
            url: `/api/order/orderDetails?orderNumber=${orderNumber}`
        }).then((res) => {
            if (res.status === 200) {
                setOrder(res.data.Order);
                setOrderLoading(false)
            }
        }).catch(error => {
            document.documentElement.classList.remove('show-nb-modal')
            setOrderError(true)
            setOrderLoading(false)
            alertLoadingError(t)
            console.error(error)
        })
    };

    const deleteOrder = (order) => {
        console.log('delete order', order);
        setCancelOrderLoading(true);
        callApi({
            method: 'put',
            url: '/api/order/cancelOrder',
            data: {
                orderNumber: order.id.trim(),
            }
        }).then((res) => {
            if (res.status === 200) {
                setCancelOrderLoading(false);
                getOpenOrders();
            } else {
                setCancelOrderLoading(false);
                alertLoadingError(t);
            }
        }).catch((error) => {
            console.error(error);
            setCancelOrderLoading(false);
            alertLoadingError(t);
        });
    };

    const columns = React.useMemo(() => {
        const confirmDelete = (order) => {
            console.log('order', order);
            if (order.isDeletable) {
                confirmAlert({
                    title: t('orders.confirm.deletable.title'),
                    message: t('orders.confirm.deletable.text'),
                    buttons: [
                        {
                            label: t('button.annul'),
                            onClick: () => deleteOrder(order),
                            className: 'button',
                        },
                        {
                            label: t('button.cancel'),
                            className: 'button-primary',
                        },
                    ],
                });
            } else {
                confirmAlert({
                    title: t('orders.confirm.non-deletable.title'),
                    message: t('orders.confirm.non-deletable.text'),
                    buttons: [
                        {
                            label: t('button.contact-us'),
                            onClick: () => redirectToContact(),
                            className: 'button',
                        },
                        {
                            label: t('button.cancel'),
                            className: 'button-primary',
                        },
                    ],
                });
            }
        };

        return [
            {
                Header: t('orders.header.order-number'),
                accessor: 'XAOrderNbr',
                Cell: function Tracking(cell) {
                    return (
                        <span
                            className="fake-link"
                            onClick={() => getOrder(cell.value)}
                        >
                            {cell.value}
                        </span>
                    );
                }
            },
            {
                Header: t('orders.header.date'),
                accessor: 'OrderDate',
                Cell: ({ value }) => {
                    return formatDate(String(value));
                },
            },
            {
                Header: t('orders.header.order-mark'),
                accessor: 'OrderMark',
            },
            {
                Header: t('orders.header.reference'),
                accessor: 'OrderRef',
            },
            {
                Header: t('orders.header.dispatch-date'),
                accessor: 'PromiseWeek',
            },
            {
                Header: t('orders.header.sum'),
                accessor: 'OrderValue',
                Cell: (cell) => {
                    return `${cell.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })} ${cell.row.original.Currency}`;
                },
            },
            {
                Header: '',
                id: 'abc',
                Cell: ({ row }) => {
                    if (row.original.OrderStatus === '10') {
                        return (
                            <button
                                type="button"
                                className="button-sm button-secondary"
                                onClick={() => {
                                    confirmDelete({
                                        id: row.original.XAOrderNbr,
                                        isDeletable: true,
                                    });
                                }}
                            >
                                {t('button.annul')}
                            </button>
                        );
                    } else {
                        return null;
                    }
                },
                disableSortBy: true,
            },
        ];
    }, [t]);// eslint-disable-line react-hooks/exhaustive-deps

    const redirectToContact = () => {
        window.location.href = '/contact/';
    };

    return (
        <div className="main-content">
            <div className="primary-content">

                <h1>{t('orders.pending-orders-title')}</h1>
                {loading &&
                    <TableLoader />
                }
                {!loading && !error &&
                    <>
                        <DataTable
                            columns={columns}
                            data={orders}
                            className="table-default"
                            limit="2"
                            getCellProps={(cellInfo) => {
                                if (cellInfo.column.id === 'abc') {
                                    return {
                                        className: 'td-delete-row',
                                    };
                                }
                                return {};
                            }}
                        />
                        {cancelOrderLoading &&
                            <div className="page-is-loading">
                                <svg className="loading-spinner" viewBox="0 0 50 50">
                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                </svg>
                            </div>
                        }
                    </>
                }    
            </div>
            {!orderError &&
                <Order order={order} orderLoading={orderLoading} />
            }
        </div>
    );
};

export default Orders;