import React, { useState, useEffect } from 'react';
import CategoryList from '../../components/CategoryList/';
import axios from 'axios';
import { TableLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';
import { useTranslation } from 'react-i18next';

const Signs = () => {
    const [signs, setSigns] = useState(null)
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
            url: '/rest-api/mediaApi/files',
            params: {
                market: 'global',
                contentType: 'ps',
            },
        }).then((res) => {
            setSigns(res.data[0]);
        }).catch((error) => {
            setError(true)
            alertLoadingError(t)
        });
    }, [t]);

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('media.product-signs.title')}</h1>
            </div>
            {signs && signs.items.length > 0 &&
                <div className="content-secondary">
                    <CategoryList items={signs.items} />
                </div>
            }
            {!signs && !error &&
                <TableLoader />
            }
        </div>
    );
}

export default Signs;