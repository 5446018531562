/* eslint no-console: ["error", { allow: ["error", "log"] }] */
import React  from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoader } from '../../components/ContentLoader';
import '../../../i18n';
import { formatNumber } from '../../components/Utils/numbers';

const Order = ({orderLoading, order}) => {
    const { t } = useTranslation();
    let lines = [];

    if(order) {
        lines = order.DetLines.filter(function(el) {
            return el.LineSequence !== 0
        });
    }

    const OrderItem = ({item}) => {
        return (
            <tr>
                <td>{item.ItemNumber}</td>
                <td>
                    <p>{item.Description}</p>
                    {item.LineComments.map((item, i) => <ItemDetails key={i} item={item}/>)}
                </td>
                <td>{item.OrderedQty}</td>
                <td>{formatNumber(item.Price)}</td>
                <td>{item.Discount}</td>
                <td>{formatNumber(item.NetSalesAmt)}</td>
            </tr>
        )
    }

    const ItemDetails = ({item}) => {
        return (
            <p>
                {item.Comment}
            </p>
        )
    }

    const closeModal = () => {
        document.documentElement.classList.remove('show-nb-modal')
    }

    return (
        <div className="nb-modal single-order">
            <div className="nb-modal-container">
                <div
                    className="nb-modal-close"
                    onClick={() => closeModal()}
                ></div>
                <div className="nb-modal-content">
                    {orderLoading &&
                        <TableLoader />
                    }

                    {!orderLoading &&
                        <>
                            {lines.length > 0 &&
                                <>
                                    <h1>{t('order.title')}</h1>
                                    <p>{t('order.number')}: <b>{order.OrderNumber}</b></p>
                                    {order.OrderMark && <p>{t('order.mark')}: <b>{order.OrderMark}</b></p>}
                                    {order.Message && <p><br />{t('order.message')}: {order.Message}</p>}
                                    <table className="table-fixed">
                                        <thead>
                                            <tr>
                                                <th>{t('order.table-header.article-nr')}</th>
                                                <th>{t('order.table-header.article')}</th>
                                                <th>{t('order.table-header.quantity')}</th>
                                                <th>{t('order.table-header.price')}</th>
                                                <th>{t('order.table-header.discount')} %</th>
                                                <th>{t('order.table-header.sum-excl-vat')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lines.map((item, i) => <OrderItem key={i} item={item}/>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="4"></td>
                                                <td>{t('order.table-header.sum')}</td>
                                                <td><b>{formatNumber(order.OrderTotal)}</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </>
                            }

                            {lines.length < 1 &&
                                <p>{t('orders.no-order-lines-found')}</p>
                            }
                        </>
                    }
                </div>
            </div>
            <div className="nb-modal-overlay" onClick={() => closeModal()}></div>
        </div>
    )
}

export default Order;