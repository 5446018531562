import React, { useState } from "react";
import { ReactComponent as IconSearch } from '../../../images/icons/search.svg'
import { ReactComponent as IconClear } from '../../../images/icons/close.svg'
import { ReactComponent as IconClose } from '../../../images/icons/close-simple.svg'
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import useAxios from 'scripts/hooks/useAxios';
import { TextLoader } from '../../components/ContentLoader';

const SearchProducts = () => {
    const { callApi } = useAxios();
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState('')
    const [hasQuery, setHasQuery] = useState(false);
    const [products, setProducts] = useState([])

    const keyboardClose = event => {
        if (event.keyCode === 27) {
            closeSearchResults()
        }
    }

    const closeSearchResults = () => {
        setQuery('')
        setHasQuery(false)
        document.documentElement.classList.remove('has-visible-search-products-results');
    }

    const redirectToPage = (href) => {
        localStorage.removeItem('breadcrumbs');
        window.location.href = href;
    }

    const clearSearch = () => {
        setQuery('')
        setHasQuery(false)
        closeSearchResults()
    }

    const onSearchChange = (event) => {
        let q = event.target.value
        setQuery(q)

        if(q.trim() !== '') {
            setHasQuery(true)
        } else {
            setHasQuery(false)
        }
    }

    const getSearchResults = (event) => {
        if (event.key === 'Enter' && query.trim() !== '') {
            document.documentElement.classList.add('has-visible-search-products-results');
            document.addEventListener('keydown', keyboardClose, false)

            setLoading(true)
            callApi({
                method: 'get',
                url: `/api/order/products?search=${query}`
            })
                .then(response => {
                // Rename JSON keys
                    let products = response.data.map(
                        o => {
                            return {
                                'id' : o.NodeId,
                                'title' : o.ItemDescription,
                                'sku': o.NodeId,
                                'image': window.location.hostname === 'localhost' ? `https://weu-wap-3101-d-001.azurewebsites.net${o.ImageUrl}` : o.ImageUrl,
                                'to': `/shop/product/${o.ItemNumber}`
                            }
                        }
                    );
                    setProducts(products)
                    setLoading(false)
                })
                .catch(error => console.error(error)) // eslint-disable-line no-console
        }
    }

    const SearchResultList = () => {
        return (
            <div className="search-result-items">
                { products.length ? (
                    products.map((product, key) => (
                        <div className="search-result-item" key={key} onClick={() => redirectToPage(product.to)}>
                            <div className="search-result-item-figure">
                                <div className="search-result-item-figure-content">
                                    <img src={product.image} alt={product.title} />
                                </div>
                            </div>
                            <h3 className="search-result-item-title">{product.title}</h3>
                            { product.description &&
                                <p className="search-result-item-description">{product.description}</p>
                            }
                        </div>
                    ))
                ): ('')
                }
            </div>
        )
    }

    return (
        <div className={`search-products${hasQuery ? ' search-products-has-query' : ''}`}>
            <div className="search-products-fieldset">
                <IconSearch />
                <input
                    className="search-products-input"
                    placeholder={t('head.search.placeholder')}
                    type="search"
                    value={query}
                    onChange={onSearchChange}
                    onKeyDown={getSearchResults}
                />
                <span className="search-products-clear">
                    <IconClear onClick={clearSearch} />
                </span>
            </div>
            <div className="search-products-results">
                {loading ?
                    <TextLoader style={{width: '100%', maxWidth: '400px', margin: '80px'}} />
                    :
                    <div className="search-products-results-content">
                        <h3>
                            {products.length ?
                                t('head.search.results-title', {count: `(${products.length})`})
                                :
                                t('head.search.no-results-title', {query: `"${query}"`})
                            }
                        </h3>
                        <SearchResultList />
                    </div>
                }
                <div onClick={closeSearchResults} className="search-products-results-close"><IconClose /></div>
            </div>
        </div>
    );
}

export default SearchProducts;