import React, { useEffect, useContext, useState, useCallback } from 'react';
import { CartContext } from '../../components/Context/cart/CartContext';
import CartItem from '../../components/Cart/shopping-cart-item';
import Checkout from '../../components/Orders/Checkout/';
import OrderConfirmation from '../../components/Orders/OrderConfirmation';
import { formatNumber } from '../../components/Utils/numbers';
import useAxios from 'scripts/hooks/useAxios';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { TableLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';

const CheckoutPage = () => {
    const { total, cartItems, itemCount, updatePrice, handleCheckout } =
        useContext(CartContext);
    const [loading, setLoading] = useState(true);
    const [orderResponse, setOrderResponse] = useState({});
    const [orderData, setOrderData] = useState({});
    const [reseller, setReseller] = useState({});
    const [orderItems, setOrderItems] = useState({});
    const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
    const [freight, setFreight] = useState(0);
    const [grandtotal, setGrandtotal] = useState(0);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);

    const { t } = useTranslation();
    const { callApi } = useAxios();

    const updateSummary = useCallback(() => {
        let // TMP settings
            sum = total,
            grandtotal = parseFloat(sum) + parseFloat(freight);
        setGrandtotal(grandtotal);
    }, [total, freight]);

    const updateSummaryValues = (key, value) => {
        switch (key) {
            case 'freight':
                setFreight(value);
                break;
            default:
        }
    };
    // Check if prices have changed after the products were added to the cart
    const validateCart = useCallback(() => {
        let promises = [];

        cartItems.map(async(product, index) => {
            if (product.type !== 'config') {
                let cartItem = await callApi({
                    method: 'get',
                    url: `/api/order/productDetails?itemNo=${product.id}`,
                }).then((response) => {
                    if (product.price !== response.data.UnitPrice) {
                        product.price = Math.floor(Math.random() * 1000) + 1; // TMP price fix
                        updatePrice(product);
                    }
                });

                promises.push(cartItem);
            }
            return null;
        });

        Promise.all(promises).then(() => {
            updateSummary();
            setLoading(false);
        });
    }, [cartItems, updatePrice, updateSummary, callApi]);

    const placeOrder = (orderData, resellerData) => {
        setPlaceOrderLoading(true);
        callApi({
            method: 'post',
            url: `/api/order/placeOrder`,
            data: orderData,
        })
            .then((res) => {
                if (res.status === 200 && res.data.Status) {
                    setPlaceOrderLoading(false);
                    setOrderResponse(res);
                    setOrderData(orderData);
                    setReseller(resellerData);
                    setOrderItems(cartItems); // Before cart is cleared
                    setShowOrderConfirmation(true);
                    handleCheckout();
                } else {
                    setPlaceOrderLoading(false);
                    alertLoadingError(t)
                }
            })
            .catch((err) => {
                console.error(err); // eslint-disable-line
                setPlaceOrderLoading(false);
                alertLoadingError(t)
            });
    };

    useEffect(() => validateCart(), []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => updateSummary());

    const CheckoutCart = () => {
        return (
            <div
                className={`checkout-cart cart-container${itemCount < 1 ? ' cart-container-has-empty-cart' : ''
                }`}>
                <div className="cart-content">
                    <div className="cart-main">
                        <div className="cart-line-items shopping-cart-line-items">
                            {cartItems.map((cartItems) => (
                                <CartItem
                                    key={cartItems.id}
                                    product={cartItems}
                                />
                            ))}

                            {cartItems.length < 1 && (
                                <div className="empty-cart">
                                    {t('cart.empty')}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="cart-footer">
                        <div className="cart-summary-subtotal cart-summary">
                            <span className="cart-summary-label">
                                {t('cart.subtotal')}
                            </span>
                            <span
                                className="cart-summary-value"
                                data-cart-subtotal="">
                                {formatNumber(total)}
                            </span>
                        </div>
                        <div className="cart-summary-shipping cart-summary">
                            <span className="cart-summary-label">
                                {t('cart.shipping')}
                            </span>
                            <span className="cart-summary-value">
                                {formatNumber(freight)}
                            </span>
                        </div>

                        <div className="cart-summary-grandtotal cart-summary">
                            <span className="cart-summary-label">
                                {t('cart.grandtotal')}
                            </span>
                            <span className="cart-summary-value">
                                {formatNumber(grandtotal)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="checkout-page">
            {showOrderConfirmation ? (
                <div className="order-confirmation-content">
                    <OrderConfirmation
                        orderResponse={orderResponse}
                        orderData={orderData}
                        reseller={reseller}
                        freight={freight}
                        cartItems={orderItems}
                    />
                </div>
            ) : (
                <div className="checkout-container">
                    <div className="checkout-content checkout-content-primary">
                        <Checkout
                            cartItems={cartItems}
                            updateSummaryValues={updateSummaryValues}
                            placeOrder={placeOrder}
                            setFreight={setFreight}
                        />
                        {placeOrderLoading && (
                            <div className="page-is-loading">
                                <svg
                                    className="loading-spinner"
                                    viewBox="0 0 50 50">
                                    <circle
                                        className="path"
                                        cx="25"
                                        cy="25"
                                        r="20"
                                        fill="none"
                                        strokeWidth="5"></circle>
                                </svg>
                            </div>
                        )}
                    </div>
                    <div className="checkout-content-secondary">
                        {loading ? <TableLoader /> : <CheckoutCart />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckoutPage;