/* eslint no-console: ["error", { allow: ["error", "log"] }] */

import React, { useState, useEffect } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import { ReactComponent as IconSearch } from '../../../images/icons/search.svg';
import { TableWithSearchLoader } from '../../components/ContentLoader';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { formatDate } from '../../components/Utils/dates';
import { useAsyncDebounce } from 'react-table';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import Order from './order';
import { alertLoadingError } from '../../components/Confirm';

const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <form className="form-order-history">
            <div className="field-search">
                <input
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    className="field field-search-input"
                    type="search"
                />
                <IconSearch className="button-search" />
            </div>
        </form>
    );
};

const OrderHistory = () => {
    const { inProgress } = useMsal();
    const { callApi } = useAxios();
    const { t } = useTranslation();
    const [orders, setOrders] = useState(null);
    const [order, setOrder] = useState(null);
    const [orderLoading, setOrderLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const [orderError, setOrderError] = useState(false)

    useEffect(() => {
        if (!orders && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/order/historyOrders?amount=999',
            }).then((res) => {
                if (res.status === 200) {
                    let theOrders = res.data.Orders
                    theOrders.forEach(e => e.complaint = e.XAOrderNbr);

                    setOrders(theOrders);
                    setLoading(false);
                }
            }).catch(error => {
                setError(true)
                setLoading(false)
                alertLoadingError(t)
                console.error(error)
            })
        }
    }, [orders, loading, inProgress, callApi]); // eslint-disable-line react-hooks/exhaustive-deps

    const getOrder = (orderNumber) => {
        setOrderLoading(true)
        document.documentElement.classList.add('show-nb-modal')
        callApi({
            method: 'get',
            url: `/api/order/orderDetails?orderNumber=${orderNumber}`
        }).then((res) => {
            if (res.status === 200) {
                setOrder(res.data.Order);
                setOrderLoading(false)
            }
        }).catch(error => {
            document.documentElement.classList.remove('show-nb-modal')
            setOrderError(true)
            setOrderLoading(false)
            alertLoadingError(t)
            console.error(error)
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t('orders.header.order-number'),
                accessor: 'XAOrderNbr',
                Cell: function Tracking(cell) {
                    return (
                        <span
                            className="fake-link"
                            onClick={() => getOrder(cell.value)}
                        >
                            {cell.value}
                        </span>
                    );
                }
            },
            {
                Header: t('orders.header.date'),
                accessor: 'OrderDate',
                Cell: ({ value }) => {
                    return formatDate(String(value));
                },
            },
            {
                Header: t('orders.header.tracking-id'),
                accessor: 'TrackingID',
                Cell: function Tracking(cell) {
                    return (
                        <a
                            href={cell.row.original.TrackingURL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {cell.value}
                        </a>
                    );
                },
            },
            {
                Header: t('orders.header.order-mark'),
                accessor: 'OrderMark',
            },
            {
                Header: t('orders.header.reference'),
                accessor: 'OrderRef',
            },
            {
                Header: t('orders.header.dispatch-date'),
                accessor: 'PromiseWeek',
            },
            {
                Header: t('orders.header.sum'),
                accessor: 'OrderValue',
                Cell: (cell) => {
                    return `${cell.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })} ${cell.row.original.Currency}`;
                },
            },
            {
                Header: 'Skapa reklamation',
                accessor: 'complaint',
                Cell: function Tracking(cell) {
                    return (
                        <a
                            href={`/complaint-new/${cell.row.original.complaint}`}
                        >
                            {t('orders.complain')}
                        </a>
                    );
                },
                disableSortBy: true,
            }
        ], [t]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="main-content">
            <div className="primary-content">

                <h1>{t('orders.order-history-title')}</h1>
                {loading &&
                    <TableWithSearchLoader />
                }
                {!loading && !error &&
                    <DataTable
                        columns={columns}
                        data={orders}
                        className="table-default"
                        limit="2"
                        GlobalFilter={GlobalFilter}
                    />
                }

            </div>
            {!orderError &&
                <Order order={order} orderLoading={orderLoading} />
            }
        </div>
    );
};

export default OrderHistory;