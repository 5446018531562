import React, { useState } from 'react';

const CheckBox = ({id, value: startValue, isEnabled, onChange, onContinue}) => {

    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.name, event.target.checked ? 'True': 'False');
        setValue(event.target.checked ? 'True': 'False');
        onContinue();
    };

    return (
        <div className="cpq-option-input">
            <div className="cpq-checkbox-control">
                <input
                    type="checkbox"
                    id={id}
                    name={id}
                    checked={value === 'True'}
                    onChange={handleInputChange}
                    disabled={!isEnabled}
                    className="cpq-checkbox-field"
                />
                <label
                    htmlFor={id}
                    className="cpq-checkbox-label"
                >
                </label>
            </div>
        </div>
    );
};

export default CheckBox;