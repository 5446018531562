/* eslint no-console: ["error", { allow: ["warn", "error", "log", "dir"] }] */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useAxios from 'scripts/hooks/useAxios';
import { CartContext } from '../../Context/cart/CartContext';
import { useMsal, useAccount } from '@azure/msal-react';
import { LargeFormLoader } from '../../../components/ContentLoader';
import { alertLoadingError, confirmAlert } from '../../../components/Confirm';
import { useTranslation } from 'react-i18next';

import StdDelivery from './stdDelivery';
import AltDelivery from './altDelivery';

export default function Checkout({ placeOrder, setFreight }) {
    const { cartItems } = useContext(CartContext);
    const { callApi } = useAxios();
    const { accounts } = useMsal();
    const [error, setError] = useState(false);
    const { t } = useTranslation();

    const account = useAccount(accounts[0] || {});
    let custMarket = account.idTokenClaims.extension_market.toLowerCase(),
        custNameArray = account.name?.split(" ") ?? [],
        custName = custNameArray[0] + " " + custNameArray[custNameArray.length - 1]?.charAt(0),
        custNumber = account.idTokenClaims.extension_CustomerNumber;

    let initInputValues = {
        ShippingInstructions: '',
        GoodsMark: '',
        HeaderId: '',
        DeliveryNotificationPhone: '',
        CustomerPo: custName,
        ReferenceNo: custName
    },
        initReseller = {
            AddressType: 6,
            Name: '',
            Address1: '',
            City: '',
            PostalCode: '',
            Country: '',
        };

    let history = useHistory(),
        [inputs, setInputs] = useState(initInputValues),
        [reseller, setReseller] = useState(initReseller),
        [optionalAddress, setOptionalAddress] = useState(initReseller),
        [useOptionalAddress, setUseOptionalAddress] = useState(false),
        [requestedDate, setRequestedDate] = useState(new Date().toISOString().split('T')[0]),
        [promiseDate, setPromiseDate] = useState(''),
        [readyToSend, setReadyToSend] = useState(false),
        [errorMessage, setErrorMessage] = useState(''),
        [isLoading, setIsLoading] = useState(true),
        [onGoingMaintenance, setOnGoingMaintenance] = useState(false),
        [loadingATP, setLoadingATP] = useState(false);

    useEffect(() => {
        checkEmptyCart();
        const fetchData = async () => {
            try {

                /*const maintenanceCall = callApi({
                    method: 'get',
                    url: '/api/order/maintenanceStatus',
                }); */

                const contactInfoCall = callApi({
                    method: 'get',
                    url: '/api/reseller/contactInfo',
                });
                const deliveryAddressCall = await callApi({
                    method: 'get',
                    url: '/api/reseller/deliveryAddress',
                });

                const deliveryCall = callApi({
                    method: 'post',
                    url: '/api/order/deliveryDate',
                    data: {
                        cartItems,
                        requestedDate: requestedDate.replace(/-/g, ''),
                    },
                });


               
                Promise.all([deliveryAddressCall, contactInfoCall, deliveryCall]).then((results) => {
                    const deliveryAddress = results[0];
                    const contactInfo = results[1];
                    const delivery = results[2];
                    //const maintenance = results[3];

                    // Display user data
                    isReadyToSend(contactInfo.data); // Use when userdata is retrived - combind with maintenancestatus?
                    setPromiseDate(delivery.data.promiseDate.split('T')[0]);
                    setFreight(delivery.data.freight ?? 999);

                    // Check 
                    //checkMaintenance(maintenance);

                    setReseller({
                        ...reseller,
                        Name:
                            deliveryAddress.data.value[0].name ??
                            undefined,
                        Address1: deliveryAddress.data.value[0].line1 ?? undefined,
                        City: deliveryAddress.data.value[0].city ?? undefined,
                        PostalCode:
                            deliveryAddress.data.value[0].postalcode ?? undefined,
                        Country: 'Sverige', // Hämta från deliveryAddress.data.value[0].country ?
                    });
                    setInputs({
                        ...inputs,
                        DeliveryNotificationPhone:
                            contactInfo.telephone1 ?? '',
                    });
                    setOptionalAddress({
                        ...optionalAddress,
                        Country: 'Sverige',
                    });

                    setIsLoading(false);
                });
            } catch (err) {
                console.log('Failed to fetch: ', err);
                setError(true)
                alertLoadingError(t)
                setIsLoading(false)

            }
        };

        fetchData();
    }, [cartItems]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Place order
     */
    let onSubmit = (event) => {
        event.preventDefault();

        try {
            const addresses = useOptionalAddress ? optionalAddress : reseller;
            if (!onGoingMaintenance) {
                placeOrder({
                    inputs,
                    cartItems,
                    CustomerId: custNumber,
                    ShipTo: custNumber,
                    requestedDate: requestedDate.replace(/-/g, ''),
                    addresses: [handleAddress(addresses)],
                }, reseller);
            } else {
                confirmAlert({
                    title: t('error.title.oops'),
                    message: errorMessage,
                    buttons: [
                        {
                            label: t('button.close'),
                            className: 'button-primary',
                        },
                    ],
                })
            }

        } catch (error) {
            console.log('error', error);
        }

        return;
    };

    let checkMaintenance = (maintenanceData) => {
        let currentDate = Date.now(),
            fromDate = new Date(maintenanceData.data.value[0].ed_frommaintenancedate).getTime(),
            toDate = new Date(maintenanceData.data.value[0].ed_tomaintenancedate).getTime();
        if (maintenanceData.data.value[0].statuscode === 1 && fromDate <= currentDate && currentDate <= toDate) {
            setOnGoingMaintenance(true)
        }

    }

    /**
     * Check of cart is empty.
     */
    let checkEmptyCart = () => {
        if (cartItems.length <= 0) {
            alert('Cart is empty.'); //eslint-disable-line
        }
    };

    /**
     * Check if obj has an empty property
     * With websiteurl exception
     * @param {*} obj
     * @returns Boolean
     */
    let checkIfEmpty = (obj) => {
        let isEmpty = { status: false, key: null };
        for (var key in obj) {
            if (
                obj[key] === undefined ||
                obj[key] === null ||
                obj[key] === ''
            ) {
                isEmpty = { status: true, key };
                if (key === 'websiteurl') {
                    isEmpty = { status: false, key: null };
                } // Not important for
                if (isEmpty.status) {
                    return isEmpty;
                }
            }
        }
        return isEmpty;
    };

    /**
     *
     * Empty Cart
     * No Cust Id
     * Deliverydate

     * Checks if
     * @param {*} reseller
     * @returns
     */
    let isReadyToSend = (reseller) => { //eslint-disable-line
        let emptyFields = checkIfEmpty(reseller);
        if (cartItems.length <= 0) {
            setErrorMessage(t('error.cart-is-empty'))
            return;
        }
        if (emptyFields.status) {
            setErrorMessage(
                `${t('error.failed-to-load-user-data')} : ${emptyFields.key ?? ''}`
            );
            return;
        }

        setReadyToSend(true);
    };

    const getATP = (date) => {
        setLoadingATP(true);
        callApi({
            method: 'post',
            url: '/api/order/deliveryDate',
            data: {
                cartItems,
                requestedDate: date.replace(/-/g, ''),
            },
        }).then((res) => {
            setPromiseDate(res.data.promiseDate.split('T')[0]);
            setFreight(res.data.freight ?? 999);
            setLoadingATP(false);
        });
    };

    const onRequestedDateChange = (event) => {
        setRequestedDate(event.target.value);
        getATP(event.target.value);
    };

    let onChange = (event) => {
        event.persist();
        setInputs((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
    };

    /**
     * Update reseller data
     */
    let onChangeReseller = (event) => {
        event.persist();

        setOptionalAddress((optionalAddress) => ({
            ...optionalAddress,
            [event.target.name]: event.target.value,
        }));
    };

    /**
     * Convert to multiple address fields if needed.
     */
    let handleAddress = (reseller) => {
        if (reseller.Address1 === undefined) {
            setErrorMessage(t('error.reseller-address-undefined'))
            return;
        }
        let addressArr = reseller.Address1.match(/.{1,74}/g);
        if (addressArr) {
            addressArr.forEach((add, index) => {
                reseller['Address' + (index + 1)] = add;
            });
        }

        return reseller;
    };

    let AddressFields = () => {
        return (
            <div>
                {useOptionalAddress ? (
                    <AltDelivery
                        onChangeReseller={onChangeReseller}
                        onChange={onChange}
                        inputs={inputs}
                        reseller={optionalAddress}
                    />
                ) : (
                    <StdDelivery reseller={reseller} />
                )}
                {custMarket === 'se' && (
                    <div className="field-item field-item-checkbox select-checkbox-container">
                        <input
                            type="checkbox"
                            id="alternative-delivery-address"
                            data-target=".alternative-delivery-address-block"
                            onChange={() => {
                                setUseOptionalAddress(!useOptionalAddress);

                                inputs.ShippingInstructions = ' ';
                            }}
                            checked={useOptionalAddress}
                        />
                        <label htmlFor="alternative-delivery-address">
                            {t('checkout.forms.alt-delivery-address')}

                        </label>
                    </div>
                )}
            </div>
        );
    };

    let DateComponent = () => {
        const today = new Date().toISOString().split('T')[0];
        return (
            <div>
                <div className="field-item">
                    <label htmlFor="RequestedShipDate">
                        {t('checkout.forms.select-delivery-date')}
                    </label>
                    <input
                        className="field field-search"
                        type="date"
                        id="RequestedShipDate"
                        name="RequestedShipDate"
                        min={today}
                        value={requestedDate}
                        onChange={onRequestedDateChange}
                    />
                </div>
                <p className={loadingATP ? ' txt-loading' : ''}>{t('checkout.forms.estimated-delivery-date')}: <span>{ loadingATP ? `${t('orders.loading')} ...` : promiseDate }</span></p>
            </div>
        );
    };

    let ShippingInstructions = () => {
        return (
            <div>
                <label className="label" htmlFor="GoodsMark">
                    {t('checkout.forms.invoice-label')}
                </label>
                <input
                    className="field field-text"
                    name="GoodsMark"
                    id="GoodsMark"
                    type="text"
                    maxLength="75"
                    onChange={onChange}
                />
                <div className="">
                    <label className="label" htmlFor="ShippingInstructions">
                        {t('checkout.forms.invoice-label')}
                    </label>
                    <div className="field-select">
                        <select
                            id="ShippingInstructions"
                            name="ShippingInstructions"
                            className="field field-select-input"
                            onChange={onChange}
                            required
                            value={inputs.ShippingInstructions}>
                            <option value=" ">{t('checkout.forms.standard-delivery')}</option>
                            {!useOptionalAddress && (
                                <option value="SAMLEV">{t('checkout.forms.co-delivery')}</option>
                            )}
                        </select>
                    </div>
                </div>
                {cartItems.length > 0 && <DateComponent />}
            </div>
        );
    };

    return (
        <div className="checkout">
            <h1>{t('checkout.forms.checkout')}</h1>
            {isLoading &&
                <div>
                    <LargeFormLoader />
                </div>
            }
            {!isLoading && !error &&
                <form method="POST" onSubmit={onSubmit}>
                    {AddressFields()}
                    {ShippingInstructions()}

                    <div className="row order-checkout-footer">
                        <p
                            className="button button--go-back"
                            onClick={() => {
                                history.push('/order-add');
                            }}>
                            {t('checkout.forms.continue')}
                        </p>

                        <button
                            type="submit"
                            className={`button-submit button-secondary${loadingATP ? ' btn-loading' : ''}`}
                            disabled={loadingATP}
                        >
                            <span>{t('checkout.forms.send')}</span>
                        </button>
                    </div>
                </form>
            }
            {onGoingMaintenance && <h3 className='content-primary'>{t('orders.maintenance-text')}</h3>}
        </div >
    );
}
