import React from 'react';
import { Link } from 'react-router-dom';
const CategoryItem = ({ category }) => {
    return (
        category.status === "inactive" ?
            <div className="shop-item inactive"  >
                <div className="shop-item-figure">
                    <div className="shop-item-figure-content">
                        <img src={category.image} alt={category.title} />
                    </div>
                </div>
                <h3 className="shop-item-title">{category.title}</h3>
                {
                    category.description &&
                    <p className="shop-item-description">{category.description}</p>
                }
            </div> :
            <Link className="shop-item" to={category.to} >
                <div className="shop-item-figure">
                    <div className="shop-item-figure-content">
                        <img src={category.image} alt={category.title} />
                    </div>
                </div>
                <h3 className="shop-item-title">{category.title}</h3>
                {
                    category.description &&
                    <p className="shop-item-description">{category.description}</p>
                }
            </Link>



    );
}

export default CategoryItem;