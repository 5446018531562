import React from 'react';
import {
    parseItem,
    campaignItemXD,
    campaignItemRP,
    originalPrice,
    attachedItem,
} from './items';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export default function SummaryTable(props) {

    const { t } = useTranslation();

    let displayItems = (items) => {
        items.sort((a, b) =>
            a.OriginalBasePrice < b.OriginalBasePrice ? 1 : -1
        );
        return items.map((item) => {
            item = parseItem(item);

            if (item.CampaignDiscountType === 'XD') {
                return campaignItemXD(item);
            } else if (item.CampaignDiscountType === 'RP') {
                return campaignItemRP(item);
            } else if (item.CampaignDiscountType === 'ND') {
                //todo more info required
                return null;
            } else if (item.CampaignDiscountType === 'XR') {
                //todo more info required
                return null;
            } else if (
                item.OriginalBasePrice &&
                item.CampaignBasePrice === null
            ) {
                return originalPrice(item);
            } else if (
                !Object.prototype.hasOwnProperty.call(item, 'Path') &&
                item.ItemNo !== undefined
            ) {
                return attachedItem(item);
            }
            return null;
        });
    };

    return (
        <div className="item-list">
            <h2 className="sub-title">{t('cpq-summary.summary-table-header.sub-title')}</h2>
            <table className="cpq-config-table table-boxed table-cols-7 ">
                <thead>
                    <tr className="item item--header">
                        <th>{t('cpq-summary.summary-table-header.id')}</th>
                        <th>{t('cpq-summary.summary-table-header.article')}</th>
                        <th></th>
                        <th>{t('cpq-summary.summary-table-header.quantity')}</th>
                        <th>{t('cpq-summary.summary-table-header.price')}</th>
                        <th>{t('cpq-summary.summary-table-header.discount')}</th>
                        <th>{t('cpq-summary.summary-table-header.sum')}</th>
                    </tr>
                </thead>
                <tbody>{displayItems(props.items)}</tbody>
            </table>
        </div>
    );
}