import React from 'react';
import '../../../../i18n';
import { useTranslation } from 'react-i18next';

export default function AdressFields(props) {

    const { t } = useTranslation();
    return (
        <div className="">
            <label className="label" htmlFor="Name">
                {t('checkout.forms.name')}
            </label>
            <input
                required
                className="field field-text"
                name="Name"
                id="Name"
                value={props.reseller.Name}
                onChange={props.onChangeReseller}
            />

            <label className="label" htmlFor="Address1">
            {t('checkout.forms.address')}
            </label>
            <input
                required
                className="field field-text"
                name="Address1"
                id="Address1"
                value={props.reseller.Address1}
                onChange={props.onChangeReseller}
            />
            <label className="label" htmlFor="DeliveryNotificationPhone">
            {t('checkout.forms.phone')}
            </label>
            <input
                required
                className="field field-text"
                name="DeliveryNotificationPhone"
                id="DeliveryNotificationPhone"
                value={props.inputs.DeliveryNotificationPhone}
                onChange={props.onChange}
            />

            <div className="row">
                <div className="column">
                    <label className="label" htmlFor="City">
                    {t('checkout.forms.city')}
                    </label>
                    <input
                        required
                        className="field field-text"
                        name="City"
                        id="City"
                        value={props.reseller.City}
                        onChange={props.onChangeReseller}
                    />
                </div>
                <div className="column">
                    <label className="label" htmlFor="PostalCode">
                    {t('checkout.forms.postal')}
                    </label>
                    <input
                        required
                        className="field field-text"
                        name="PostalCode"
                        id="PostalCode"
                        value={props.reseller.PostalCode}
                        onChange={props.onChangeReseller}
                    />
                </div>
            </div>

            <label className="label" htmlFor="Country">
            {t('checkout.forms.country')}
            </label>
            <input
                required
                className="field field-text"
                name="Country"
                id="Country"
                value={props.reseller.Country}
                readOnly
            />
        </div>
    );
}