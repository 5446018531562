/* eslint no-console: ["error", { allow: ["warn", "error", "log", "dir"] }] */
import React, { useContext } from 'react';
import Icon from '../../components/Icon/';
import { Link } from "react-router-dom";
import { CartContext } from '../../components/Context/cart/CartContext';
import { useTranslation } from 'react-i18next';
import { useMsal, useAccount } from '@azure/msal-react';
import '../../../i18n';

export default function User() {
    const { instance, accounts } = useMsal();
    const { t } = useTranslation();
    const {itemCount} = useContext(CartContext);

    const
        accountInfo = useAccount(accounts[0] || {}),
        account = accountInfo?.idTokenClaims;

    const isAdmin = account?.extension_Roles?.toLowerCase().includes('portal admin'); // eslint-disable-line camelcase

    // Close dropdown menu on click outside
    document.addEventListener('click', function(event) {
        let dropDown = document.querySelector('.user');
        if(dropDown) {
            let
                dropDownVisible = dropDown.classList.contains('user-has-visible-user-menu'),
                isClickInside = dropDown.contains(event.target);

            if (dropDownVisible && !isClickInside) {
                dropDown.classList.toggle('user-has-visible-user-menu');
            }
        }
    });

    function toggleUserMenu() {
        document.querySelector('.user').classList.toggle('user-has-visible-user-menu');
    }

    function closeUserMenu() {
        document.querySelector('.user').classList.remove('user-has-visible-user-menu');
        document.querySelectorAll('.menu-item-current').forEach(el => el.classList.remove('menu-item-current'))
    }

    function trySignOut(instance) {
        if(parseInt(itemCount) > 0) {
            document.documentElement.classList.add('has-visible-sign-out-message');
        } else {
            signOut(instance);
        }
    }

    function signOut(instance) {
        document.documentElement.classList.remove('has-visible-sign-out-message');
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    }

    function closeSignOutMsg() {
        document.documentElement.classList.remove('has-visible-sign-out-message');
    }

    return (
        <div className="user">
            <div className="user-info" onClick={toggleUserMenu}>
                <div className="user-avatar"><Icon name="User" /></div>
                <div className="user-name">{account?.name}</div>
                <div className="toggle-user-menu"></div>
            </div>

            <div className="user-menu">
                <div className="user-menu-item">
                    <Link to="/account" onClick={closeUserMenu} className="user-menu-link"><Icon name="Account" />{t('button.account')}</Link>
                </div>
                {isAdmin &&
                    <div className="user-menu-item">
                        <Link to="/user-admin" onClick={closeUserMenu} className="user-menu-link"><Icon name="UserAdmin" />{t('button.users')}</Link>
                    </div>
                }
                <div className="user-menu-item">
                    <div onClick={() => trySignOut(instance)} className="user-menu-link"><Icon name="SignOut" />{t('button.sign-out')}</div>
                </div>
            </div>
            <div className="sign-out-message-block">
                <div onClick={closeSignOutMsg} className="sign-out-message-overlay"></div>
                <div className="sign-out-message">
                    <h3>{t('user.cart-has-items', {count: itemCount})}</h3>
                    <p>{t('user.confirm-logout')}</p>
                    <div className="buttongroup confirm-buttons">
                        <button onClick={() => signOut(instance)} className="button button-tertiary">{t('button.sign-out')}</button>
                        <Link to="/checkout" onClick={closeSignOutMsg} className="button button-primary">{t('button.to-checkout')}</Link>
                    </div>
                    <div onClick={closeSignOutMsg} className="sign-out-message-close"><Icon name="CloseSimple" /></div>
                </div>
            </div>
        </div>
    );
}