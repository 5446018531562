import React from 'react';
import { stripHtml } from '../../components/Utils/strings';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

const CPQInfo = ({details, summary, image, infoImage, activeIndex, setActiveIndex}) => {

    const { t } = useTranslation();

    const handleTabClick = (e) => {
        setActiveIndex(e.target.tabIndex);
    };

    const buildTabs = () => {
        return (
            <>
                <button
                    className={`cpq-info-tab ${activeIndex === 1 ? 'cpq-info-tab--active' : ''}`}
                    onClick={handleTabClick}
                    tabIndex="1"
                >
                    {t('cpq.details')}
                </button>
                <button
                    className={`cpq-info-tab ${activeIndex === 2 ? 'cpq-info-tab--active' : ''}`}
                    onClick={handleTabClick}
                    tabIndex="2"
                >
                    {t('cpq.summary')}
                </button>
                <button
                    className={`cpq-info-tab ${activeIndex === 3 ? 'cpq-info-tab--active' : ''}`}
                    onClick={handleTabClick}
                    tabIndex="3"
                >
                    {t('cpq.image')}
                </button>
                <button
                    className={`cpq-info-tab ${activeIndex === 4 ? 'cpq-info-tab--active' : ''}`}
                    onClick={handleTabClick}
                    tabIndex="4"
                    disabled={!infoImage}
                >
                    {t('cpq.info')}
                </button>
            </>
        );
    };

    const buildDetails = () => {
        return (
            <div
                className={`cpq-info-panel ${activeIndex === 1 ? 'cpq-info-panel--active' : ''}`}
            >
                <ul>
                    {details.map((detail, index) => {
                        return (
                            <li key={index}>{stripHtml(detail.Caption)} : {stripHtml(detail.Value)}</li>
                        )
                    })}
                </ul>
            </div>
        );
    };

    const buildSummary = () => {
        return (
            <div
                className={`cpq-info-panel ${activeIndex === 2 ? 'cpq-info-panel--active' : ''}`}
            >
                {summary.map((sum, index) => {
                    switch (sum.Type) {
                        case 0:
                            return (
                                <h3 key={index}>{stripHtml(sum.Caption)}</h3>
                            );
                        case 1:
                            if (sum.Caption) {
                                return (
                                    <h4 key={index}>{stripHtml(sum.Caption)}</h4>
                                );
                            }
                            return null;
                        case 2:
                            return (
                                <p key={index}>{stripHtml(sum.Caption)} {sum.Value}</p>
                            );

                        default:
                            return null;
                    }
                })}
            </div>
        );
    };

    const buildImage = () => {
        return (
            <div
                className={`cpq-info-panel ${activeIndex === 3 ? 'cpq-info-panel--active' : ''}`}
            >
                <div className="cpq-config-image">
                    {image ? <img alt="" src={image} /> : null}
                </div>
            </div>
        );
    };

    const buildInfoImage = () => {
        return (
            <div
                className={`cpq-info-panel ${activeIndex === 4 ? 'cpq-info-panel--active' : ''}`}
            >
                <div className="cpq-config-image">
                    {infoImage ? <img alt="" src={infoImage} /> : null}
                </div>
            </div>
        );
    };

    const buildPanels = () => {
        return (
            <>
                {typeof details === 'object' ? buildDetails() : null}
                {buildSummary()}
                {buildImage()}
                {buildInfoImage()}
            </>
        );
    };

    return (
        <div className="cpq-secondary-content">
            <div className="cpq-info-tabs">
                {buildTabs()}
            </div>
            <div className="cpq-info-panels">
                {buildPanels()}
            </div>
        </div>
    );
};

export default CPQInfo;