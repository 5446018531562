import React from 'react';
import ShopItem from '../../pages/Shop/shop-item';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

const ShopItems = ({items}) => {

    const { t } = useTranslation();

    return (
        <div className="shop-items">
            { items.length > 0 &&
                items.map((category, key) =>  <ShopItem key={key} category={category} />)
            }
            { items.length < 1 &&
                <p>[ {t('shop.product-missing')} ]</p>
            }
        </div>

    );
}

export default ShopItems;