import React, { useState, useEffect } from 'react';
import CategoryList from '../../components/CategoryList/';
import axios from 'axios';
import { TableLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';
import { useTranslation } from 'react-i18next';

const Media = () => {
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
            url: '/rest-api/mediaApi/files',
            params: {
                market: 'sv-SE',
                contentType: 'doc',
            }
        }).then((res) => {
            setDocuments(res.data[0]);
        }).catch((error) => {
            setError(true)
            alertLoadingError(t)
        });
    }, [t]);

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('media.documents.title')}</h1>
            </div>
            {documents && documents.items.length > 0 &&
                <div className="content-secondary">
                    <CategoryList items={documents.items} />
                </div>
            }
            {!documents && !error &&
                <TableLoader />
            }
        </div>
    );
}

export default Media;