import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

const DropDown = ({id, value: startValue, options, onChange, onContinue, isRequired, isEnabled}) => {

    const { t } = useTranslation();
    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.id, event.target.value);
        setValue(event.target.value);
        onContinue();
    };

    return (
        <div className="cpq-option-input cpq-option-input-has-select">
            <select
                id={id}
                value={value}
                onChange={handleInputChange}
                className="cpq-select-box"
                disabled={!isEnabled}
            >
                { !(value !== '' && isRequired) ?
                    <option defaultValue>{t('cpq.select')} ...</option>
                    : null }
                {options.map((option, index) => (
                    <option key={index} value={option.Value}>
                        {option.Caption}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropDown;