import React, { useState } from 'react';
import { ReactComponent as IconPlus } from '../../../images/icons/plus-2.svg';
import { ReactComponent as IconMinus } from '../../../images/icons/minus-2.svg';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

const Quantity = ({ updateQuantity }) => {
  const [quantity, setQuantity] = useState(1);

  const { t } = useTranslation();

  const update = (event) => {
    const newQuantity =
      event.target.value !== '' || event.target.value < 1 ? 1 : event.target.value;
    setQuantity(newQuantity);
    updateQuantity(newQuantity);
  };

  const decrease = () => {
    const newQuantity = quantity > 2 ? quantity - 1 : 1;
    setQuantity(newQuantity);
    updateQuantity(newQuantity);
  };

  const increase = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    updateQuantity(newQuantity);
  };

  return (
    <div className='product-quantity'>
      <div className='label'>{t('product.quantity')}</div>
      <div className='quantity-select'>
        <div className='quantity quantity-decrease' onClick={decrease}>
          <IconMinus />
        </div>
        <input type='number' className='field quantity-field' value={quantity} onChange={update} />
        <div className='quantity quantity-increase' onClick={increase}>
          <IconPlus />
        </div>
      </div>
    </div>
  );
};

export default Quantity;
