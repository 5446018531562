import React, { useContext } from 'react';
import { ReactComponent as ShoppingBag } from '../../../images/icons/shopping-bag.svg'
import { CartContext } from '../../components/Context/cart/CartContext';

const ToggleShoppingCart = () => {

    const {itemCount} = useContext(CartContext);

    const toggleShoppingCart = () => {
        document.documentElement.classList.toggle('has-visible-shopping-cart');
    }

    return (
        <div className={`toggle-shopping-cart${itemCount < 1 ? ' toggle-shopping-cart-has-empty-cart' : ''}`} onClick={toggleShoppingCart}>
            <ShoppingBag />
            <div className="cart-count-bubble">
                <span className="cart-count" data-cart-item-count={itemCount}></span>
            </div>
        </div>
    );
}

export default ToggleShoppingCart;