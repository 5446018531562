import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import lng_en from "./translations/en/translation.json";
import lng_sv from "./translations/sv/translation.json";

const resources = {
    en: {
        translation: lng_en
    },
    sv: {
        translation: lng_sv
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "sv",

        interpolation: {
            escapeValue: false
        }
});

export default i18n;