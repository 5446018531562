import React, { useState } from 'react';

const NumericTextBox = ({id, value: startValue, max, min, step, onChange, onContinue, isEnabled}) => {

    const [value, setValue] = useState(startValue);

    const handleInputChange = (event) => {
        onChange(event.target.name, event.target.value);
        setValue(event.target.value);
    };

    const handleInputKey = (event) => {
        if ((event.key === 'Enter' || event.key === 'Tab') && event.target.value.trim() !== '') {
            validateInput(event)
        }
    }

    const validateInput = (event)  => {
        let newValue = event.target.value;

        if (newValue < min) {
            newValue = min;
        } else if (newValue > max) {
            newValue = max;
        }

        onChange(event.target.name, newValue);
        setValue(newValue);
        onContinue();
    }

    return (
        <div className="cpq-option-input">
            <input
                id={id}
                name={id}
                max={max}
                min={min}
                step={step}
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleInputKey}
                placeholder={`${min !== undefined ? 'min: ' + min : ''} ${min !== undefined && max !== undefined ? ', ' : ''} ${max !== undefined ? 'max: ' + max : ''}`}
                type="number"
                autoFocus
                className="cpq-input-field cpq-input-field-number"
                disabled={!isEnabled}
            />
        </div>
    );
};

export default NumericTextBox;