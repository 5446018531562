import axios from 'axios';
import React, { useState, useEffect } from 'react';
import '../../../../i18n'
import { useTranslation } from 'react-i18next';


export default function NewsOverlay() {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null); // eslint-disable-line no-unused-vars

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
            url: '/rest-api/mediaApi/articles',
            params: {
                market: 'sv-SE',
                isFlagged: true,
                limit: 1,
                sort: true,
            }
        }).then(async (res) => {
            const post = res.data[0];
            const showNews = await showFlaggedNews(post);
            if (showNews) {
                setIsVisible(true);
                setPost(post);
                localStorage.setItem(
                    'viewed',
                    JSON.stringify({
                        time: Date.now(),
                        id: post.id,
                    })
                );
            }
        }).catch((error) => {
            setError({ error: 'Failed to get flagged news' });
        });
    }, []);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const showFlaggedNews = async (post) => {
        const viewed = await JSON.parse(localStorage.getItem('viewed'));
        const currentTime = Date.now();
        const timeLimit = 60 * 60 * 1000 * 48; // 48 hours

        if (viewed) {
            const timeDifference = currentTime - viewed.time;
            if (post.id === viewed.id && timeDifference < timeLimit) {
                return false;
            }
        }

        return true;
    };

    return (
        <div>
            {isVisible && (
                <div className="news-overlay-background">
                    <div className="news-overlay-container">
                        {post && (
                            <div className="news-overlay-card">
                                <a href={'/news/' + post.id} onClick={toggleVisibility}>
                                    <img
                                        alt="Not found"
                                        src={post.image}
                                        className="card-img"
                                    />
                                    <h3 className="card-title">{post.title}</h3>
                                    <p className="card-abstract">{post.abstract}</p>
                                </a>
                            </div>
                        )}
                        <button className="btn close-btn" onClick={toggleVisibility}>
                            {t('news.news-overlay.close')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
