import React, {  useEffect, useContext, useState } from 'react';
import { CartContext } from '../../components/Context/cart/CartContext';
import { formatNumber } from '../../components/Utils/numbers';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconRemove } from '../../../images/icons/dust-bin.svg'
import '../../../i18n';

const ShoppingCartItem = ({product}) => {
    const { t } = useTranslation();
    const { update, increase, decrease, removeProduct } = useContext(CartContext);
    const [productQuantity, setProductQuantity] = useState(product.quantity);
    const maxQuantity = 999;

    useEffect(() => {
        setProductQuantity(product.quantity)
    }, [product]);

    const updateQty = (event) => {
        setProductQuantity(event.target.value);
    }

    const updateItem = (event) => {
        let qty = parseInt((event.target.value !== '' && event.target.value >= 1) ? event.target.value : 1);
        qty = qty > maxQuantity ? maxQuantity : qty;
        setProductQuantity(qty)
        product.quantity = qty;
        update(product)
    }

    const increaseQty = () => {
        if((productQuantity + 1) <= maxQuantity) {
            increase(product)
        }
    }

    const ConditionalLink = ({ children, to, condition }) => (!!condition && to)
        ? <a className="shopping-cart-line-item-content" href={to}>{children}</a>
        : <div className="shopping-cart-line-item-content">{children}</div>;

    return (
        <div className="shopping-cart-line-item cart-line-item-type-shopping-cart" >
            <div className="shopping-cart-line-item-main">
                <ConditionalLink to={`/shop/product/${product.id}`} condition={product.type !== 'config'}>
                    <div className="shopping-cart-line-item-figure">
                        <img className="shopping-cart-line-item-img" src={product.image} alt="" />
                    </div>
                    <div className="shopping-cart-line-item-details">
                        <h3 className="shopping-cart-line-item-title">{product.title}</h3>
                    </div>
                </ConditionalLink>
                <div
                    className="cart-line-item-remove shopping-cart-line-item-remove"
                    onClick={() => removeProduct(product)}
                >
                    <IconRemove className="icon-remove" />
                </div>
            </div>

            <div className="shopping-cart-line-item-footer">
                {product.type !== 'config' ?
                    <span className="shopping-cart-line-item-quantity">
                        <button
                            className="shopping-cart-quantity-btn shopping-cart-quantity-decrease"
                            data-type-of-change="decrease"
                            aria-label="Decrease"
                            onClick={() => decrease(product)}
                        >
                            <i></i>
                        </button>
                        <input
                            className="shopping-cart-quantity-value"
                            type="number" value={productQuantity}
                            min="1"
                            aria-label="Quantity"
                            onChange={updateQty}
                            onBlur={updateItem}
                        />
                        <button
                            className="shopping-cart-quantity-btn shopping-cart-quantity-increase"
                            data-type-of-change="increase"
                            aria-label="Increase"
                            onClick={() => increaseQty()}
                            disabled={productQuantity >= maxQuantity}
                        >
                            <i></i>
                        </button>
                    </span>
                    :
                    <span className="shopping-cart-line-item-quantity-disabled">
                        {productQuantity} {t('cart.pieces')}
                    </span>
                }
                <span className="shopping-cart-line-item-price price">{formatNumber(product.price * product.quantity)}</span>
            </div>
        </div>
    );
}

export default ShoppingCartItem;